<template>
  <li
    v-if="!disabled"
    :class="{ active: activeFolder && (activeFolder.folderId === folder.folderId) }"
    class="position-relative">
    <div class="file-item" ref="fileItem">
      <!-- Rename input -->
      <input
        v-if="isRenaming"
        v-model.trim="name"
        :disabled="isLoading"
        @keyup.esc="renameCancel"
        @keyup.enter="renameConfirm"
        ref="inputRename"
        type="text"
        class="form-control input-rename">

      <!-- Folder name -->
      <a
        v-else
        :href="`#${folder.folderName}`"
        @click.prevent="folderClick"
        @contextmenu.prevent="$refs.menu.open($event, 'folders');"
        class="d-flex align-items-center text-truncate btn-file">
        {{ folder.folderName }}
      </a>

      <!-- Loading -->
      <template v-if="isLoading">
        <div class="spinner position-absolute cursor-auto"></div>
      </template>

      <div v-else class="file-item-actions btn-group ml-auto">
        <!-- Rename actions -->
        <template v-if="isRenaming">
          <button
            @click="renameCancel()"
            class="btn btn-outline-danger btn-xs btn-round btn-icon d-flex align-items-center justify-content-center mr-1"
            type="button">
            <i class="bi bi-x text-20 ml-1px"></i>
          </button>

          <button
            :disabled="name === ''"
            @click="renameConfirm"
            class="btn btn-outline-success btn-xs btn-round btn-icon d-flex align-items-center justify-content-center"
            type="button">
            <i class="bi bi-check text-20"></i>
          </button>
        </template>

        <!-- Directory actions -->
        <template v-else>
          <dropdown-horizontal-ellipsis-toggle @click="$refs.menu.close();"></dropdown-horizontal-ellipsis-toggle>
          <div class="dropdown-menu dropdown-menu-right" ref="dropdownActions">
            <button class="dropdown-item text-14" type="button" @click="renameClick" ref="btnRenameFolder">
              <i class="dropdown-item-icon far fa-edit"></i>
              Naam wijzigen
            </button>

            <button class="dropdown-item text-14" type="button" @click="addTempFolder">
              <i class="dropdown-item-icon far fa-plus-square"></i>
              Submap toevoegen
            </button>

            <button v-if="folder.isSystem !== 'true' && allowDelete"
                    @click="deleteFolder"
                    class="dropdown-item text-14 text-danger"
                    type="button">
              <i class="dropdown-item-icon far fa-trash-alt"></i>
              Verwijderen
            </button>
          </div>
        </template>
      </div>

      <!-- Subdirectories caret -->
      <button
        v-if="folder.children && folder.children.length > 0"
        @click.prevent="toggleSubDirectory"
        :aria-expanded="isExpanded.toString()"
        class="file-item-dropdown btn text-9 w-auto box-shadow-none border-0 flex-shrink-0 text-black-50 d-flex align-items-center"
        type="button">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
    <small v-if="isRenaming && showNameFeedback" class="d-block feedback text-secondary my-2">Enkel cijfers, letters, - en _ zijn toegelaten.</small>

    <!-- Subdirectories -->
    <ul
      v-if="folder.children && folder.children.length > 0"
      v-show="isExpanded"
      class="list-unstyled mb-0">
      <folder
        v-for="folder in folder.children"
        ref="subFolder"
        :folder="folder"
        :key="folder.folderId"
        :class="{ active: activeFolder && (activeFolder.folderId === folder.folderId) }">
      </folder>
    </ul>

    <!-- Context menu -->
    <context-menu
      ref="menu">
      <template v-slot>
        <context-menu-item @click="$refs.menu.close(); renameClick(folder.folderName);">
          <i class="dropdown-item-icon far fa-edit"></i>
          Naam wijzigen
        </context-menu-item>
        <context-menu-item @click="$refs.menu.close(); addTempFolder();">
          <i class="dropdown-item-icon far fa-plus-square"></i>
          Submap toevoegen
        </context-menu-item>
        <context-menu-item
          v-if="folder.isSystem !== 'true' && allowDelete"
          class="text-danger"
          @click="$refs.menu.close(); deleteFolder();">
          <i class="dropdown-item-icon far fa-trash-alt"></i>
          Verwijderen
        </context-menu-item>
      </template>
    </context-menu>
  </li>
  <li v-else
      class="position-relative">
    <div class="file-item box-shadow-none cursor-auto" ref="fileItem">
      <!-- Folder name -->
      <div class="d-flex align-items-center text-truncate btn-file disabled">
        {{ folder.folderName }}
      </div>

      <!-- Subdirectories caret -->
      <button
        v-if="folder.children && folder.children.length > 0"
        @click.prevent="toggleSubDirectory"
        :aria-expanded="isExpanded.toString()"
        class="file-item-dropdown btn text-9 w-auto box-shadow-none border-0 flex-shrink-0 text-black-50 d-flex align-items-center"
        type="button">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <!-- Subdirectories -->
    <ul
      v-if="folder.children && folder.children.length > 0"
      v-show="isExpanded"
      class="list-unstyled mb-0 cursor-auto">
      <folder
        v-for="folder in folder.children"
        ref="subFolder"
        :folder="folder"
        :disabled=true
        :key="folder.folderId">
      </folder>
    </ul>
  </li>
</template>

<script>
  import { mapState } from 'vuex';
  import $ from 'jquery';
  import { findDeep } from '@/utils/array';
  import contextMenu from '@/components/context_menu/ContextMenu';
  import contextMenuItem from '@/components/context_menu/ContextMenuItem';
  import dropdownHorizontalEllipsisToggle from '@/components/dropdown/DropdownHorizontalEllipsisToggle';

  export default {
    name: 'folder',
    props: {
      folder: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      allowDelete: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        name: '',
        isRenaming: false,
        isExpanded: false,
        isLoading: false,
        isActionsOpen: false,
        showNameFeedback: false,
      };
    },
    computed: {
      activeRenameFolderId: {
        get() {
          return this.$store.state.datastore.activeRenameFolderId;
        },
        set(value) {
          this.$store.commit('datastore/setActiveRenameFolderId', value);
        },
      },
      activeContextMenuId: {
        get() {
          return this.$store.state.activeContextMenuId;
        },
        set(value) {
          this.$store.commit('setActiveContextMenuId', value);
        },
      },
      folderDeleteModal: {
        get() {
          return this.$store.state.folderDeleteModal;
        },
        set(value) {
          this.$store.commit('datastore/setFolderDeleteModal', value);
        },
      },
      ...mapState({
        searchEl: (state) => state.datastore.searchEl,
        activeFolder: (state) => state.datastore.activeFolder,
        requireFolderDeleteConfirm: (state) => state.datastore.requireFolderDeleteConfirm,
      }),
    },
    components: {
      contextMenu,
      contextMenuItem,
      dropdownHorizontalEllipsisToggle,
    },
    methods: {
      /**
       * sub-folder on click
       * add temporary folder
       * @note the item is added in the ui but not on the server!
       * once a folder name is given, it will become an 'actual' folder
       */
      addTempFolder() {
        const tempFolder = {
          folderName: '',
          folderId: this.$store.state.datastore.TEMP_ID_PREFIX + Math.round((Math.random() * 1000)),
          parentFolderId: this.folder.folderId,
        };

        // add temp folder
        this.$store.commit('datastore/addTempFolder', tempFolder);

        // expand folder
        this.isExpanded = true;

        // trigger rename
        this.$nextTick(() => {
          this.$refs.subFolder.$refs.btnRenameFolder.click('');
        });
      },

      /**
       * upgrade temp folder to an 'actual' folder
       * */
      async addFolder() {
        await this.$store.dispatch('datastore/addFolder', {
          parentId: this.folder.parentFolderId,
          name: this.name,
          tempId: this.folder.folderId,
        });

        this.clearSearch();
        this.renameCb();
      },

      /**
       * clicked on chevron
       * show sub-folders
       */
      toggleSubDirectory() {
        this.isExpanded = !this.isExpanded;
      },

      /**
       * clicked on folder
       * set active state
       */
      folderClick() {
        // do nothing is active folder and clicked folder are the same
        if (this.activeFolder.folderId !== this.folder.folderId) {
          // set active folder
          this.$store.commit('datastore/setActiveFolder', this.folder.folderId);

          // clear search value
          this.clearSearch();
        }
      },

      /**
       * clicked on rename option
       */
      renameClick() {
        this.showNameFeedback = false;
        this.isRenaming = true;
        this.activeRenameFolderId = this.folder.folderId;
        this.name = this.folder.folderName;

        // select value on dom update
        this.$nextTick(() => {
          this.$refs.inputRename.select();
        });
      },

      /**
       * name change cancelled
       */
      renameCancel() {
        // temp folder has no name, delete
        if (this.folder.folderId.includes(this.$store.state.datastore.TEMP_ID_PREFIX)) {
          this.$store.commit('datastore/deleteTempFolder', this.folder.folderId);
          this.$store.commit('datastore/deleteFolder', this.folder.folderId);
        } else {
          // change renaming state
          this.$nextTick(() => {
            this.name = '';
            this.isRenaming = false;

            if (this.$refs.inputRename) {
              this.$refs.inputRename.blur();
            }
          });
        }
      },

      /**
       * name change confirmed
       */
      renameConfirm() {
        // ignore is text is empty
        if (this.name === '' || this.isLoading) {
          return;
        }

        // nothing changed, just cancel and quit fn
        if (this.name === this.folder.folderName) {
          this.renameCancel();
          return;
        }

        // name has changed
        this.isLoading = true;

        if (this.folder.folderId.includes(this.$store.state.datastore.TEMP_ID_PREFIX)) {
          // Upgrade temp folder
          this.addFolder();
        } else {
          // folder exists, change name
          this.renameFolder();
        }
      },

      /**
       * rename folder
       */
      async renameFolder() {
        await this.$store.dispatch(
          'datastore/renameFolder',
          {
            id: this.folder.folderId,
            parentId: this.folder.parentFolderId,
            name: this.name,
          },
        );

        this.renameCb();
      },

      /**
       * folder has successfully renamed
       */
      renameCb() {
        this.isRenaming = false;
        this.isLoading = false;
      },

      /**
       * Delete folder
       */
      deleteFolder() {
        if (this.requireFolderDeleteConfirm) {
          // confirm before deleting
          this.folderDeleteModal = {
            name: this.folder.folderName,
            folderId: this.folder.folderId,
            parentId: this.folder.parentFolderId,
          };
        } else {
          // skip confirmation and delete
          this.$store.dispatch(
            'datastore/deleteFolder',
            {
              folderId: this.folder.folderId,
              parentId: this.folder.parentFolderId,
            },
          );
        }

        if (this.activeFolder === this.folder) {
          this.clearSearch();
        }
      },

      /**
       * close dropdown menu
       */
      closeDropdown() {
        $(this.$refs.dropdownActions)
          .dropdown('hide');
      },

      // clear search value
      clearSearch() {
        if (this.searchEl) {
          this.searchEl.value = '';
        }
      },
    },
    created() {
      // Current folder is the active folder, do nothing
      if (this.folder.folderId === this.activeFolder.folderId) return;
      // Try to find active folder within the folder children
      const activeFolder = findDeep([this.folder], 'folderId', this.activeFolder.folderId, 'children');
      // If folder is found set isExpanded to true, else to false
      this.isExpanded = !!activeFolder;
    },
    mounted() {
      $(this.$el)
        .on('show.bs.dropdown', this.$refs.dropdownActions, () => {
          this.isActionsOpen = true;
        });
      $(this.$el)
        .on('hide.bs.dropdown', this.$refs.dropdownActions, () => {
          this.isActionsOpen = false;
        });
    },
    watch: {
      name(newName) {
        this.name = this.name.replace(/[^a-zA-Z0-9_/-]/, '');

        if (this.name !== newName) {
          this.showNameFeedback = true;
        }
      },
      activeRenameFolderId() {
        // allow 1 open rename dialog
        if (this.activeRenameFolderId !== this.folder.folderId) {
          this.renameCancel();
        }
      },
      activeContextMenuId() {
        if (this.activeContextMenuId !== '') {
          this.closeDropdown();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";
  @import "node_modules/spinthatshit/src/loaders";

  $file-item-py: $input-padding-y;
  $file-item-pl: 20px;
  $file-item-pr: 30px;
  $file-item-actions-right: 9px;

  li:not(.active) > .file-item:not(:hover):not(:focus):not(:focus-within) .file-item-actions, {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    tab-index: -1;
    transform: translateX(-50%) translateY(-50%);
  }

  li > .file-item input ~ .file-item-actions {
    opacity: 1 !important;
    pointer-events: all !important;
    user-select: none !important;;
    tab-index: 1 !important;;
    transform: translateY(-50%) !important;;
  }

  li.active > .file-item:not(.editable) {
    background: $file-item-bg-active;

    > a {
      color: $dark;
      font-weight: $font-weight-bold;
    }
  }

  li > .file-item:not(.disabled) {
    z-index: $zIndex-2;

    &:focus-within,
    &:focus,
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(#d2d6dc, 0.9);
    }
  }

  li ul .file-item:not(:hover):not(:focus) {
    box-shadow: none;
  }

  ion-icon[aria-label="checkmark"] path {
    stroke-width: 48px;
  }

  .btn-icon {
    $size: 22px;

    width: $size;
    height: $size;
    padding: 0;
  }

  .file-item {
    display: block;
    position: relative;
    border: 0;
    border-radius: $border-radius;
    transition: 0.25s ease;

    > .btn-file {
      padding: $file-item-py $file-item-pr $file-item-py $file-item-pl;
      color: $body-color;
      transition: 0.25s ease;
      height: $input-height;
    }

    &:hover > a,
    &:focus > a,
    &:focus-within > a {
      color: $dark;
    }
  }

  .file-item-actions,
  .file-item-dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.25s ease;
  }

  .file-item-actions {
    right: $file-item-actions-right;
    z-index: $zIndex-1;

    &:hover,
    &:focus,
    &:focus-within {
      opacity: 1 !important;
      pointer-events: all !important;
      user-select: auto !important;
      transform: translateY(-50%) !important;
    }
  }

  .file-item-dropdown {
    left: 0;
    padding: 5px 8px;
    z-index: $zIndex-2;

    &[aria-expanded="true"] i {
      transform: rotate(90deg);
    }
  }

  .dropdown-item-icon {
    width: 1rem;
    margin-right: 0.25rem;
  }

  .input-rename {
    padding-left: $file-item-pl;
    padding-right: 58px;
  }

  .feedback {
    padding-left: $file-item-pl;
    padding-right: $file-item-pl;
  }

  .ml-1px {
    margin-left: 1px;
  }

  .spinner {
    $size: 16px;

    top: 50%;
    right: $file-item-actions-right;
    margin-top: -($size * 0.5);
    z-index: $zIndex-1;
    @include loader01($size: $size, $color: $primary, $border-size: 1px, $duration: 0.5s);
  }
</style>
