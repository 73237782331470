import axios from 'axios';
import { getTranslation } from '@/utils/languages';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';

export default class GridActionOnOff {
  /**
   * set an item on of off
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      ...{
        pk: this.dom.action.dataset.pk,
        table: this.dom.action.dataset.table,
        field: this.dom.action.dataset.field,
        check: 1,
      },
      ...payload.props,
    };
    this.events = {
      onOff: this.onOffItem.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.onOff);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.onOff);
  }

  /**
   * switch item on or off
   *
   * @param e {MouseEvent}
   */
  onOffItem(e) {
    // item checked or not?
    this.props.check = (e.target.checked) ?? 0;

    // check required parameters
    if (!this.props.pk || !this.props.table || !this.props.field) {
      // throw critical alert
      getCritical();
    } else {
      this.data.formData = new FormData();
      this.data.formData.append('gridID', this.props.gridId);
      this.data.formData.append('table', this.props.table);
      this.data.formData.append('field', this.props.field);
      this.data.formData.append('pk', this.props.pk);
      this.data.formData.append('current', this.props.check);

      // make axios call
      axios({
        method: 'post',
        url: `${root}common/check-item`,
        data: this.data.formData,
      })
        .then((response) => {
          // console.log(response);
          if (!response.data.successMessage) {
            // send error notification
            let errorMessage = getTranslation('contact your webpartner');
            if (response.data.errorMessage) errorMessage = response.data.errorMessage;
            const notification = { title: errorMessage, type: 'danger' };
            this.customEvents.notificationError = getErrorNotification(notification);
            this.dom.engine.dispatchEvent(this.customEvents.notificationError);
            // throw critical alert
            // getCritical();
          }

          // some useful debug info
          if (response.data.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }
        })
        .catch(() => getCritical());
    }
  }
}
