<template>
  <!-- File upload modal -->
  <div class="dz-backdrop box-shadow-modal-lg d-flex align-items-center justify-content-center py-4" ref="dz-backdrop">
    <div class="dz-dialog rounded-lg bg-white p-5">
      <!-- Title and fractions -->
      <h4 class="d-flex flex-wrap pb-3 mb-3 dz-title">Bezig met uploaden<span class="d-inline-block ml-auto text-light font-weight-light">
        <span class="dz-fraction">0</span>&nbsp;/&nbsp;<span class="dz-total">0</span></span>
      </h4>

      <div class="m-0" id="previews">
        <div class="d-flex dz-template" data-id="dz-template">
          <div class="d-flex align-items-center mt-4 w-100">
            <!-- Thumb -->
            <div class="dz-thumb d-flex align-items-center justify-content-center overflow-hidden flex-shrink-0 rounded">
              <img class="img-fluid" data-dz-thumbnail/>
            </div>

            <div class="dz-text position-relative d-flex align-items-center">
              <div class="w-100 px-4">
                <!-- Name and size -->
                <div class="d-flex align-items-center">
                  <div class="text-truncate text-dark w-100" data-dz-name></div>
                  <div class="pl-4 ml-auto flex-shrink-0 text-light font-weight-light dz-size" data-dz-size></div>
                </div>

                <!-- Progress -->
                <div class="dz-progress position-relative w-100 mt-3">
                  <div class="dz-upload" data-dz-uploadprogress></div>
                </div>
              </div>
            </div>

            <!-- Actions -->
            <div class="dz-actions position-relative d-flex align-items-center flex-shrink-0">
              <div class="dz-mark dz-mark-loader"></div>
              <div class="dz-mark dz-success-mark d-flex align-items-center justify-content-center rounded-circle bg-primary">
                <i class="bi bi-check"></i>
              </div>
              <div class="dz-mark dz-error-mark d-flex align-items-center justify-content-center rounded-circle bg-danger">
                <i class="bi bi-exclamation-triangle-fill"></i>
              </div>
            </div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'templateList',
  };
</script>

<style lang="scss">
  @import "~@/assets/scss/appwork/_appwork/include";
  @import "~spinthatshit/src/loaders.scss";

  $dz-mark-size: 32px;
  $dz-thumb-size: 60px;
  $dz-backdrop-opacity: $modal-backdrop-opacity;
  $dz-dialog-max-width: 36em;
  $dz-text-width: calc(100% - #{$dz-thumb-size} - #{$dz-mark-size});

  .dz-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, $dz-backdrop-opacity);
    z-index: $zindex-modal-top + $zIndex-5;
    backdrop-filter: blur(4px);
    opacity: 0;

    &:not(.active) {
      visibility: hidden;
      pointer-events: none;
      touch-action: none;
      tab-index: -1;
    }
  }

  .dz-dialog {
    width: $dz-dialog-max-width;
    max-height: calc(100vh - #{$spacer * 8});
    transform: translateY(10vh);
    opacity: 0;
    overflow: auto;
  }

  .dz-template {
    opacity: 0;
    transform: translateY(20px);

    &:first-of-type {
      display: none !important;
    }

    &.dz-complete .dz-mark-loader {
      opacity: 0;
      transition: 0.25s ease-in;
      transform: scale(0);
    }

    &:not(.dz-complete) .dz-mark-loader {
      @include loader01($size: 16px, $color: $input-border-color, $border-size: 2px, $duration: 1s, $align: middle);
      opacity: 1;
    }

    .dz-mark-loader {
      top: 4px !important;
    }
  }

  .dz-title {
    border-bottom: 1px solid $input-border-color;
  }

  .dz-thumb {
    width: $dz-thumb-size;
    height: $dz-thumb-size;
    border: 1px solid $input-border-color;

    i {
      color: $file-thumb-color;
      @include font-size(30px);
    }
  }

  .dz-text {
    width: $dz-text-width;
  }

  .dz-progress,
  .dz-upload {
    height: 5px;
    border-radius: 5px;
  }

  .dz-progress {
    background: $file-item-bg-active;
  }

  .dz-upload {
    opacity: 1;
    pointer-events: none;
    background: $primary;
    z-index: $zIndex-1;
  }

  // progressbar
  .dz-processing {
    .dz-upload:not([style="width: 100%;"]) {
      animation: progressbar 2.5s infinite linear reverse;
      background: linear-gradient(90deg, $primary 0, $primary 35%, lighten($primary, 8%) 46%, lighten($primary, 8%) 54%, $primary 65%, $primary 100%);
      background-size: 300% 300%;
    }
  }

  // actions
  .dz-actions,
  .dz-mark {
    width: $dz-mark-size;
    height: $dz-mark-size;
  }

  .dz-mark {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.4);
    opacity: 0;
    transition: 0.6s cubic-bezier(0.34, 2.4, 0.64, 1);

    i {
      font-size: 24px;
      color: $white;
    }
  }

  .dz-success .dz-success-mark {
    opacity: 1;
    transform: scale(1);
  }

  .dz-error .dz-error-mark {
    opacity: 1;
    transform: scale(1);
  }

  .dz-remove {
    display: none !important;
  }

  .dz-size strong {
    font-weight: $font-weight-light;
  }

  @keyframes progressbar {
    0% {
      background-position: 0 50%
    }
    100% {
      background-position: 100% 50%
    }
  }
</style>
