<template>
  <template v-if="state === states.loading">
    <view-list-skeleton/>
  </template>
  <template v-else>
    <div class="datastore-list">
      <grid
        @ready="gridReady"
        @cellClick="cellClick"
        @row-selection-add="rowSelectionAdd"
        @row-selection-remove="rowSelectionRemove"
        @select-all-rows="selectAllRows"
        @deselect-all-rows="deselectAllRows"
        :cols="cols"
        :rows="rows"
        :sort="true"
        :search="true"
        :fixedHeader="true"
        :autoWidth="false"
        ref="grid"
        class="mb-4">
      </grid>

      <!-- Show amount if we have results -->
      <p class="text-right text-muted">{{ amountOfFilteredResults }} bestanden</p>
    </div>
  </template>
</template>

<script>
  // store
  import { mapGetters, mapState } from 'vuex';
  import { h } from 'gridjs';
  import mixinFile from '@/mixins/file';
  import grid from '@/components/tables/GridJS';
  import viewListSkeleton from '@/views/v10/datastore/overview/list/ListSkeleton';

  // config
  import configRow from '../../config/rows';
  import configColumn from '../../config/cols';

  export default {
    name: 'datastore-view-list',
    mixins: [mixinFile],
    props: {
      config: {
        type: String,
        required: false,
        default: '',
      },
      allowedExtensions: {
        type: Array, // array of strings ['jpg', 'png']
        required: false,
        default() {
          return [];
        },
      },
      maxFiles: {
        type: Number,
        required: false,
      },
      deselectFilesOnFolderChange: {
        type: Boolean,
        required: false,
        default() {
          return true;
        },
      },
    },
    data() {
      return {
        gridSearch: null,
        widthUnit: 'px',
        heightUnit: 'px',
        sizeUnit: 'mb',
        rows: [],
        cols: configColumn(
          {
            sizeUnit: this.sizeUnit,
            widthUnit: this.widthUnit,
            heightUnit: this.heightUnit,
            setThumb: this.setThumb,
            addRemoveButton: this.addRemoveButton,
          },
          this.config,
        ),
        amountOfFilteredResults: 0,
      };
    },
    computed: {
      filesFiltered() {
        let { files } = this;

        if (this.allowedExtensions.length > 0 && files) {
          files = this.files.filter((f) => this.allowedExtensions.includes(f.extension));
        }

        return files;
      },
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
      editFile: {
        get() {
          return this.$store.state.datastore.editFile;
        },
        set(value) {
          this.$store.commit('datastore/setEditFile', value);
        },
      },
      editRef: {
        get() {
          return this.$store.state.datastore.editRef;
        },
        set(value) {
          this.$store.commit('datastore/setEditRef', value);
        },
      },
      fileDeleteModal: {
        get() {
          return this.$store.state.fileDeleteModal;
        },
        set(value) {
          this.$store.commit('datastore/setFileDeleteModal', value);
        },
      },
      ...mapState({
        files: (state) => state.datastore.files,
        searchValue: (state) => state.datastore.searchValue,
        activeView: (state) => state.datastore.activeView,
        views: (state) => state.datastore.views,
        activeFolder: (state) => state.datastore.activeFolder,
        requireFileDeleteConfirm: (state) => state.datastore.requireFileDeleteConfirm,
        state: (state) => state.datastore.state,
        states: (state) => state.datastore.states,
      }),
      ...mapGetters({
        getFileById: 'datastore/getFileById',
        selectedFileIds: 'datastore/getSelectedFileIds',
      }),
    },
    components: {
      grid,
      viewListSkeleton,
    },
    methods: {
      // add rows to list view
      buildRows() {
        console.log('buildRows');
        this.rows = [];

        if (this.filesFiltered && typeof this.filesFiltered !== 'string') {
          this.filesFiltered.forEach((file) => this.rows.push(
            configRow(
              file,
              {
                sizeUnit: this.sizeUnit,
                widthUnit: this.widthUnit,
                heightUnit: this.heightUnit,
              },
              this.config,
            ),
          ));
        }
      },

      // on custom search emit input to trigger gridJS search
      syncSearch() {
        this.gridSearch.value = this.searchValue;
        this.gridSearch.dispatchEvent(new Event('input', { bubbles: true }));
      },

      setAmountOfFilteredResults() {
        this.amountOfFilteredResults = this.$refs.grid.wrapper.querySelectorAll('.gridjs-tbody .gridjs-tr').length;
      },

      // grid loaded
      async gridReady() {
        await this.$nextTick();
        try {
          if (this.$refs.grid) {
            this.gridSearch = this.$refs.grid.$el.querySelector('.gridjs-search-input');
          }
        } catch (error) {
          throw new Error(`Failed to link search: ${error.message}`);
        }

        this.setAmountOfFilteredResults();
        this.setCheckedState();
      },

      /**
       * add to selected rows
       * @param args {array}
       */
      cellClick(args) {
        const mouseEvent = args[0];
        const cellContent = args[1].data;
        const cell = args[2];
        const file = this.getFileById(cellContent.fileId);
        const ref = mouseEvent.currentTarget.querySelector('.thumb-wrapper');

        if (cell?.id === 'filename' && ref && file) {
          if (this.editFile?.fileUID === file.fileUID) {
            // tooltip is already shown, close
            this.editFile = null;
            this.editRef = null;
          } else {
            // show tooltip
            this.editFile = file;
            this.editRef = ref;
          }
        }
      },

      setCheckedState() {
        if (!this.$refs.grid) return;

        // check columns
        const checkToggleAll = this.$refs.grid.$el.querySelector('.gridjs-check-columns');
        const rows = [...this.$refs.grid.$el.querySelectorAll('.gridjs-tbody .gridjs-tr')];
        const fileIdsInView = rows.map((row) => row.querySelector('.gridjs-check-row')?.value);

        rows.forEach((row) => {
          const checkbox = row.querySelector('.gridjs-check-row');
          const value = checkbox?.value;

          // If element doesn't exist or value is not set do nothing
          if (!checkbox || !value) return;

          if (this.selectedFileIds.includes(value)) {
            // set checked state
            checkbox.checked = true;
            row.classList.add('active');
          } else {
            // set unchecked state
            checkbox.checked = false;
            row.classList.remove('active');
          }
        });

        // Exit function, no .gridjs-check-columns element found
        if (!checkToggleAll) return;

        // Check if all items in view are selected or not
        checkToggleAll.checked = fileIdsInView.every((id) => this.selectedFileIds.includes(id));
      },

      /**
       * add to selected rows
       * @param id {string}
       */
      rowSelectionAdd(id) {
        this.$store.commit('datastore/addSelectedFile', this.getFileById(id));
        this.setCheckedState();
      },

      /**
       * remove from selected rows
       * @param id {string}
       */
      rowSelectionRemove(id) {
        this.$store.commit('datastore/removeSelectedFile', this.getFileById(id));
        this.setCheckedState();
      },

      // select all rows
      selectAllRows() {
        this.filesFiltered.forEach((file) => {
          if (!this.selectedFileIds.includes(file.fileUID)) {
            this.rowSelectionAdd(file.fileUID);
          }
        });
      },

      // deselect all rows
      deselectAllRows() {
        this.filesFiltered.forEach((file) => {
          if (this.selectedFileIds.includes(file.fileUID)) {
            this.rowSelectionRemove(file.fileUID);
          }
        });
      },

      /**
       * set thumb if not an image
       * @param thumb {string}
       * @param extension {string}
       * @param fileName {string}
       */
      setThumb(thumb, extension, fileName) {
        if (thumb) {
          return `<img class="d-block thumb of-contain overflow-hidden" src="${thumb}" alt="${fileName}" loading="lazy">`;
        }

        return `<i class="thumb has-icon text-center ${this.getExtensionIconClass(extension, true)}"></i>`;
      },

      /**
       * add remove button in gridJS preact
       * @param text {string}
       */
      addRemoveButton(text) {
        return h('div', {
          className: '',
          onClick: (e) => this.delegateActions(e),
        }, text);
      },

      /**
       * determine which action was clicked
       * needed for remove button
       * @param e {MouseEvent}
       */
      delegateActions(e) {
        const {
          ref,
          id,
          name,
        } = e.target.dataset;

        if (ref === 'btn-remove') {
          this.deleteFile([id], name);
        }
      },

      /**
       * delete files
       * @param fileId {array}
       * @param fileName {string}
       */
      deleteFile(fileId, fileName) {
        if (this.requireFileDeleteConfirm) {
          // confirm before deleting
          this.fileDeleteModal = {
            fileIds: fileId,
            fileName,
          };
        } else {
          // skip confirmation and delete
          this.$store.dispatch('datastore/deleteFiles', fileId);
        }
      },
    },
    watch: {
      selectedFiles: {
        deep: true,
        handler() {
          if (this.activeView === this.views.list) this.setCheckedState();
        },
      },
      searchValue() {
        this.$nextTick(() => {
          if (this.gridSearch) this.syncSearch();
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  $img-thumb-size: 48px;
  $icon-thumb-size: 36px;
  $thumb-border-color: #e5e7eb;
  $checkbox-size: 18px;

  :deep(.thumb-wrapper) {
    border: 1px solid $thumb-border-color;
  }

  :deep(.thumb) {
    width: $img-thumb-size;
    height: $img-thumb-size;

    &.has-icon {
      color: $file-thumb-color;
      line-height: $img-thumb-size;
      @include font-size($icon-thumb-size);
    }
  }

  :deep(.custom-checkbox) {
    width: $checkbox-size;
    height: $checkbox-size;
  }

  :deep(th[data-column-id="filename"]) {
    width: auto;
  }

  :deep(.gridjs-td[data-column-id="filename"]) {
    padding: 0;
    max-width: 360px;
    min-width: 240px;
    word-break: break-word;

    .td-wrapper {
      display: table-cell;
      padding: 12px 16px;
      width: 100%;
    }
  }

  :deep(.action-color) {
    color: $body-color;
    transition: color 0.15s ease;

    &:focus,
    &:hover {
      color: $link-color !important;
    }
  }
</style>
