import { getTranslation } from '@/utils/languages';

export default function checkAllMulti() {
  const triggers = [...document.querySelectorAll('.js-toggle-checked')];

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      let items = [];
      const target = document.getElementById(trigger.dataset.target);
      if (!target) {
        // individual box click - optional functionality
        if (trigger.value) {
          const targetId = `list-${trigger.dataset.formfieldid}`;
          const optionalButtonParent = document.querySelector(`button[data-target="${targetId}"]`);
          const optionalBoxes = document.getElementById(targetId);
          // the optional select button is optional
          if (optionalButtonParent) {
            // change the labels if necessary
            const checkboxes = [...optionalBoxes.querySelectorAll('input[type=checkbox]')];
            const checked = checkboxes.filter((input) => input.checked);
            // set parents labels correct
            if (checkboxes.length === checked.length) {
              // equal
              // eslint-disable-next-line no-param-reassign
              optionalButtonParent.innerHTML = getTranslation('deselectAll');
            } else {
              optionalButtonParent.innerHTML = getTranslation('selectAll');
            }
          }
        }
        return;
      }

      if (trigger.dataset.items) items = trigger.dataset.items.split(';');

      let checkboxes = [...target.querySelectorAll('input[type=checkbox]')];
      let checked = checkboxes.filter((input) => input.checked);

      // uncheck all if necessary
      if (checkboxes.length === checked.length && (items.length > 0)) {
        checkboxes.forEach((input) => {
          // eslint-disable-next-line no-param-reassign
          input.checked = false;
        });
      }

      if (items.length > 0) {
        items.forEach((item) => {
          const targetBox = document.getElementById(`cbx-${item}`);
          if (!targetBox) return;
          targetBox.checked = true;
        });
      } else {
        checkboxes.forEach((input) => {
          // eslint-disable-next-line no-param-reassign
          input.checked = checkboxes.length !== checked.length;
        });
      }

      // change the labels if necessary
      checkboxes = [...target.querySelectorAll('input[type=checkbox]')];
      checked = checkboxes.filter((input) => input.checked);

      const labelToUpdate = trigger.parentNode.querySelector('button[data-all="true"]');
      if (checkboxes.length === checked.length && (items.length < 1)) {
        // equal
        // eslint-disable-next-line no-param-reassign
        labelToUpdate.innerHTML = getTranslation('deselectAll');
      } else {
        labelToUpdate.innerHTML = getTranslation('selectAll');
      }
    });
  });
}
