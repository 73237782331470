import * as api from '@/api/v10/widgets';

// initial state
const data = () => ({
  allWidgets: [],
  pageWidgets: [],
  formId: null,
  recordId: null,
  activeDetailId: null,
  activeWidgetId: null,
});

const getters = {
  totalPageWidgets: (state) => state.pageWidgets.length,
  activePageWidget: (state) => state.pageWidgets.find((widget) => widget.recordDetailID === state.activeDetailId),
};

// actions
const actions = {
  /**
   * get all available widgets the user can choose from
   * @param commit
   * @returns {Promise}
   */
  getAllWidgets({ commit }) {
    return api.getWidgets()
      .then((response) => {
        commit('setAllWidgets', response.data.widgets);
        return response.data.widgets;
      })
      .catch((error) => console.error(error));
  },

  /**
   * add widget to page
   * @param commit
   * @param formData {FormData}
   * @returns {Promise}
   */
  addWidgetToPage({ commit }, formData) {
    return api.addWidgetToPage(formData)
      .then((response) => {
        commit('addWidgetToPage', response.data.widget);
        return response.data.widget;
      })
      .catch((error) => console.error(error));
  },

  /**
   * delete widget from page
   * @param commit
   * @param formData {FormData}
   */
  deleteWidgetFromPage({ commit }, formData) {
    return api.deleteWidgetFromPage(formData)
      .then((response) => {
        commit('deleteWidgetFromPage', String(formData.get('detailID')));
        return response.data.successMessage;
      })
      .catch((error) => console.error(error));
  },

  /**
   * get widgets that are present on the page
   * @param commit
   * @param formData {FormData}
   * @returns {Promise}
   */
  getPageWidgets({ commit }, formData) {
    return api.getPageWidgets(formData)
      .then((response) => {
        commit('setPageWidgets', response.data.relatedWidgets);
        return response.data.relatedWidgets;
      })
      .catch((error) => console.error(error));
  },
};

// mutations
const mutations = {
  /**
   * sets all available widgets for users to choose
   * @param state
   * @param widgets {Array}
   */
  setAllWidgets(state, widgets) {
    console.log('widgets/setAllWidgets');
    state.allWidgets = widgets;
  },

  /**
   * set widgets used on the current page
   * @param state
   * @param widgets {Array}
   */
  setPageWidgets(state, widgets) {
    console.log('widgets/setPageWidgets');
    state.pageWidgets = widgets;
  },

  /**
   * set the active form id
   * @param state
   * @param formId {String}
   */
  setFormId(state, formId) {
    console.log('widgets/setFormId');
    state.formId = formId;
  },

  /**
   * set the active record id
   * @param state
   * @param recordId {String}
   */
  setRecordId(state, recordId) {
    console.log('widgets/setRecordId');
    state.recordId = recordId;
  },

  /**
   * set the first active detail id
   * @param state
   * @param activeDetailId {String}
   */
  setActiveDetailId(state, activeDetailId) {
    console.log('widgets/setActiveDetailId');
    state.activeDetailId = activeDetailId;
  },

  /**
   * set the active widget id
   * @param state
   * @param widgetId {String}
   */
  setActiveWidgetId(state, widgetId) {
    console.log('widgets/setActiveWidgetId');
    state.activeWidgetId = widgetId;
  },

  /**
   * add widget to pageWidgets
   * @param state
   * @param widget {Object}
   */
  addWidgetToPage(state, widget) {
    console.log('widgets/addWidgetToPage');
    state.pageWidgets.push(widget);
  },

  /**
   * remove widget from pageWidgets
   * @param state
   * @param detailId {String}
   */
  deleteWidgetFromPage(state, detailId) {
    console.log('widgets/deleteWidgetFromPage');
    state.pageWidgets = state.pageWidgets.filter((widget) => widget.recordDetailID !== detailId);
  },
};

export default {
  namespaced: true,
  state: data, // fixes upper scope no-shadow - https://stackoverflow.com/a/54744970
  getters,
  actions,
  mutations,
};
