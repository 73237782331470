import * as api from '@/api/v10/datastore';

export const folderActions = {
  /**
   * get all folders (including sub-folders)
   * @param commit
   * @param cb {function}
   * @returns {Promise}
   */
  getAllFolders({ commit }) {
    return api.getFolders()
      // success
      .then((response) => {
        commit('setFolders', response.data);
        return response.data;
      })
      // fail
      .catch((error) => {
        commit('addMessage',
          {
            title: 'Unable to fetch folders.',
            type: 'danger',
          },
          { root: true });

        return Promise.reject(error);
      });
  },

  /**
   * get files from given folderId
   * @param commit
   * @param args {object} folderId, cb
   * @returns {Promise}
   */
  getFilesFromFolder({ commit }, args) {
    const formData = new FormData();
    formData.append('folderID', args.folderId);

    return api.getFilesFromFolder(formData)
      // success
      .then((response) => {
        commit('setFiles', response.data);
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to fetch files.',
            type: 'danger',
          },
          { root: true });
      });
  },

  /**
   * rename folder
   * @param commit
   * @param args {object} id, name
   * @returns {Promise}
   */
  renameFolder({ commit }, args) {
    const formData = new FormData();
    formData.append('folderID', args.id);
    formData.append('newFolderName', args.name);

    return api.renameFolder(formData)
      // success
      .then((response) => {
        if (response.data.isSuccess) {
          // successfully changed folder name
          commit('setFolderName', {
            id: args.id,
            name: args.name,
          });
        } else {
          // only show error message
          commit('addMessage', response.data.message, { root: true });
        }

        // sort folders
        commit('sortFolder', args.parentId);
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to rename folder.',
            type: 'danger',
          },
          { root: true });
      });
  },

  /**
   * add new folder
   * @param commit
   * @param args {object} parentId, name, tempId, cb
   * @returns {Promise}
   */
  addFolder({ commit }, args) {
    const formData = new FormData();
    formData.append('parentFolderID', args.parentId);
    formData.append('newFolderName', args.name);

    return api.addFolder(formData)
      // success
      .then((response) => {
        if (response.data.isSuccess) {
          // folder is added, commit
          commit('upgradeTempFolder', {
            folderId: response.data.folderId,
            folderName: args.name,
            tempId: args.tempId,
          });

          // set new folder as active
          commit('setActiveFolder', response.data.folderId);

          // sort folders
          commit('sortFolder', args.parentId);
        } else {
          // only show error message
          commit('addMessage', response.data.message, { root: true });
        }
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to add folder.',
            type: 'danger',
          },
          { root: true });
      })
      // always
      .then(() => {
        commit('deleteFolder', args.tempId);
        commit('deleteTempFolder', args.tempId);
      });
  },

  /**
   * delete folder (and sub-folders)
   * @param commit
   * @param ids {object} folderId, parentId
   * @returns {Promise}
   */
  deleteFolder({ commit }, ids) {
    const formData = new FormData();
    formData.append('folderID', ids.folderId);
    formData.append('parentFolderID', ids.parentId);

    return api.deleteFolder(formData)
      // success
      .then((response) => {
        if (response.data.isSuccess) {
          // remove temp folders
          commit('deleteFolder', ids.folderId);
          commit('setActiveFolder', ids.parentId);
        } else {
          // only show error message
          commit('addMessage', response.data.message, { root: true });
        }
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to remove folder.',
            type: 'danger',
          },
          { root: true });
      });
  },
};

export default {
  folderActions,
};
