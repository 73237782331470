import { createStore } from 'vuex';
import datastore from './modules/v10/datastore';
import widgets from './modules/v10/widgets';
import engine from './modules/engine';

export default createStore({
  state: {
    messages: [],
    activeContextMenuId: '',
  },
  actions: {},
  mutations: {
    /**
     * set right mouse click menu id
     * @param state
     * @param id {String}
     */
    setActiveContextMenuId(state, id) {
      state.activeContextMenuId = id;
    },

    /**
     * add message
     * @param state
     * @param message {object}
     */
    addMessage(state, message) {
      /**
       * 1. Get unique titles
       * 2. Find the title its corresponding object
       * 3. Return array of object with unique titles
       * */
      const tempMessages = state.messages;
      tempMessages.push(message);
      state.messages = [...new Set(tempMessages.map((m) => m.title))].map((title) => tempMessages.find((obj) => obj.title === title));
    },

    /**
     * remove message
     * @param state
     * @param message {object}
     */
    removeMessage(state, message) {
      state.messages.splice(state.messages.indexOf(message), 1);
    },
  },
  modules: {
    datastore,
    widgets,
    engine,
  },
});
