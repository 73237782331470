<template>
  <a :class="triggerClass"
     data-toggle="tooltip"
     data-placement="top"
     :ajax-params="ajaxParams"
     :url="url"
     :title="title"
     :original-title="originalTitle"></a>
</template>

<script>

  export default {
    name: 'actionHref',
    props: {
      url: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: false,
      },
      originalTitle: {
        type: String,
        required: false,
      },
      ajaxParams: {
        type: String,
        required: false,
      },
      triggerClass: {
        type: String,
        required: false,
      },
    },
    computed: {},
    data() {},
    methods: {
      dummy(value) {
        console.log(value);
      },
    },
    created() {
      console.log(JSON.parse(this.ajaxParams));
      console.log(this.url);
    },
    mounted() {},
  };
</script>

<style scoped lang="scss">

</style>
