import axios from 'axios';
import Sortable from 'sortablejs';
import { root } from '@/utils/paths';
import { getCritical } from '@/views/engine/utils/alerts';
import GridSearch from './gridSearch';
import GridExtendedSearch from './gridExtendedSearch';
import GridPaginator from './gridPaginator';
import GridSorter from './gridSorter';
import GridLevelFilter from './gridLevelFilter';
import GridActionLoadModalForm from './actions/loadModalForm';
import GridActionPublish from './actions/publishItem';
import GridActionDelete from './actions/deleteItem';
import GridActionOnOff from './actions/onOffItem';
import GridActionPlusMinItem from './actions/plusMinItem';
import GridFastAction from './actions/fastAction';
import GridActionHref from './actions/hrefAction';
import GridActionBtn from './actions/btnAction';
import GridActionChangeSelect from './actions/changeSelectItem';
import GridActionChangePositionSelect from './actions/changePositionSelectItem';
import GridActionBulkSelect from './actions/bulkSelect';
import GridActionBulkButton from './actions/bulkButton';

export default class Grid {
  /**
   * Constructor for the Grid class
   * @param gridEl {Element}
   */
  constructor(gridEl) {
    this.dom = {
      gridEl,
      gridSearchFormEl: gridEl.querySelector('.js-grid-search'),
      gridPaginatorEl: gridEl.querySelector('.js-grid-pagination'),
      gridExtendedSearchFormEl: [...gridEl.querySelectorAll('.js-ext-search')],
      gridLevelFilterEl: gridEl.querySelector('.js-level-filter'),
      gridSorters: [...gridEl.querySelectorAll('.gridjs-th-content')],
      gridActionPublishItems: [...gridEl.querySelectorAll('.js-publish-item')],
      gridActionDeleteItems: [...gridEl.querySelectorAll('.js-delete-item')],
      gridActionLoadFormModals: [...gridEl.querySelectorAll('.js-modal-action'), ...document.querySelectorAll('.js-modal-action-button')],
      gridActionOnOfItems: [...gridEl.querySelectorAll('.on-off-checkbox')],
      gridActionPlusMinItems: [...gridEl.querySelectorAll('.js-plusmin')],
      gridActionChangeSelectItems: [...gridEl.querySelectorAll('.js-change-select')],
      gridActionChangePositionSelectItems: [...gridEl.querySelectorAll('.js-change-position')],
      gridActionHrefItems: [...gridEl.querySelectorAll('.js-href-action')],
      gridActionBtnItems: [...gridEl.querySelectorAll('.js-btn-action')],
      gridActionBulkSelectItems: [...gridEl.querySelectorAll('.js-bulk-select')],
      gridFastActionItems: [...gridEl.querySelectorAll('.js-fast-action')],
      gridActionBulkButtons: [...gridEl.querySelectorAll('.js-bulk-button')],
    };
    this.props = {
      gridId: gridEl.dataset.gridid,
      ajaxUrl: gridEl.dataset.ajaxurl,
      activeTab: gridEl.dataset.tab ?? 1,
      ajaxParams: JSON.parse(gridEl.dataset.ajaxparams),
    };
    this.data = {
      ...{},
      ...JSON.parse(gridEl.dataset.opts),
    };
    this.components = {
      search: null,
      paginator: null,
      levelFilter: null,
      sorters: [],
      publishItems: [],
      deleteItems: [],
      loadFormModals: [],
      onOffCheckboxes: [],
      plusMinItems: [],
      changeSelects: [],
      positionSelects: [],
      hrefActions: [],
      btnActions: [],
      bulkSelects: [],
      bulkButtons: [],
      fastActions: [],
      extSearch: [],
    };
    this.events = {
      onPaginationUpdate: this.onPaginationUpdate.bind(this),
    };

    // bind all actions
    this.mount();
  }

  // bind the elements & actions
  mount() {
    console.log('mount grid component');
    // add search instance
    if (this.dom.gridSearchFormEl) {
      this.components.search = new GridSearch({
        dom: {
          gridSearchFormEl: this.dom.gridSearchFormEl,
        },
        props: this.props,
      });
    }

    // add an extended search instance
    if (this.dom.gridExtendedSearchFormEl) {
      this.components.extSearch = new GridExtendedSearch({
        dom: {
          gridExtendedSearchFormEl: this.dom.gridExtendedSearchFormEl,
        },
        props: this.props,
      });
    }

    // add paginator instance
    if (this.dom.gridPaginatorEl) {
      this.components.paginator = new GridPaginator({
        dom: {
          gridEl: this.dom.gridEl,
          gridPaginatorEl: this.dom.gridPaginatorEl,
        },
        props: this.props,
      });
      this.dom.gridEl.addEventListener('paginationUpdated', this.events.onPaginationUpdate);
    }

    // add level filter instance
    if (this.dom.gridLevelFilterEl) {
      this.components.levelFilter = new GridLevelFilter({
        dom: {
          gridEl: this.dom.gridEl,
          gridLevelFilterEl: this.dom.gridLevelFilterEl,
        },
        props: this.props,
      });
    }

    // add sorter instances
    this.dom.gridSorters.forEach((gridSorter) => {
      const gridSorterComponent = new GridSorter({
        dom: {
          gridSorterEl: gridSorter,
          gridSearchFormEl: this.dom.gridSearchFormEl,
        },
        props: this.props,
      });

      this.components.sorters.push(gridSorterComponent);
    });

    // add modal form instances
    this.dom.gridActionLoadFormModals.forEach((modal) => {
      const loadModalFormComponent = new GridActionLoadModalForm(modal);
      this.components.loadFormModals.push(loadModalFormComponent);
    });

    // add publish instances
    this.dom.gridActionPublishItems.forEach((publishItem) => {
      const publishItemComponent = new GridActionPublish(publishItem);
      this.components.publishItems.push(publishItemComponent);
    });

    // add delete instances
    this.dom.gridActionDeleteItems.forEach((deleteItem) => {
      const deleteItemComponent = new GridActionDelete(deleteItem, {
        props: this.props,
      });
      this.components.deleteItems.push(deleteItemComponent);
    });

    // add on/off instances
    this.dom.gridActionOnOfItems.forEach((onOffItem) => {
      const onOffItemComponent = new GridActionOnOff(onOffItem, {
        props: this.props,
      });
      this.components.onOffCheckboxes.push(onOffItemComponent);
    });

    // add plus/min items
    this.dom.gridActionPlusMinItems.forEach((plusMin) => {
      const plusMinComponent = new GridActionPlusMinItem(plusMin);
      this.components.plusMinItems.push(plusMinComponent);
    });

    // fast actions instances
    this.dom.gridFastActionItems.forEach((fastItem) => {
      const fastComponent = new GridFastAction(fastItem, {
        props: this.props,
      });
      this.components.fastActions.push(fastComponent);
    });

    // add change select instances
    this.dom.gridActionChangeSelectItems.forEach((changeSelectItem) => {
      const changeSelectItemComponent = new GridActionChangeSelect(changeSelectItem);
      this.components.changeSelects.push(changeSelectItemComponent);
    });

    // add change position select instances
    this.dom.gridActionChangePositionSelectItems.forEach((changeSelectItem) => {
      const changeSelectPositionComponent = new GridActionChangePositionSelect(changeSelectItem, {
        props: this.props,
      });
      this.components.positionSelects.push(changeSelectPositionComponent);
    });

    // add href action instances
    this.dom.gridActionHrefItems.forEach((hrefItem) => {
      // only execute if no modal form is loaded
      if (!hrefItem.classList.contains('open-in-modal') && !hrefItem.classList.contains('js-delete-item')) {
        const hrefComponent = new GridActionHref(hrefItem, {
          props: this.props,
        });
        this.components.hrefActions.push(hrefComponent);
      }
    });

    // add btn action instances
    this.dom.gridActionBtnItems.forEach((btnItem) => {
      const btnComponent = new GridActionBtn(btnItem, {
        props: this.props,
      });
      this.components.btnActions.push(btnComponent);
    });

    // bulk selections
    this.dom.gridActionBulkSelectItems.forEach((bulkSelectItem) => {
      const bulkSelectItemComponent = new GridActionBulkSelect(bulkSelectItem);
      this.components.bulkSelects.push(bulkSelectItemComponent);
    });

    // bulk buttons
    this.dom.gridActionBulkButtons.forEach((bulkButton) => {
      const bulkButtonComponent = new GridActionBulkButton(bulkButton, {
        props: this.props,
      });
      this.components.bulkButtons.push(bulkButtonComponent);
    });

    // sortable on the grid if available
    if (this.dom.gridEl.querySelector('[data-sortable]')) {
      const mainScope = this;
      Sortable.create(this.dom.gridEl.querySelector('[data-sortable]'), {
        // Called when dragging element changes position
        onUpdate(e) {
          // get all indexes
          const indexes = Array.prototype.slice.call(e.from.children)
            .map((el) => el.id);

          // pass the necessary data
          const formData = new FormData();
          formData.append('gridID', mainScope.props.gridId);
          formData.append('indexes', indexes);

          // make axios call
          axios({
            method: 'post',
            url: `${root}common/drop-position`,
            data: formData,
          })
            .then(() => {
              // apply the filter
              console.warn('gelukt');
            })
            .catch(() => getCritical());
        },
      });
    }
  }

  // unbind all elements & actions
  unmount() {
    console.log('unmount grid component');

    // remove pagination update
    this.dom.gridEl.removeEventListener('paginationUpdated', this.events.onPaginationUpdate);

    // remove search instance
    if (this.components.search) {
      this.components.search.unmount();
    }

    // remove extended search instance
    if (this.components.extSearch) {
      this.components.extSearch.unmount();
    }

    // remove paginator instance
    if (this.components.paginator) {
      this.components.paginator.unmount();
    }

    // remove level filter instance
    if (this.components.levelfilter) {
      this.components.levelfilter.unmount();
    }

    // remove sorter instances
    this.components.sorters.forEach((sorter) => {
      sorter.unmount();
    });

    // remove modal form instances
    this.components.loadFormModals.forEach((modal) => {
      modal.unmount();
    });

    // remove publish instances
    this.components.publishItems.forEach((publishItem) => {
      publishItem.unmount();
    });

    // remove delete instances
    this.components.deleteItems.forEach((deleteItem) => {
      deleteItem.unmount();
    });

    // remove on/off instances
    this.components.onOffCheckboxes.forEach((onOffItem) => {
      onOffItem.unmount();
    });

    // remove plus/min items
    this.components.plusMinItems.forEach((plusMin) => {
      plusMin.unmount();
    });

    // fast actions instances
    this.components.fastActions.forEach((fastItem) => {
      fastItem.unmount();
    });

    // remove change select instances
    this.components.changeSelects.forEach((changeSelectItem) => {
      changeSelectItem.unmount();
    });

    // remove change position select instances
    this.components.positionSelects.forEach((changeSelectItem) => {
      changeSelectItem.unmount();
    });

    // remove href action instances
    this.components.hrefActions.forEach((hrefItem) => {
      hrefItem.unmount();
    });

    // remove btn action instances
    this.components.btnActions.forEach((btnItem) => {
      btnItem.unmount();
    });

    // remove bulk select instances
    this.components.bulkSelects.forEach((bulkSelectItem) => {
      bulkSelectItem.unmount();
    });

    // bulk buttons
    this.components.bulkButtons.forEach((bulkButton) => {
      bulkButton.unmount();
    });

    // delete all to components (garbage clean)
    this.components = null;
  }

  /**
   * update the gotoTab prop
   */
  onPaginationUpdate(e) {
    // pass the activeTab from the paginator
    this.props.activeTab = e.detail;

    // loop all delete items and update the property
    this.components.deleteItems.forEach((deleteItem) => {
      // eslint-disable-next-line no-param-reassign
      deleteItem.props.activeTab = this.props.activeTab;
    });
  }
}
