import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';

export default class GridActionChangeSelect {
  /**
   * delete an item
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      pk: this.dom.action.dataset.pk,
      table: this.dom.action.dataset.table,
      tablepk: this.dom.action.dataset.tablepk,
      field: this.dom.action.dataset.field,
      newvalue: null,
    };
    this.events = {
      changeSelect: this.changeSelectItem.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('change', this.events.changeSelect);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('change', this.events.changeSelect);
  }

  /**
   * change the select item
   *
   * @param e {MouseEvent}
   */
  changeSelectItem(e) {
    // don't take the native click event
    e.preventDefault();

    // the new value
    this.props.newvalue = e.target.value;

    // check required parameters
    if (!this.props.pk || !this.props.table || !this.props.field) {
      // throw critical alert
      getCritical();
    } else {
      this.data.formData = new FormData();
      this.data.formData.append('table', this.props.table);
      this.data.formData.append('table-pk', this.props.tablepk);
      this.data.formData.append('pk', this.props.pk);
      this.data.formData.append('field-to-update', this.props.field);
      this.data.formData.append('newvalue', this.props.newvalue);

      // make axios call
      axios({
        method: 'post',
        url: `${root}common/change-related-item`,
        data: this.data.formData,
      })
        .then((response) => {
          if (!response.data.success) {
            // send error notification
            const notification = { title: response.data.errorMessage, type: 'danger' };
            this.customEvents.notificationError = getErrorNotification(notification);
            this.dom.engine.dispatchEvent(this.customEvents.notificationError);
            // throw critical alert
            // getCritical();
          }

          // some useful debug info
          if (response.data.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }
        })
        .catch(() => getCritical());
    }
  }
}
