<template>
  <matrix-file-upload-item-skeleton v-if="isLoading"/>
  <template v-else>
    <template v-if="items.length < 1">
      <div class="text-center py-5">
        <img class="img-fluid mb-5" :src="svgEmpty" alt="no_components_added" width="200">

        <div class="mw-40 mx-auto mb-2 text-muted">
          <h2>Er zijn nog geen slides toegevoegd</h2>
          <div class="cms-text">
            <p>Laten we starten door een slide toe te voegen.</p>
          </div>
          <button class="btn btn-xl btn-primary mt-4"
                  type="button"
                  @click="addMatrixItem">
            Slide toevoegen
          </button>
        </div>
      </div>
    </template>
    <div v-show="items.length > 0">
      <div v-if="disableAddNewItem"
           class="alert alert-warning alert-dismissible alert-limit fade show"
           role="alert">
        Je kan maximaal {{ matrixField.settings?.maxMatrices }} slides toevoegen.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div ref="matrixItems">
        <matrix-file-upload-item
          v-for="(item) in items" :key="item.settings.uid"
          @addMatrixItem="addMatrixItem"
          :data-uid="item.settings.uid"
          :formFieldId="formFieldId"
          :html="item?.html"
          :settings="item.settings"
          :ref="setItemRef">
        </matrix-file-upload-item>
      </div>
      <div class="row gx-2 py-3 position-sticky bottom-0 left-0 sticky-wrapper">
        <button class="btn btn-primary d-flex align-items-center mr-2"
                :disabled="disableAddNewItem"
                type="button"
                @click="addMatrixItem">
          <i class="bi bi-plus-circle-fill mr-2 text-16"></i>
          {{ btnAddComponentText }}
        </button>

        <div :class="{ 'disabled': items.length < 1 }" class="dropdown" ref="dropdownAction">
          <button class="btn btn-outline-gray dropdown-toggle hide-arrow d-flex align-items-center justify-content-center box-shadow-none h-100 px-3"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="setToggleState">
            <i class="bi bi-gear-fill text-16 text-dark"></i>
            <i class="bi bi-chevron-down text-dark text-12 ml-2"></i>
            <!--        <i class="bi bi-gear-fill"></i>-->
            <span class="sr-only">Instellingen</span>
          </button>
          <div class="dropdown-menu text-14">
            <button class="dropdown-item"
                    type="button"
                    @click="toggleAll">
              <span class="show-all">
                <i class="bi bi-arrows-expand mr-1"></i>
                Alles openklappen
              </span>
              <span class="hide-all">
                <i class="bi bi-arrows-collapse mr-1"></i>
                Alles toeklappen
              </span>
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item text-danger"
                    type="button"
                    @click="deleteAll">
              <i class="bi bi-x-circle mr-1"></i>
              Alles verwijderen
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Sortable from 'sortablejs';
  import $ from 'jquery';
  import matrixFileUploadItem from '@/components/matrix/MatrixFileUploadItem';
  import matrixFileUploadItemSkeleton from '@/components/matrix/MatrixFileUploadItemSkeleton';
  import svgEmpty from '@/assets/img/empty_page.svg';

  export default {
    name: 'matrix-file-upload',
    props: {
      pk: {
        type: String,
        required: true,
      },
      formId: {
        type: String,
        required: true,
      },
      formFieldId: {
        type: String,
        required: true,
      },
      thumbText: {
        type: String,
        required: false,
        default: 'Voeg bestand toe',
      },
      btnAddComponentText: {
        type: String,
        required: false,
        default: 'Component toevoegen',
      },
    },
    data() {
      return {
        sortable: null,
        isLoading: true,
        showConfirmation: true,
        itemRefs: [],
        svgEmpty,
      };
    },
    computed: {
      items() {
        return this.matrixField.items;
      },
      disableAddNewItem() {
        return this.matrixField.items?.length >= this.matrixField.settings?.maxMatrices;
      },
      matrixField() {
        return this.matrixUploadFields[this.formFieldId];
      },
      nextUID() {
        return this.getNextMatrixFieldUID(this.formFieldId);
      },
      /**
       * Returns whether all matrixUploadItems are collapsed or not
       * @returns {boolean}
       */
      isAllCollapsed() {
        return this.itemRefs.every((ref) => ref.isCollapsed === true);
      },
      matrixUploadFields: {
        get() {
          return this.$store.state.engine.matrixUploadFields;
        },
        set(value) {
          this.$store.commit('engine/setMatrixUploadFields', value);
        },
      },
      ...mapGetters({
        getNextMatrixFieldUID: 'engine/getNextMatrixFieldUID',
      }),
    },
    components: {
      matrixFileUploadItem,
      matrixFileUploadItemSkeleton,
    },
    methods: {
      /**
       * Keep track of component instances
       * @param matrixFileUploadItemInstance {Object}
       */
      setItemRef(matrixFileUploadItemInstance) {
        if (matrixFileUploadItemInstance) this.itemRefs.push(matrixFileUploadItemInstance);
      },

      /**
       * Add new item
       * @param e {event}
       * @param index {number}
       */
      async addMatrixItem(e, index = undefined) {
        const nextId = this.nextUID;
        const position = index === undefined ? nextId : index;

        // build new item
        const item = {
          html: this.matrixField.template,
          settings: {
            uid: nextId,
            position,
            thumb: null,
          },
        };

        // add to form field
        this.matrixField.items.splice(position, 0, item);

        // update position param
        this.matrixField.items.forEach((mf, i) => {
          // eslint-disable-next-line no-param-reassign
          mf.settings.position = i;
        });

        // wait for DOM update
        await this.$nextTick();

        // move item into view
        this.itemRefs
          .find((ref) => ref.settings.position === position)
          .$el
          .scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
      },

      // Keep track if the form is modified
      trackFormChanges() {
        // Sync track form changes
        const engine = document.getElementById('engine');
        if (engine) {
          const formTrackChangesEvent = new Event('formTrackChanges');
          engine.dispatchEvent(formTrackChangesEvent);
        }
      },

      // Toggle all matrixUploadItems
      toggleAll() {
        if (this.itemRefs.length < 1) return;

        // check if all are collapsed or expanded
        const allCollapsed = this.isAllCollapsed;

        // set collapse state
        this.itemRefs.forEach((ref) => {
          // eslint-disable-next-line no-param-reassign
          ref.isCollapsed = !allCollapsed;
        });

        this.setToggleState();
      },

      // Set dropdown classes indicating if all are collapsed or not
      setToggleState() {
        // alias ref
        const dropdown = this.$refs.dropdownAction;
        // check if all are collapsed or expanded
        const allCollapsed = this.isAllCollapsed;

        // no dropdown, ignore class change
        if (!dropdown) return;

        // binding to v-if or v-show causes infinite loop (recursive calls)
        if (allCollapsed) {
          dropdown.classList.add('all-expanded');
        } else {
          dropdown.classList.remove('all-expanded');
        }
      },

      updatePositions() {
        // Get files of current form field
        const values = this.matrixField;
        const items = Object.values(values?.items);

        // Loop dom items (for position)
        [...this.$refs.matrixItems.children].forEach((el, index) => {
          // Get file matching DOM el
          const item = items.find((o) => o.settings.uid === parseInt(el.dataset.uid, 10));
          // Set index
          if (item) item.settings.position = index;
        });
      },

      // Delete all matrixUploadItems
      deleteAll() {
        if (this.showConfirmation) {
          // pass formFieldId to modal
          this.$store.commit('engine/setMatrixModalFormFieldId', this.formFieldId);
          // show modal
          $('#matrix-items-delete-modal')
            .modal('show');
        } else {
          // force reflow to trigger dropdown close
          setTimeout(() => {
            // delete items
            this.matrixField.items = [];
          }, 0);
        }
      },
    },
    async mounted() {
      // fetch matrices
      await this.$store.dispatch('engine/getMatrixData', {
        pk: this.pk,
        formId: this.formId,
        formFieldId: this.formFieldId,
      });

      // set loading state
      // (!) must be set before nextTick or references will be undefined
      this.isLoading = false;
      // wait for dom update
      await this.$nextTick();
      // track form changes
      this.trackFormChanges();
      // init sortable
      this.sortable = Sortable.create(this.$refs.matrixItems, {
        onUpdate: this.updatePositions,
      });
    },
    beforeUnmount() {
      if (this.sortable) this.sortable.destroy();
      this.isLoading = true;
    },
    beforeUpdate() {
      this.itemRefs = [];
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .file-upload {
    margin-bottom: 0 !important;
  }

  .sticky-wrapper {
    $gx: $spacer * 1.5;

    margin-left: -$gx;
    margin-right: -$gx;
    padding-left: $gx;
    padding-right: $gx;
    background-color: $white;
    z-index: $zIndex-4;
  }

  .dropdown.all-expanded .hide-all {
    display: none;
  }

  .dropdown:not(.all-expanded) .show-all {
    display: none;
  }

  .alert-limit {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 900;
  }
</style>
