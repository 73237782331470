<template>
  <div class="instant-edit">
    <input
        v-if="isRenaming"
        v-model.trim="currentValue"
        :disabled="isLoading"
        @keyup.esc="renameCancel"
        @keyup.enter="renameConfirm"
        ref="inputRename"
        type="text"
        class="form-control input-rename">

      <!-- Folder name -->
      <a
        v-else
        :data-table="table"
        :data-tablepk="tablepk"
        :data-pk="pk"
        :data-value="currentValue"
        @click.prevent="editClick"
        class="d-flex align-items-center text-truncate">
        {{ currentValue }}
      </a>
  </div>
</template>
<script>

import axios from 'axios';
import { root } from '@/utils/paths';
import { getTranslation } from '@/utils/languages';

export default {
  name: 'instantedit',
  props: {
    table: {
      type: String,
      required: true,
    },
    tablepk: {
      type: String,
      required: true,
    },
    pk: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
  },
  computed: {},
  data() {
    return {
      isRenaming: false,
      isLoading: false,
      currentValue: '',
      oldValue: '',
    };
  },
  methods: {
    /**
     * name change cancelled
     */
    renameCancel() {
      // reset some states
      this.currentValue = this.oldValue;
      this.resetState();
    },

    /**
     * name change confirmed
     */
    renameConfirm() {
      // ignore is text is empty
      if (this.currentValue === '' || this.currentValue === this.oldValue) {
        return;
      }

      // set the formdata
      const data = new FormData();
      const dataset = {};
      dataset.table = this.table;
      dataset.tablepk = this.tablepk;
      dataset.field = this.field;
      dataset.pk = this.pk;

      data.append('dataset', JSON.stringify(dataset));
      data.append('value', this.currentValue);

      // make axios call
      axios({
        method: 'post',
        url: `${root}common/save-inline-edit`,
        data,
      })
        .then((response) => {
            // log the response
          if (response.data.type === 'danger') {
            // cancel the renaming
            this.renameCancel();

            // notification error
            this.$root.$store.commit('addMessage', {
              title: response.data.title,
              type: 'danger',
            });
          }

          // set the new value
          this.oldValue = this.currentValue;
          this.resetState();
        })
        .catch((error) => {
          // cancel the renaming
          this.renameCancel();

          // handle error
          console.log(error);

          // notification error
          this.$root.$store.commit('addMessage', {
            title: getTranslation('something went wrong'),
            type: 'danger',
          });
        });
    },
    resetState() {
      // set some states
      this.$refs.inputRename.blur();
      this.isRenaming = false;
      this.isLoading = false;
    },
    /**
     * clicked on folder
     * set active state
     */
    editClick() {
      // some logging
      console.log('edit');
      this.isRenaming = true;

      // the current value > name
      // this.currentValue = this.value;
      this.oldValue = this.currentValue;

      // select value on dom update
      this.$nextTick(() => {
        this.$refs.inputRename.select();
      });
    },
  },
  created() {
    // set the current value
    this.currentValue = this.value;
  },
  mounted() {
    // console.log('mounted');
  },
};
</script>

<style scoped lang="scss">

</style>
