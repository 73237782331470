<template>
  <figure class="mb-0" :style="`width: ${thumbSize}px;`">
    <div class="thumb d-flex align-items-center justify-content-center position-relative overflow-hidden"
         ref="thumb">
      <img v-if="file.thumb"
           class="of-contain"
           :src="file.thumb"
           :title="file.thumb"
           :alt="alt"
           :width="thumbSize"
           :height="thumbSize"
           loading="lazy">
      <i v-else
         class="d-flex align-items-center justify-content-center"
         :style="`width: ${thumbSize}px; height: ${thumbSize}px; font-size: ${iconFontSize}px;`"
         :class="getExtensionIconClass(file.fileExtension, true)">
      </i>

      <button class="btn btn-delete p-2 border-0 box-shadow-none d-flex align-items-center justify-content-center"
              type="button"
              :disabled="isRemoving"
              @click="deleteThumb">
        <i v-if="isRemoving" class="spinner d-inline-block"></i>
        <i v-else class="far fa-trash-alt"></i>
      </button>
    </div>
    <figcaption class="text-truncate mt-2 text-14 pr-2">{{ file.fileName }}</figcaption>
  </figure>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import mixinFile from '@/mixins/file';

  export default {
    name: 'file-upload-thumb',
    mixins: [mixinFile],
    props: {
      doubleRelationFk: {
        type: String,
        required: true,
      },
      file: {
        type: Object,
        required: true,
      },
      thumbSize: {
        type: Number,
        required: false,
        default: 128, // in px
      },
      iconFontSize: {
        type: Number,
        required: false,
        default: 48, // in px
      },
    },
    data() {
      return {
        isRemoving: false,
      };
    },
    computed: {
      alt() {
        const segments = this.file.thumb.split('/');
        return segments[segments.length - 1];
      },
      ...mapState({
        formFieldData: (state) => state.engine.formFieldData,
      }),
      ...mapGetters({
        getFormFieldDataById: 'engine/getFormFieldDataById',
      }),
    },
    methods: {
      deleteThumb() {
        // disable remove
         this.isRemoving = true;

        // delete file
        this.$store.commit('engine/deleteFormFieldFiles', {
          id: this.doubleRelationFk,
          fileIds: [this.file.fileUID],
        });

        // enable remove
        this.isRemoving = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";
  @import "node_modules/spinthatshit/src/loaders";

  $thumb-border-color: #e5e7eb;

  figure {
    cursor: move;
  }

  .thumb {
    border: 1px solid $thumb-border-color;
    border-radius: $border-radius;
    color: $file-thumb-color;
  }

  .btn-delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zIndex-1;

    &,
    &:hover,
    &:focus {
      background-color: $white;
    }

    &:hover,
    &:focus {
      color: $danger;
    }
  }

  .spinner {
    $size: 16px;

    @include loader01($size: $size, $color: $gray-200, $border-size: 2px, $duration: 0.5s);
  }
</style>
