<template>
  <file-upload-skeleton v-if="isLoading"/>
  <template v-else>
    <div class="alert alert-danger d-block text-12 mb-4" v-if="formField?.files < formField?.settings?.minFiles">
      <file-upload-restrictions
        :min-files="formField?.settings?.minFiles"
        :max-files="formField?.settings?.maxFiles"
        :custom-selected="formFieldFileFilesLength"/>
    </div>

    <ul v-show="hasFiles"
        :class="{'list-unstyled d-flex flex-wrap mx-n1 mt-n4 pl-0': hasFiles}"
        ref="listThumbs">
      <li v-for="file in formField?.files" :data-file-uid="file.fileUID" :key="file.fileUID" class="px-1 mt-4">
        <thumb :file="file"
               :thumb-size="thumbSize"
               :double-relation-fk="pk"
               :ref="setThumbRef"/>
        <input type="hidden" :name="`rel[${formFieldId}][FileIds][]`" :value="file.fileUID">
      </li>
    </ul>

    <div v-show="!hasFiles"
         :class="{'thumb-placeholder d-flex align-items-center justify-content-center': !hasFiles}"
         :style="`width: ${thumbSize}px; height: ${thumbSize}px;`"
         @click="$refs.btnAdd.click();">
      <div class="p-4 text-center">
        <i class="bi bi-image opacity-50"></i>
        <div class="text-13 mt-3" v-show="showThumbText">
          {{ thumbText }}
        </div>
      </div>
    </div>

    <div class="py-3 bg-white position-sticky bottom-0 z-2">
      <div class="d-flex flex-wrap mx-n1 mt-n2">
        <div class="px-1 pt-2" v-show="showAddButton">
          <button
            class="btn btn-primary d-flex align-items-center"
            type="button"
            ref="btnAdd"
            :disabled="isLoading"
            @click="onModalButtonClick">
            <i class="bi bi-plus-circle-fill mr-2 text-16"></i>
            {{ btnAddText }}
          </button>
        </div>

        <div v-if="hasFiles" class="px-1 pt-2">
          <button
            class="btn btn-outline-danger"
            type="button"
            ref="btnRemoveAll"
            :disabled="isLoading"
            @click="onDeleteAllClick">
            {{ btnRemoveText }}
          </button>
        </div>
      </div>
    </div>
  </template>
  <input type="hidden" :name="`rel[${formFieldId}][FileIds][]`">
</template>

<script>
  import { mapGetters } from 'vuex';
  import $ from 'jquery';
  import Sortable from 'sortablejs';
  import thumb from '@/components/upload/FileUploadThumb';
  import fileUploadRestrictions from '@/components/upload/FileUploadRestrictions';
  import mixinFormFieldFiles from '@/mixins/engine/form/formFieldsFiles';
  import fileUploadSkeleton from '@/components/upload/FileUploadSkeleton';

  export default {
    name: 'file-upload',
    mixins: [mixinFormFieldFiles],
    props: {
      pk: {
        type: String,
        required: true,
      },
      formFieldId: {
        type: String,
        required: true,
      },
      formId: {
        type: String,
        required: true,
      },
      childId: {
        type: String,
        required: false,
      },
      btnAddText: {
        type: String,
        required: false,
        default: 'Bestand toevoegen',
      },
      btnRemoveText: {
        type: String,
        required: false,
        default: 'Leegmaken',
      },
      thumbSize: {
        type: [String, Number],
        required: false,
        default: 192,
      },
      thumbText: {
        type: String,
        required: false,
        default: 'Voeg bestand toe',
      },
      showDeleteAllModal: {
        type: Boolean,
        required: false,
        default: true,
      },
      showAddButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      showThumbText: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        sortable: null,
        isLoading: true,
        thumbRefs: [],
      };
    },
    computed: {
      formField() {
        return this.getFormFieldDataById(this.pk);
      },
      formFieldFileFilesLength() {
        return this.formField?.files?.length ?? 0;
      },
      hasFiles() {
        if (this.formField?.files) return this.formField.files.length > 0;
        return false;
      },
      formFieldData: {
        get() {
          return this.$store.state.engine.formFieldData;
        },
        set(value) {
          this.$store.commit('engine/setFormFieldData', value);
        },
      },
      ...mapGetters({
        getFormFieldDataById: 'engine/getFormFieldDataById',
      }),
    },
    components: {
      thumb,
      fileUploadRestrictions,
      fileUploadSkeleton,
    },
    methods: {
      /**
       * Keep track of thumb component instances
       * @param thumbComponent {Object} FileUploadThumb
       */
      setThumbRef(thumbComponent) {
        if (thumbComponent) this.thumbRefs.push(thumbComponent);
      },

      // Set activeFormField
      setActiveFormField() {
        this.$store.commit('engine/setActiveFormField', {
          ...{
            pk: this.pk,
            formId: this.formId,
            formFieldId: this.formFieldId,
          },
          ...this.formField,
        });
      },

      // Changed sorting within list
      onSortableUpdate() {
        // Get files of current form field
        const files = Object.values(this.formField.files);

        // Loop dom items (for position)
        [...this.$refs.listThumbs.children].forEach((el, index) => {
          // Get file matching DOM el
          const file = files.find((f) => f.fileUID === el.dataset.fileUid);
          // Set index
          if (file) file.position = index;
        });

        // Update files
        this.$store.commit('engine/setFormFieldFiles', {
          formFieldId: this.pk,
          files,
        });
      },

      // Modal button has been clicked
      onModalButtonClick() {
        // sort files based on position
        // the folder of the first file will be set active
        const files = this.formField?.files?.sort((first, second) => first.position - second.position);

        // set upload modal settings
        this.$store.commit('engine/setUploadModalSettings', {
          files,
          restrictions: this.formField.settings,
          formFieldId: this.pk,
          action: 'engine/setFormFieldFiles',
        });

        // show modal
        $('#modal-library')
          .modal('show');
      },

      // On delete all click
      onDeleteAllClick() {
        this.setActiveFormField();

        if (this.showDeleteAllModal) {
          // Store thumb references
          this.$store.commit('engine/setThumbRefsToBeDeleted', this.thumbRefs);

          // Show confirmation modal
          $('#delete-thumbs-modal')
            .modal('show');
        } else {
          this.deleteFormFieldFiles();
        }
      },
    },
    async mounted() {
      // Get form field files
      // setTimeout(() => { return true; }, 2000);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await this.$store.dispatch('engine/getFormFieldData', {
        pk: this.pk,
        formId: this.formId,
        formFieldId: this.formFieldId,
      });

      // update state
      this.isLoading = false;

      // wait for dom update
      await this.$nextTick();

      // init sortable
      this.sortable = Sortable.create(this.$refs.listThumbs, {
        onUpdate: this.onSortableUpdate,
      });
    },
    beforeUpdate() {
      this.thumbRefs = [];
    },
    beforeUnmount() {
      if (this.sortable) this.sortable.destroy();
      this.isLoading = true;
    },
  };
</script>

<style scoped lang="scss">
  .thumb-placeholder {
    border-radius: 4px;
    cursor: pointer;

    // https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg " +
    "width='100%25' " +
    "height='100%25' " +
    "xmlns='http://www.w3.org/2000/svg'%3e%3crect " +
    "width='100%25' " +
    "height='100%25' " +
    "rx='4' " +
    "ry='4' " +
    "fill='none' " +
    "stroke='%238897AAFF' " +
    "stroke-width='2' " +
    "stroke-dasharray='4%2c 8' " +
    "stroke-dashoffset='5' " +
    "stroke-linecap='round'/%3e%3c/svg%3e");

    .bi-image {
      font-size: 48px;
      transition: opacity 0.25s;
    }

    &:hover .bi-image {
      opacity: 1 !important;
    }
  }
</style>
