import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/code';

import { getTinyLanguageCode } from '@/utils/languages'; // gives a path error -> to fix!!

/* init tiny mce */
export default class TinyMce {
  constructor(settings = {}) {
    this.tinymce = tinymce;
    this.settings = {
      ...{
        selector: '.tiny-mce',
        language: getTinyLanguageCode(),
        menubar: false, // no menubar
        skin: 'oxide',
        theme: 'silver',
        height: 240,
        resize: true, // stretch vertical only
        plugins: 'link,paste,media,fullscreen,code', // ,autoresize
        toolbar: 'formatselect | bold italic underline | alignleft aligncenter alignright | link unlink | bullist numlist | undo redo | code',
        // autoresize_max_height: 400, // adapt to max 400 px
        // autoresize_bottom_margin: 5, // adapt to min 5px
        setup(editor) {
          editor.on('change', () => {
            tinymce.triggerSave();
          });
        },
      },
      ...settings,
    };
  }

  init() {
    this.tinymce.init(this.settings);
  }

  destroy() {
    this.tinymce.remove();
  }
}
