<template>
  <template v-if="isLoading">
    <widgetDetailSkeleton/>
  </template>
  <template v-else>
    <div class="row" v-if="pageWidgets.length > 0">
      <div class="col">
        <slot name="widget-edit"></slot>
      </div>

      <div class="col-md-4">
        <div class="pl-lg-5">
          <button class="btn btn-xl btn-secondary w-100"
                  :disabled="allWidgets.length < 1"
                  type="button"
                  data-toggle="modal"
                  data-target="#modal-add-widget">
            Widget toevoegen
          </button>
          <div class="mt-3" v-show="allWidgets.length < 1">
            <input type="text" class="form-control" placeholder="Search for...">
          </div>
        </div>

        <div class="widget-thumbs mt-2 pl-lg-5" ref="widgetThumbs">
          <widget-thumb v-for="(widget, index) in pageWidgets"
                        :key="widget.widgetID"
                        :widget-id="widget.widgetID"
                        :detail-id="widget.recordDetailID"
                        :thumb="widget.preview"
                        :description="widget.description"
                        :class="{ 'mt-1': index > 0 }"
                        @updateForm="updateForm"
          />
        </div>
      </div>
    </div>
    <div class="text-center py-5" v-else>
      <img class="img-fluid mb-5" :src="svgPageEmpty" alt="page_is_empty" width="200">

      <div class="mw-40 mx-auto mb-2 text-muted">
        <h2>Hmm, deze pagina lijkt leeg te zijn.</h2>
        <div class="cms-text">
          <p>Er zijn nog geen widgets toegevoegd aan deze pagina. Zoals elk mooi verhaal begint ook deze met het toevoegen van een widget.</p>
        </div>
        <button class="btn btn-xl btn-primary mt-4"
                :disabled="allWidgets.length < 1"
                type="button"
                data-toggle="modal"
                data-target="#modal-add-widget">
          Let's add some magic
        </button>
      </div>
    </div>

    <modal
      id="modal-add-widget"
      size="xl"
      ref="modalAddWidget"
      :isModalDialogCentered="true">
      <template v-slot:header>
        <div class="modal-header justify-content-center text-center pb-4">
          <h5 class="modal-title mt-1">
            Widget toevoegen
          </h5>
          <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </template>
      <template v-slot:body>
        <div class="modal-body d-flex justify-content-center pt-4">
          <div class="row form-row mt-n2 mx-n1">
            <div class="col-md-6 col-xl-4 p-1"
                 v-for="(widget) in allWidgets"
                 :key="widget.WidgetID"
            >
              <widget-add
                :widget-id="widget.WidgetID"
                :thumb="widget.preview"
                :name="widget.WidgetName"
                :description="widget.Description"
                @updateForm="updateForm"
                @onComplete="hideAddWidgetModal"
              />
            </div>
          </div>
        </div>
      </template>
    </modal>
  </template>
</template>

<script>
  import { mapState } from 'vuex';
  import Sortable from 'sortablejs';

  // components
  import modal from '@/components/modal/Modal';

  // partials
  import svgPageEmpty from '@/assets/img/empty_page.svg';
  import widgetThumb from './Thumb';
  import widgetAdd from './Add';
  import widgetDetailSkeleton from './WidgetDetailSkeleton';

  export default {
    name: 'widget-detail',
    components: {
      modal,
      widgetAdd,
      widgetThumb,
      widgetDetailSkeleton,
    },
    props: {
      formId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      activeDetailId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState({
        pageWidgets: (state) => state.widgets.pageWidgets,
        allWidgets: (state) => state.widgets.allWidgets,
        theActiveDetailId: (state) => state.widgets.activeDetailId,
      }),
    },
    data() {
      return {
        sortable: null,
        isLoading: true,
        svgPageEmpty,
      };
    },
    methods: {
      initSortable() {
        // @note drop native js plugins for vue component
        if (this.$refs.widgetThumbs) {
          this.sortable = Sortable.create(this.$refs.widgetThumbs, {
            onUpdate: this.onThumbPositionChange,
          });
        }
      },
      onThumbPositionChange(e) {
        console.log(e);
        // if (!Request::POST("sourceposition") || !Request::POST("targetposition") || !Request::POST("recordDetailId")) formId
      },

      /**
       * replace form by a new one
       * @param payload {Object}
       */
      updateForm(payload) {
        const engine = document.getElementById('engine');
        const formEl = document.querySelector('.js-c-form');
        const { id } = formEl;

        const formBeforeReplace = new CustomEvent('formBeforeReplace', { detail: { id } });
        const formAfterReplace = new CustomEvent('formAfterReplace', { detail: { id } });

        // dispatch form before replace event
        engine.dispatchEvent(formBeforeReplace);

        // replace from
        document.getElementById('js-widget-replace').innerHTML = payload.html;

        // dispatch grid after replace event
        engine.dispatchEvent(formAfterReplace);

        // update active values
        this.$store.commit('widgets/setActiveDetailId', payload.detailId);
        this.$store.commit('widgets/setActiveWidgetId', payload.widgetId);

        // save the detail id
        document.getElementById('detailID').value = payload.detailId;
        document.getElementById('js-active-widget-id-widget').value = payload.widgetId;
      },

      hideAddWidgetModal() {
        this.$refs.modalAddWidget.hide();
      },
    },
    async mounted() {
      // set formData
      const formData = new FormData();
      formData.append('formID', this.formId);
      formData.append('recordID', this.recordId);
      formData.append('detailID', this.detailId);

      // set necessary ids
      this.$store.commit('widgets/setFormId', this.formId);
      this.$store.commit('widgets/setRecordId', this.recordId);
      this.$store.commit('widgets/setActiveDetailId', this.activeDetailId);

      // get all widgets
      // @todo > check for live version
      const allWidgets = await this.$store.dispatch('widgets/getAllWidgets');
      if (allWidgets.length > 0) {
        // wait for page widgets to load
        const widgets = await this.$store.dispatch('widgets/getPageWidgets', formData);

        // set first widget as active
        if (widgets && widgets.length > 0) {
          this.$store.commit('widgets/setActiveDetailId', widgets[0].recordDetailID);
          this.$store.commit('widgets/setActiveWidgetId', widgets[0].widgetID);
        }
      }

      // set loading state
      // (!) must be set before nextTick or references will be undefined
      this.isLoading = false;
      // wait for dom update
      await this.$nextTick();

      this.initSortable();

      const engineEl = document.getElementById('engine');
      engineEl.addEventListener('setReferencePK', (e) => {
        if (e.detail.pk) {
          // this.$store.commit('widgets/setActiveDetailId', e.detail.pk);
          this.$store.commit('widgets/setRecordId', e.detail.pk);
        }
      });
    },
    updated() {
      const engine = document.getElementById('engine');
      const formEls = document.querySelectorAll('.js-c-form');

      formEls.forEach((form) => {
        const { id } = form;
        const formAfterReplace = new CustomEvent('formAfterReplace', { detail: { id } });

        // dispatch grid after replace event
        engine.dispatchEvent(formAfterReplace);
      });
    },
    watch: {
      pageWidgets: {
        deep: true,
        handler() {
          this.initSortable();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .widget-thumbs {
    //max-height: calc(100vh - 128px);
    //max-height: 60vh;
    //overflow: auto;
  }
</style>
