<template>
  <div class="card-body media align-items-center">
    <img class="profile-picture img-fluid d-block ui-w-120 rounded-circle cursor-pointer"
         alt=""
         ref="profilePicture"
         :src="src"
         @click="triggerUpload">

    <div class="media-body ml-4">
      <dropzone
        :use-custom-slot=true
        :autoDiscover=false
        :options="{
              url: `${root}datastore/profile-file-upload`,
              paramName: 'file[0]',
              previewTemplate: 'dz-template',
              previewsContainer: '#previews',
              clickable: true,
              createImageThumbnails: true,
              uploadMultiple: false,
              maxFiles: 1,
              acceptedFiles: 'image/jpeg, image/png, image/gif',
              maxFilesize, // mb
             }"
        @sending="uploadSending"
        @error="uploadError"
        @success="uploadSuccess"
        role="form"
        class="dropzone dz-clickable d-inline-block border-0 p-0"
        id="dropzone-profile-picture"
        ref="dz-profile-picture">
        <template v-slot:dz-message>
          <div class="dz-message m-0 text-left">
            <button class="btn btn-outline-primary mr-2" type="button" ref="btnUpload">Foto uploaden</button>
          </div>
        </template>
      </dropzone>

      <button
        class="btn btn-default md-btn-flat"
        type="button"
        @click="reset">
        Reset
      </button>

      <div class="text-light small mt-2">Toegelaten JPG, GIF of PNG. Max bestandsgrootte van {{ maxFilesize }}MB.</div>
    </div>
  </div>

  <!-- Dropzone preview template -->
  <template-list></template-list>
</template>

<script>
  import dropzone from '@/components/dropzone/Dropzone';
  import templateList from '@/components/dropzone/TemplateList';
  import svgPlaceholderImage from '@/assets/img/user_profile_picture.svg';
  import { root } from '@/utils/paths';

  export default {
    name: 'user-profile-picture',
    props: {
      folderId: {
        type: String,
        required: true,
      },
      userId: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: false,
        default: svgPlaceholderImage,
      },
    },
    computed: {},
    data() {
      return {
        maxFilesize: 25,
        root,
      };
    },
    components: {
      dropzone,
      templateList,
    },
    methods: {
      triggerUpload() {
        this.$refs.btnUpload.click();
      },

      // called just before each file is sent
      uploadSending(file, xhr, formData) {
        console.log('uploadSending');
        console.log(file, xhr, formData);

        formData.append('uploadFolderID', this.folderId);
        formData.append('userID', this.userId);
      },

      // call failed
      uploadError() {
        console.log('error');
      },

      // call succeeded
      uploadSuccess(file) {
        console.log('uploadSuccess');
        this.$refs.profilePicture.src = JSON.parse(file.xhr.response)[0].file.thumb;
        document.getElementById('js-app-profile-picture').src = JSON.parse(file.xhr.response)[0].file.thumb;
      },

      reset() {
        this.$refs.profilePicture.src = svgPlaceholderImage;
      },
    },
    created() {
      console.log('created');
    },
    mounted() {
      console.log('mounted');
      console.log(this.folderId);
    },
  };
</script>

<style scoped lang="scss">
  .profile-picture {
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.8;
    }
  }

  .dropzone {
    min-height: 0;
    background: white;
  }
</style>
