import { getRegex } from '@/api/common';
import { REGEX_ID } from '@/utils/regex';

export default {
  data() {
    return {
      modelFileName: null,
      fileNameRegex: /[^a-zA-Z0-9 _-]+$/,
      fileNameInvalidCharacter: null,
    };
  },
  computed: {
  },
  methods: {
    // Get regex from API
    async fetchRegex() {
      const formData = new FormData();
      formData.append('identifier', REGEX_ID.FILE_NAME);

      // fetch file name regex
      await getRegex(formData)
        // success
        .then((resp) => {
          this.fileNameRegex = resp.data.regex;
        })
        // fail
        .catch((error) => {
          console.error(error.response);
        });
    },

    // apply regex to modelFileName
    cleanFileName() {
      // exit, regex is empty
      if (!this.fileNameRegex) return;

      // warn about invalid character
      if (this.fileNameRegex.test(this.modelFileName)) {
        this.fileNameInvalidCharacter = this.modelFileName.slice(-1);
      }

      // apply regex
      this.modelFileName = this.modelFileName.replace(this.fileNameRegex, '');
    },
  },
  created() {
    // @todo: currently return an invalid regex
    // this.fetchRegex();
  },
};
