export const formGetters = {
  /**
   * return data of given form field id
   * @param state
   * @returns {Object}
   */
  getFormFieldDataById: (state) => (id) => state.formFieldData[id],

  /**
   * return uid of next matrix item
   * @param state
   * @returns {number}
   */
  getNextMatrixFieldUID: (state) => (id) => {
    // no items, set uid to 0
    if (state.matrixUploadFields[id].items.length < 1) return 0;

    // we have items
    return Math.max(...Object
      // get settings from each field inside form field
      .values(state.matrixUploadFields[id].items)
      // only keep its uid
      .map((field) => field.settings.uid))
      // add one to the highest uid (marking next uid)
      + 1;
  },
};

export default {
  formGetters,
};
