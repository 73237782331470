<template>
  <span class="font-weight-bold">{{ selected }}</span>
  <template v-if="maxFilesRange && maxFilesRange > 0">
    <span class="font-weight-bold">/{{ maxFilesRange }}</span>
  </template>
  &#32;bestanden geslecteerd.
  <template v-if="minFilesRange && selected < minFilesRange">
    <!-- Min is set -->
    <span class="text-danger">Minimaal nog {{ minFilesRange - selected }} te selecteren.</span>
  </template>

  <template v-if="maxFilesRange && selected > maxFilesRange">
    <!-- Reached max allowed files to select -->
    <span class="text-danger">Maximaal {{ maxFilesRange }} te selecteren.</span>
  </template>

  <template v-if="allowedExtensions?.length > 0">
    <!-- Allowed extensions -->
    Toegelaten extensies:&#32;
    <span class="font-weight-bold">{{
        allowedExtensions
          .toString()
          .replaceAll(',', ', ')
      }}</span>
  </template>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'file-upload-restriction',
    props: {
      minFiles: {
        type: Number,
        required: false,
      },
      maxFiles: {
        type: Number,
        required: false,
      },
      allowedExtensions: {
        type: Array,
        required: false,
      },
      customSelected: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        isRemoving: false,
      };
    },
    computed: {
      minFilesRange() {
        if (this.minFiles && this.maxFiles) return Math.min(this.minFiles, this.maxFiles);
        return this.minFiles;
      },
      maxFilesRange() {
        if (this.minFiles && this.maxFiles) return Math.max(this.minFiles, this.maxFiles);
        return this.maxFiles;
      },
      selected() {
        // Custom selected is set, use it as selected value
        if (this.customSelected) return this.customSelected;
        // Use selectedFilesLength
        return this.selectedFilesLength;
      },
      ...mapGetters({
        selectedFilesLength: 'datastore/selectedFilesLength',
      }),
    },
  };
</script>
