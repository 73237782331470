import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';

export default class dragDropComponent {
  /**
   * set an item on of off
   * @param componentEl {Element}
   */
  constructor(componentEl) {
    this.dom = {
      componentEl,
      engine: document.getElementById('engine'),
      dragItems: componentEl.querySelectorAll('.draggable'),
      dropItems: componentEl.querySelectorAll('.droppable'),
      lightOn: null,
    };
    this.data = {
      xhrUrl: null,
      formData: null,
    };
    this.events = {
      dragStart: this.onDragStart.bind(this),
      dragEnd: this.onDragEnd.bind(this),
      dragOver: this.onDragOver.bind(this),
      dragLeave: this.onDragLeave.bind(this),
      drop: this.onDrop.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    // set xhr url
    this.data.xhrUrl = this.dom.componentEl.dataset.xhr;

    // add drag instances
    this.dom.dragItems.forEach((draggable) => {
      draggable.addEventListener('dragstart', this.events.dragStart);
      draggable.addEventListener('dragend', this.events.dragEnd);
    });

    // add drop instances
    this.dom.dropItems.forEach((droppable) => {
      droppable.addEventListener('dragover', this.events.dragOver);
      droppable.addEventListener('dragleave', this.events.dragLeave);
      droppable.addEventListener('drop', this.events.drop);
    });
  }

  // remove event listener
  unmount() {
    // unset drag instances
    this.dom.dragItems.forEach((draggable) => {
      draggable.removeEventListener('dragstart', this.events.dragStart);
      draggable.removeEventListener('dragEnd', this.events.dragStart);
    });

    // unset drop instances
    this.dom.dropItems.forEach((droppable) => {
      droppable.removeEventListener('dragover', this.events.dragOver);
      droppable.removeEventListener('dragLeave', this.events.dragLeave);
      droppable.removeEventListener('drop', this.events.drop);
    });
  }

  /**
   * drag start
   *
   * @param e {MouseEvent}
   */
  // eslint-disable-next-line class-methods-use-this
  onDragStart(e) {
    e.target.classList.add('dragging');
  }

  /**
   * drag end
   *
   * @param e {MouseEvent}
   */
  // eslint-disable-next-line class-methods-use-this
  onDragEnd(e) {
    e.target.classList.remove('dragging');
  }

  /**
   * drag over
   *
   * @param e {MouseEvent}
   */
  onDragOver(e) {
    e.preventDefault();
    this.dom.lightOn = e.target.closest('.card');
    this.dom.lightOn.classList.add('drag-over');
  }

  /**
   * drag over
   *
   * @param e {MouseEvent}
   */
  // eslint-disable-next-line class-methods-use-this
  onDragLeave(e) {
    e.preventDefault();
    const lightOff = e.target.closest('.card');
    lightOff.classList.remove('drag-over');
  }

  onDrop(e) {
    const dropId = e.currentTarget.dataset.dropid;
    const dragEl = this.dom.componentEl.querySelector('.dragging');
    const dropEl = this.dom.componentEl.querySelector(`[data-dropid="${dropId}"]`);
    if (dropEl) {
      dropEl.appendChild(dragEl);
    }
    console.log(dropEl);
    dragEl.classList.remove('dragging');

    const lightOff = e.currentTarget.closest('.card');
    lightOff.classList.remove('drag-over');

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('dropID', dropId);
    this.data.formData.append('dragID', dragEl.dataset.dragid);

    // make axios call
    axios({
      method: 'post',
      url: root + this.data.xhrUrl,
      data: this.data.formData,
    })
      .then((response) => {
        if (!response.data.success) {
          this.showError(response.data.errorMessage);
        }
      })
      .catch(() => {
        getCritical();
      });
  }

  /**
   * throw error
   *
   * @param errorMessage {String}
   */
  showError(errorMessage) {
    const notificationError = new CustomEvent('notification', {
      detail: {
        title: `${errorMessage}`,
        type: 'danger',
      },
    });
    this.dom.engine.dispatchEvent(notificationError);
  }
}
