import { getTranslation } from '@/utils/languages';
import { getSuccessNotification, getErrorNotification } from '@/views/engine/utils/notifications';
import { getActionSettings, executeAction } from '@/api/engine/grid';

export default class GridActionBulkButton {
  /**
   * delete an item
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.events = {
      handleBulkAction: this.handleBulkAction.bind(this),
    };
    this.props = {
      ...{
        selectedKeys: [],
      },
      ...payload.props,
    };
    this.data = {
      formData: null,
    };
    this.customEvents = {
      notificationSuccess: null,
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.handleBulkAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.handleBulkAction);
  }

  /**
   * bulk button action
   *
   * @param e {MouseEvent}
   */
  async handleBulkAction(e) {
    // prevent default click
    e.preventDefault();

    // get all checked keys
    const bulkActionKeys = [];
    let sBulkActionKeys = '';
    [...document.querySelectorAll('.bulkaction')].forEach((el) => {
      if (el.checked) bulkActionKeys.push(el.dataset.pk);
    });

    // in case of error
    if (bulkActionKeys.length < 1) {
      this.customEvents.notificationError = getErrorNotification({
        title: getTranslation('NoneSelected'),
        type: 'danger',
      });
      this.dom.engine.dispatchEvent(this.customEvents.notificationError);
      return;
    }

    // save for further use
    this.props.selectedKeys = bulkActionKeys;

    // convert to string
    sBulkActionKeys = bulkActionKeys.toString();

    // needed for end call
    this.data.formData = new FormData();
    this.data.formData.append('gridID', this.props.gridId);

    // pass the necessary data
    const formData = new FormData();
    formData.append('actionID', e.target.dataset.actionid);

    // load action settings
    await getActionSettings(formData)
      .then((response) => {
        const exeData = new FormData();
        // send selected keys
        exeData.append('keys', sBulkActionKeys);
        // add all keys returned by the getActionSettings call
        Object.keys(response.data).forEach((n) => {
          exeData.append(n, response.data[n]);
        });
        // send method if set
        if (e.target.dataset.method) exeData.append('method', e.target.dataset.method);
        // send pk if set
        if (e.target.dataset.pk) {
          this.data.pk = e.target.dataset.pk;
          exeData.append('pk', e.target.dataset.pk);
        }
        // send fk if set
        if (e.target.dataset.fk) {
          this.data.fk = e.target.dataset.fk;
          exeData.append('fk', e.target.dataset.fk);
        }

        // execute the action
        executeAction(exeData)
          .then(() => {
            // set custom options per method
            this.setCustomOptionsBulkMethod(e.target.dataset.method);
            // report success
            this.dom.engine.dispatchEvent(getSuccessNotification());
          })
          .catch((error) => {
            console.log(error);
            this.dom.engine.dispatchEvent(getErrorNotification());
          });
      })
      .catch((error) => {
        console.log(error);
        this.dom.engine.dispatchEvent(getErrorNotification());
      });
  }

  /**
   * set custom options for a method
   *
   * @param method {String}
   */
  async setCustomOptionsBulkMethod(method) {
    // check current class
    switch (method) {
      case 'delete-selection':
        // get the selection
        // loop all keys
        this.props.selectedKeys.forEach((idx) => {
          const row = document.getElementById(idx);
          row.remove();
        });
        break;
      default:
        console.log(`${method} has no related customOptions in hrefAction class`);
        // by default no options
        break;
    }
  }
}
