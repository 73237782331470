import axios from 'axios';
import { root } from '@/utils/paths';
import { upperFirst } from 'lodash-es';
import { getTranslation } from '@/utils/languages';
import { getErrorNotification, getSuccessNotification } from '@/views/engine/utils/notifications';
import Alert from '@/app/alert';
import { getActionRelatedGridInfo } from '@/api/engine/grid';
import GridAction from '../gridAction';

export default class GridActionDelete {
  /**
   * delete an item
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
    };
    this.props = {
      ...payload.props,
    };
    this.data = {
      formData: null,
      pk: action.dataset.pk,
      fk: null,
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };
    this.events = {
      deleteItem: this.deleteItem.bind(this),
    };
    this.customEvents = {
      notificationSuccess: null,
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.deleteItem);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.deleteItem);
  }

  /**
   *
   * @param e {MouseEvent}
   */
  async deleteItem(e) {
    // prevent default handle
    e.preventDefault();

    // needed for end call
    this.data.formData = new FormData();
    this.data.formData.append('gridID', this.props.gridId);

    const settingsData = new FormData();
    settingsData.append('actionID', e.target.dataset.actionid);

    if (e.target.dataset.pk) {
      this.data.pk = e.target.dataset.pk;
      settingsData.append('pk', e.target.dataset.pk);
    }
    if (e.target.dataset.fk) {
      this.data.fk = e.target.dataset.fk;
      settingsData.append('fk', e.target.dataset.fk);
    }

    // eslint-disable-next-line no-unused-vars
    const settings = await getActionRelatedGridInfo(settingsData)
      .then((response) => {
        this.data.pk = e.target.dataset.pk;
        this.data.tablename = response.data.tableName;
      })
      .catch(() => this.dom.engine.dispatchEvent(this.customEvents.notificationError));

    const confirmAlert = new Alert(
      {
        text: getTranslation('are you sure to delete'),
        title: upperFirst(getTranslation('attention')),
        icon: 'question',
        showConfirmButton: true,
        confirmButtonText: getTranslation('yes'),
        showCancelButton: true,
        cancelButtonText: getTranslation('no'),
        customClass: {
          confirmButton: 'btn-sm btn-primary',
          cancelButton: 'btn-sm btn-danger ml-2',
          title: 'text-primary',
          icon: 'text-primary',
        },
      },
      (result) => {
        if (result.isConfirmed) {
          // pass the necessary data
          this.data.formData = new FormData();
          this.data.formData.append('pk', this.data.pk);
          this.data.formData.append('table', this.data.tablename);

          // make axios call
          axios({
            method: 'post',
            url: `${root}common/delete-item`,
            data: this.data.formData,
          })
            .then((response) => {
              console.log(response);
              if (response.data.successMessage) {
                // delete row
                this.data.formData = new FormData();
                this.data.formData.append('gridID', this.props.gridId);
                this.data.formData.append('gotoTab', this.props.activeTab);
                if (this.data.fk) this.data.formData.append('pk', this.data.fk); // pass fk as pk
                this.components.filterAction.applyFilter(this.data.formData);

                // dispatch a message
                if (response.data.success) {
                  this.customEvents.notificationSuccess = getSuccessNotification({ title: response.data.successMessage });
                  this.dom.engine.dispatchEvent(this.customEvents.notificationSuccess);
                } else {
                  this.dom.engine.dispatchEvent(getErrorNotification({ title: response.data.errorMessage }));
                }
              } else {
                // send error notification
                let errorMessage = getTranslation('contact your webpartner');
                if (response.data.errorMessage) errorMessage = response.data.errorMessage;
                const notification = { title: errorMessage, type: 'danger' };
                this.customEvents.notificationError = getErrorNotification(notification);
                this.dom.engine.dispatchEvent(this.customEvents.notificationError);
              }
            })
            .catch(() => this.dom.engine.dispatchEvent(this.customEvents.notificationError));
        }
      },
    );
    confirmAlert.show();
  }
}
