import { debounce } from 'lodash-es';
import { getFilterRequestData } from '@/views/engine/grid/gridUtils';
import RandomAction from '@/views/engine/randomcomponent/randomAction';

export default class RandomSearch {
  /**
   * constructor for the GridSearch class
   * @param payload {Object}
   */
  constructor(payload) {
    this.dom = {
      ...{
        inputSearch: payload.dom.gridSearchFormEl.querySelector('.search-input'),
        cancel: payload.dom.gridSearchFormEl.querySelector('.clear-search-input'),
      },
      ...payload.dom,
    };
    this.props = {
      ...{ debounceDelay: 500 },
      ...payload.props,
    };
    this.data = {
      ...{
        formData: null,
      },
      ...payload.data,
    };
    this.components = {
      filterAction: new RandomAction(this.props.randomId, this.props.ajaxUrl),
    };
    this.events = {
      debouncedSearch: debounce(this.onInputSearch.bind(this), this.props.debounceDelay),
      onClearSearch: this.onClearSearch.bind(this),
    };

    this.mount();
  }

  mount() {
    console.log('mount random search');

    this.dom.inputSearch.addEventListener('input', this.events.debouncedSearch);
    this.dom.cancel.addEventListener('click', this.events.onClearSearch);
  }

  unmount() {
    this.dom.inputSearch.removeEventListener('input', this.events.debouncedSearch);
    this.dom.cancel.removeEventListener('click', this.events.onClearSearch);
    this.components = null;
  }

  /**
   * function to filter a grid on change of the search input
   */
  async onInputSearch() {
    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('randomID', this.props.randomId);
    this.data.formData.append('refine', true);
    this.data.formData.append('filters', getFilterRequestData(this.dom.gridSearchFormEl));

    // apply the filter
    await this.components.filterAction.applyFilter(this.data.formData);

    // umount
    this.unmount();
  }

  // function to clear the search filter for a grid
  onClearSearch() {
    this.dom.inputSearch.value = '';
    this.onInputSearch();
  }
}
