<template>
  <!--  @todo Disablen indien selected files fileSize is > 50mb? && minder dan 20 files? -->
  <button
    :disabled="disableDownload"
    @click="downloadFiles"
    class="dropdown-item d-flex align-items-center"
    type="button">
    Download
    <span v-show="disableDownload" class="d-inline-block pl-3 ml-auto flex-shrink-0">
      <timer-pie
        :size="16"
        :time="downloadCooldown"
        @complete="downloadCooldownComplete"
        ref="downloadCooldown">
      </timer-pie>
    </span>
  </button>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  // utils
  import { downloadFile } from '@/utils/files';

  // components
  import timerPie from '@/components/timer/Pie';

  export default {
    name: 'datastore-btn-download-files',
    components: {
      timerPie,
    },
    data() {
      return {
        disableDownload: false,
        downloadCooldown: 5, // in seconds
      };
    },
    computed: {
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
      ...mapState({
        files: (state) => state.datastore.files,
      }),
      ...mapGetters({
        selectedFilesLength: 'datastore/selectedFilesLength',
        selectedFileIds: 'datastore/getSelectedFileIds',
      }),
    },
    methods: {
      // download selected file(s)
      downloadFiles() {
        // no file(s) selected
        if (this.selectedFiles < 1) {
          return;
        }

        if (this.selectedFiles.length === 1) {
          // trigger single file download
          this.selectedFiles.forEach((file) => {
            // try to download file
            downloadFile(file.sourceUrl, file.fileName);
            this.removeSelection();
          });
        } else {
          // disable to download file by selection
          this.disableDownload = true;

          // request zip for multi-file download
          this.$store.dispatch('datastore/downloadFiles', {
            fileIds: this.selectedFileIds,
            cb: this.setDownloadCooldown, // cb is optional
          });
        }
      },

      setDownloadCooldown() {
        this.removeSelection();
        this.$refs.downloadCooldown.play();
        // start cooldown
        setTimeout(() => this.downloadCooldownComplete, this.downloadCooldown);
      },

      // enable to download file by selection
      downloadCooldownComplete() {
        this.disableDownload = false;
        this.$refs.downloadCooldown.reset();
      },

      // deselect all files
      removeSelection() {
        this.selectedFiles = [];
      },
    },
    watch: {},
  };
</script>
