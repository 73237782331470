/**
 * Return pre-defined row structure
 * @param file {object}
 * @param args {object}
 * @param name {string}
 * @returns {array}
 */
export default function row(file, args = {}, name = '') {
  let arrRow = [];

  switch (name) {
    case 'modal':
      arrRow = [
        // merge thumb and filename td
        file.fileUID,
        {
          thumb: file.thumb,
          fileName: file.noExtensionName,
          extension: file.extension,
          translations: file.translations,
          fileId: file.fileUID,
        },
        `${file.size} ${args.sizeUnit}`,
        file.dateAdd,
        file.sourceUrl,
      ];
      break;
    default:
      arrRow = [
        // merge thumb and filename td
        file.fileUID,
        {
          thumb: file.thumb,
          fileName: file.noExtensionName,
          extension: file.extension,
          translations: file.translations,
          fileId: file.fileUID,
        },
        `${file.width}${args.widthUnit}`,
        `${file.height}${args.heightUnit}`,
        `${file.size}${args.sizeUnit}`,
        file.dateAdd,
        file.sourceUrl,
      ];
      break;
  }

  return arrRow;
}
