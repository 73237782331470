import { html } from 'gridjs';
import { root } from '@/utils/paths';

/**
 * Return pre-defined column structure
 * @param args {object}
 * @param name {string}
 * @returns {array}
 */
export default function column(args = {}, name = '') {
  let arrColumn = [];

  switch (name) {
    case 'modal':
      arrColumn = [
        {
          id: 'check',
          name: html(
            `<label class="custom-control custom-checkbox p-0 m-0">
                      <input type="checkbox" class="custom-control-input gridjs-check-columns">'
                      <span class="custom-control-label">
                        <span class="sr-only">Selecteer alles</span>
                      </span>
                    </label>`,
          ),
          formatter: (cell) => html(
            `<label class="custom-control custom-checkbox p-0 m-0">
                        <input type="checkbox" class="custom-control-input gridjs-check-row" value="${cell}">
                        <span class="custom-control-label"> </span>
                      </label>`,
          ),
          sort: true,
          search: false,
        },
        {
          id: 'filename',
          name: 'Bestandsnaam',
          width: '100%',
          formatter: (cell) => html(
            `<div class="td-wrapper d-flex align-items-center">
                        <div class="d-block thumb-wrapper mr-4">
                          ${args.setThumb(cell.thumb, cell.extension, cell.fileName)}
                        </div>
                        ${cell.fileName}
                      </div>`,
          ),
          sort: {
            compare: (a, b) => {
              if (a.fileName > b.fileName) {
                return 1;
              }
              if (b.fileName > a.fileName) {
                return -1;
              }
              return 0;
            },
          },
        },
        {
          id: 'size',
          name: html(
            '<div>Grootte</div>',
          ),
          sort: {
            compare: (a, b) => {
              const newValue = Number(a.replace(args.sizeUnit, ''));
              const oldValue = Number(b.replace(args.sizeUnit, ''));

              if (newValue > oldValue) {
                return 1;
              }
              if (oldValue > newValue) {
                return -1;
              }
              return 0;
            },
          },
          search: false,
        },
        {
          id: 'uploaddate',
          name: 'Uploaddatum',
          search: false,
        },
        {
          id: 'actions',
          name: html('<div class="sr-only">Acties</div>'),
          formatter: (cell) => args.addRemoveButton(html(
            `
                      <a class="d-flex align-center" href="${cell}" target="_blank" rel="noreferrer noopener">
                        <i class="far fa-eye text-16 action-color" title="Open in nieuwe tab"></i>
                      </a>`,
          )),
          sort: true,
          search: false,
        },
      ];
      break;
    default:
      arrColumn = [
        {
          id: 'check',
          name: html(
            `<label class="custom-control custom-checkbox p-0 m-0">
                      <input type="checkbox" class="custom-control-input gridjs-check-columns">'
                      <span class="custom-control-label">
                        <span class="sr-only">Selecteer alles</span>
                      </span>
                    </label>`,
          ),
          formatter: (cell) => html(
            `<label class="custom-control custom-checkbox p-0 m-0">
                        <input type="checkbox" class="custom-control-input gridjs-check-row" value="${cell}">
                        <span class="custom-control-label"> </span>
                      </label>`,
          ),
          sort: true,
          search: false,
        },
        {
          id: 'filename',
          name: 'Bestandsnaam',
          width: '100%',
          formatter: (cell) => html(
            `<div class="td-wrapper d-flex align-items-center cursor-pointer edit-tooltip-area child-pointer-events-none">
                        <div class="d-block thumb-wrapper mr-4">
                          ${args.setThumb(cell.thumb, cell.extension, cell.fileName)}
                        </div>
                        ${cell.fileName}
                      </div>`,
          ),
          sort: {
            compare: (a, b) => {
              if (a.fileName > b.fileName) {
                return 1;
              }
              if (b.fileName > a.fileName) {
                return -1;
              }
              return 0;
            },
          },
        },
        {
          id: 'width',
          width: '250',
          name: html(
            '<div>Breedte</div>',
          ),
          sort: {
            compare: (a, b) => {
              const newValue = Number(a.replace(args.widthUnit, ''));
              const oldValue = Number(b.replace(args.widthUnit, ''));

              if (newValue > oldValue) {
                return 1;
              }
              if (oldValue > newValue) {
                return -1;
              }
              return 0;
            },
          },
          search: false,
        },
        {
          id: 'height',
          width: '250',
          name: html(
            '<div>Hoogte</div>',
          ),
          sort: {
            compare: (a, b) => {
              const newValue = Number(a.replace(args.heightUnit, ''));
              const oldValue = Number(b.replace(args.heightUnit, ''));

              if (newValue > oldValue) {
                return 1;
              }
              if (oldValue > newValue) {
                return -1;
              }
              return 0;
            },
          },
          search: false,
        },
        {
          id: 'size',
          width: '250',
          name: html(
            '<div>Grootte</div>',
          ),
          sort: {
            compare: (a, b) => {
              const newValue = Number(a.replace(args.sizeUnit, ''));
              const oldValue = Number(b.replace(args.sizeUnit, ''));

              if (newValue > oldValue) {
                return 1;
              }
              if (oldValue > newValue) {
                return -1;
              }
              return 0;
            },
          },
          search: false,
        },
        {
          id: 'uploaddate',
          name: 'Uploaddatum',
          search: false,
          width: '250',
        },
        {
          id: 'actions',
          name: html('<div class="sr-only">Acties</div>'),
          formatter: (cell, row) => args.addRemoveButton(html(
            `
                      <div class="d-flex align-center">
                        <a class="d-flex align-center mr-1 ml-n1 px-1" href="${cell}" target="_blank" rel="noreferrer noopener">
                          <i class="far fa-eye text-16 action-color" title="Open in nieuwe tab"></i>
                        </a>
                        <a class="d-flex align-center mr-1 px-1" href="${`${root}datastore/file/fileID-${row.cells[0].data}`}">
                          <i class="far fa-edit text-16 action-color" title="Bewerken"></i>
                        </a>
                        <button
                          class="btn-reset d-flex align-center px-1 mr-n1"
                          data-ref="btn-remove"
                          data-id="${row.cells[0].data}"
                          data-name="${row.cells[1].data.fileName}">
                          <i class="far fa-trash-alt text-16 text-danger pointer-events-none" title="Verwijder"></i>
                        </button>
                      </div>`,
          )),
          sort: true,
          search: false,
        },
      ];
      break;
  }

  return arrColumn;
}
