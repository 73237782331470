import upperFirst from 'lodash-es/upperFirst';
import camelCase from 'lodash-es/camelCase';

const components = {};

// Automatically register all Vue components located within the /src/components folder.
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Wether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
);

/**
 * Return component name
 * @param propName
 * @param fileName
 * @returns { String }
 */
function getComponentName(propName, fileName) {
  if (propName) {
    // Return vue name property
    return propName;
  }

  // Return PascalCase name of component based on filename
  return upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  );
}

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = getComponentName(componentConfig.default.name, fileName);

  components[componentName] = componentConfig.default || componentConfig;
  // Register component globally
  /* App.component(
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
  ); */
  components[componentName] = componentConfig.default || componentConfig;
});

export default components;
