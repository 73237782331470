import $ from 'jquery';
import TinyMce from '@/views/engine/components/tinymce';
import { copyFieldValueAndSlugify, makeTagfield, revealPassword } from '@/utils/fields';
import { root } from '@/utils/paths';
import checkAllMulti from './actions/checkAllMultiSelect';
import FormActionPost from './actions/post';
import FormActionSwitch from './actions/switch';
import reloadGrid from './actions/reloadGrid';
import goToWizardStep from './actions/goToWizardStep';
import FormAddElement from './actions/addElementToForm';
import RevealItem from './actions/revealItem';
import FormCategoryManager from './fieldTypes/categoryManager';
import ManageFormFieldTypes from './fieldTypes/formFieldTypesManager';
import MasterSlaveField from './fieldTypes/masterSlaveField';

// utils
export default class Form {
  /**
   * Constructor for the Form class
   * @param formEl {Element}
   */
  constructor(formEl) {
    this.dom = {
      formEl,
      engine: document.getElementById('engine'),
      tinyMces: [...formEl.querySelectorAll('.tiny-mce')],
      addElementButtons: formEl.querySelectorAll('.js-add-element'),
      slugFields: formEl.querySelectorAll('.js-slug'),
      tagFields: formEl.querySelectorAll('.js-tagfield'),
      categoryManagerField: formEl.querySelector('#js-add-category-txt'),
      formFieldTypeManagerField: document.getElementById('select-FormFieldTypeID'),
      masterSlaveFields: [...formEl.querySelectorAll('.js-master-slave')],
      checkAllFields: [...formEl.querySelectorAll('.js-checkall-multi')],
      revealElements: [...formEl.querySelectorAll('.js-reveal')],
      $modalForm: $('#modal-form'),
    };

    this.props = {
      formId: formEl.dataset.formid,
      widgetForm: formEl.dataset.widgetform,
      wizardStep: formEl.dataset?.wizardstep,
    };

    this.data = {
      formData: null,
      currentStep: null,
      nextStep: null,
      redirectUrl: null,
    };

    this.events = {
      onFieldChange: this.onFieldChange.bind(this),
      onSubmitFinally: this.onSubmitFinally.bind(this),
    };

    this.components = {
      post: null,
      tinyMces: [],
      masterSlaves: [],
      categoryManager: null,
      formFieldTypeManager: null,
      reveals: [],
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    // post component
    this.components.post = new FormActionPost(this.dom.formEl, {
      tinyMces: this.components.tinyMces,
    });

    // switch component
    this.components.switch = new FormActionSwitch(this.dom.formEl);

    // tinymce fields
    this.dom.tinyMces.forEach((tinyEl, index) => {
      this.components.tinyMces.push(new TinyMce(tinyEl));
      this.components.tinyMces[index].init();
    });

    // master slave fields
    if (this.dom.masterSlaveFields) {
      this.dom.masterSlaveFields.forEach((msEl) => {
        this.components.masterSlaves.push(new MasterSlaveField(msEl, {
          props: this.props,
          dom: this.dom,
        }));
      });
    }

    // category manager component
    if (this.dom.categoryManagerField) {
      this.components.categoryManager = new FormCategoryManager(this.dom.formEl);
    }

    // formFieldTypeManager manager component
    if (this.dom.formFieldTypeManagerField) {
      this.components.formFieldTypeManager = new ManageFormFieldTypes(this.dom.formFieldTypeManagerField);
    }

    // dispatched events
    this.dom.formEl.addEventListener('onSubmitFinally', this.events.onSubmitFinally);

    // add element button instances
    this.dom.addElementButtons.forEach((addBtn) => {
      const addBtnComponent = new FormAddElement(addBtn, {
        dom: {
          formEl: this.dom.formEl,
        },
        props: this.props,
      });
      this.components.addElementButtons.push(addBtnComponent);
    });

    // create slug based on name
    this.dom.slugFields.forEach((field) => {
      // const src = field.dataset.src;
      copyFieldValueAndSlugify(field);
    });

    // create password revealer
    if (this.dom.revealElements) {
      this.dom.revealElements.forEach((el) => {
        const id = el.closest('[data-formfieldholderid]').dataset.formfieldholderid;
        const reveal = new RevealItem(el, id);
        this.components.reveals.push(reveal);
      });
    }

    // create tagFields based on class
    this.dom.tagFields.forEach((htmlEl) => {
      const src = document.getElementById(htmlEl.getAttribute('id'));
      if (src) makeTagfield(src);
    });

    // multi checkbox
    checkAllMulti();
  }

  /**
   * unbind & destroy
   */
  unmount(nullifyComponents = true) {
    console.log('unmount form...');
    this.components.post.unmount();
    this.components.switch.unmount();

    this.dom.formEl.removeEventListener('onSubmitFinally', this.events.onSubmitFinally);

    // remove tinymce instances
    this.components.tinyMces.forEach((tinyInst) => {
      tinyInst.destroy();
    });

    // master slave fields
    if (this.dom.masterSlaveFields) {
      this.components.masterSlaves.forEach((msEl) => {
        msEl.unmount();
      });
    }

    // unmount category manager if available
    if (this.components.categoryManager) {
      this.components.categoryManager.unmount();
    }

    // unmount formFieldTypeManager manager component
    if (this.components.formFieldTypeManager) {
      this.components.formFieldTypeManager.unmount();
    }

    // unmount reveal instances
    if (this.dom.revealElements) {
      this.components.reveals.forEach((revealInst) => {
        revealInst.unmount();
      });
    }

    if (nullifyComponents) {
      this.components = null;
    }
  }

  // field has changed
  onFieldChange() {
    this.dom.formEl.dataset.hasPendingChanges = true;
  }

  /**
   * manage the callback after post (reloading stuff)
   * @param e {CustomEvent}
   */
  onSubmitFinally(e) {
    console.log('onSubmitFinally');

    // init
    let addDetail = true;

    // set submitter
    if (e.detail.submitter) {
      this.data.redirectUrl = e.detail.submitter.dataset.ref;
      if (this.data.redirectUrl) {
        if (this.data.redirectUrl.includes('/add/')) addDetail = false;
      }
      // if (this?.data?.redirectUrl.includes('/add/')) addDetail = false;
    }
    console.log(this.data);

    /* exec init tiny mce */
    this.unmount(false);
    this.mount();

    // redirect to previous location if requested
    if (this.data.redirectUrl) {
      if (addDetail) {
        window.location = `${this.data.redirectUrl}/viewID-${e.detail.viewID}`;
      } else {
        window.location = `${this.data.redirectUrl}`;
      }
    }

    /* this continuation only goes if first action is success */
    switch (e.detail.reloadMode) {
      // a grid needs to be reloaded
      case 'grid':
        // only reload if url is available
        // none critical error (just end here)
        if (e.detail.callbackUrl) {
          reloadGrid(
            root + e.detail.callbackUrl,
            {
              gridID: e.detail.refGridId,
              pk: e.detail.pk,
            },
          );
        } else {
          console.log('grid has reloadMode but no Url is set');
        }
        break;
      // the post is part of a wizard step form
      case 'wizard':
        console.log('wizard reload...');
        goToWizardStep(this.props.formId, e.detail.wizardStep, (e.detail.wizardStep + 1));
        break;
      // default handling
      default:
        // no further actions
        break;
    }
  }
}
