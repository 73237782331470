import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';

export default class GridAction {
  /**
   * Constructor for the GridFilter class
   *
   * @param gridId {String}
   * @param url {String}
   */
  constructor(gridId, url) {
    this.dom = {
      grid: document.getElementById(`js-replace-autogrid-${gridId}`),
      paginator: document.getElementById(`js-pagination-${gridId}`),
      amountOfRecords: document.getElementById(`js-pagination-rows-${gridId}`),
      debugReport: document.getElementById('debugReport'),
      engine: document.getElementById('engine'),
    };
    this.customEvents = {
      gridBeforeReplace: new CustomEvent('gridBeforeReplace', { detail: { gridId } }),
      gridAfterReplace: new CustomEvent('gridAfterReplace', { detail: { gridId } }),
      errorNotification: getErrorNotification(),
    };
    this.gridId = gridId;
    this.url = url;
  }

  /**
   * @param data {FormData}
   */
  applyFilter(data) {
    // make axios call
    return axios({
      method: 'post',
      url: root + this.url,
      data,
    })
      .then((response) => {
        // success
        if (response.data.success) {
          // dispatch the reload event
          this.dom.engine.dispatchEvent(this.customEvents.gridBeforeReplace);

          // set table content
          this.dom.grid.innerHTML = response.data.overview;

          // paginator
          if (this.dom.paginator) {
            this.dom.paginator.innerHTML = response.data.paginator;
          }

          // pagination rows
          if (this.dom.amountOfRecords) {
            this.dom.amountOfRecords.innerHTML = response.data.totalRows;
          }

          // some useful debug info
          if (this.dom.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }

          // after replace actions (rebind events)
          this.dom.engine.dispatchEvent(this.customEvents.gridAfterReplace);
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);

        // dispatch errorNotification event
        this.dom.engine.dispatchEvent(this.customEvents.errorNotification);
      });
  }
}
