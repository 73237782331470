<template>
  <svg class="timer-pie" :viewbox="`0 0 ${size} ${size}`" :width="size" :height="size">
    <circle ref="circle" :stroke-width="newStrokeWidth" :fill="circleFill" :stroke="circleColor" :cx="center" :cy="center" :r="radius"></circle>
    <path ref="track" :stroke-width="newStrokeWidth" :fill="progressFill" :stroke="progressStrokeColor" :d="d"></path>
  </svg>
</template>

<script>
  import gsap from 'gsap';
  import { degreesToRadians } from '@/utils/math';

  export default {
    name: 'timer-pie',
    emits: ['complete'],
    props: {
      size: {
        type: Number,
        default: 24,
        required: true,
      },
      onlyBorder: {
        type: Boolean,
        default: false,
        required: false,
      },
      // only applied if onlyBorder is set to true
      fillCircle: {
        type: Boolean,
        default: true,
        required: false,
      },
      toAngle: {
        type: Number,
        default: 360,
        required: false,
      },
      strokeWidth: {
        type: Number,
        default: 5,
        required: false,
      },
      progressColor: {
        type: String,
        required: false,
        default: '#dbe2eb',
      },
      circleColor: {
        type: String,
        required: false,
        default: 'none',
      },
      time: {
        type: Number,
        required: false,
        default: 5, // in s
      },
    },
    computed: {
      newStrokeWidth() {
        return this.onlyBorder ? this.strokeWidth : 0;
      },
      center() {
        return this.size * 0.5;
      },
      radius() {
        return this.newStrokeWidth ? (this.center - this.newStrokeWidth * 0.5) : this.center;
      },
      circleFill() {
        return this.fillCircle || !this.onlyBorder ? this.circleColor : 'none';
      },
      progressFill() {
        return this.onlyBorder ? 'none' : this.progressColor;
      },
      progressStrokeColor() {
        return this.onlyBorder ? this.progressColor : 'none';
      },
    },
    data() {
      return {
        angle: this.toAngle,
        d: '',
      };
    },
    methods: {
      // play timer
      play() {
        if (this.timeline) {
          this.timeline.play();
        }
      },

      // pause timer
      pause() {
        if (this.timeline) {
          this.timeline.pause();
        }
      },

      // reset timer
      reset() {
        if (this.timeline) {
          this.timeline.pause();
          this.timeline.progress(0);
          this.angle = 0;
          this.setProgress();
        }
      },

      // timer has completed
      complete() {
        this.$emit('complete');
      },

      // make sure angle is within 360 bounds
      checkAngle() {
        if (this.angle > 360) {
          this.angle %= 360;
        }
      },

      // add animation
      addAnimation() {
        const oAngle = { angle: 0 };
        this.timeline.to(oAngle, {
          duration: this.time,
          roundProps: 'val',
          angle: this.angle,
          callbackScope: this,
          onUpdate() {
            this.angle = oAngle.angle;
            this.setProgress();
          },
          onComplete() {
            this.complete();
          },
        });
      },

      // update angle
      changeAngle(angle) {
        this.angle = angle;
        this.checkAngle();
        this.$refs.track.setAttribute('d', this.setProgress());
      },

      // get progress pos
      getProgress(angle) {
        const x = this.center + this.radius * Math.cos(degreesToRadians(angle));
        const y = this.center + this.radius * Math.sin(degreesToRadians(angle));

        return `A${this.radius},${this.radius} 1 0 1 ${x},${y}`;
      },

      // set progress path
      setProgress() {
        // set progress angles
        const firstAngle = this.angle > 180 ? 90 : this.angle - 90;
        const secondAngle = -270 + this.angle - 180;

        // progress
        const firstArc = this.getProgress(firstAngle);
        const secondArc = this.angle > 180 ? this.getProgress(secondAngle) : '';

        // start -> starting line
        // end -> will path be closed or not
        let end = '';
        let start = null;

        if (this.onlyBorder) {
          start = `M${this.center},${this.newStrokeWidth * 0.5}`;
        } else {
          start = `M${this.center},${this.center} L${this.center},${this.newStrokeWidth * 0.5}`;
          end = 'z';
        }

        // set path
        this.d = `${start} ${firstArc} ${secondArc} ${end}`;
      },
    },
    created() {
      this.timeline = null;
      this.checkAngle();
    },
    mounted() {
      this.timeline = gsap.timeline({ paused: true });
      this.getProgress();
      this.addAnimation();
    },
  };
</script>
