<template>
  <modal :isModalDialogCentered="true"
         ref="fileDeleteModal"
         @hidden="fileDeleteModalHidden">
    <template v-slot:header>
      <div class="modal-header justify-content-center text-center pb-4">
        <h5 class="modal-title mt-1">
          <span v-if="fileDeleteModal?.fileName">Bestand&#32;'{{ fileDeleteModal.fileName }}'</span>
          <span v-else-if="fileDeleteModal?.fileIds.length > 1">{{ fileDeleteModal.fileIds.length }}&#32;bestanden</span>
          <span v-else>Bestand</span>
          &#32;verwijderen?
          <small class="d-block text-muted">Actie kan niet ongedaan gemaakt worden.</small>
        </h5>
        <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body d-flex justify-content-center pt-4">
        <button type="button" class="btn btn-link text-dark px-0 mr-4 box-shadow-none" data-dismiss="modal">Annuleer</button>
        <button type="button" class="btn btn-danger btn-confirm-delete text-transform-none box-shadow-none" @click="confirmDeleteFile">Verwijder</button>
      </div>
    </template>
  </modal>
</template>

<script>
  // store
  import { mapState } from 'vuex';

  // components
  import modal from '@/components/modal/Modal';

  export default {
    name: 'datastore-file-delete-modal',
    emits: ['deleteConfirm'],
    components: {
      modal,
    },
    computed: {
      ...mapState({
        fileDeleteModal: (state) => state.datastore.fileDeleteModal,
      }),
    },
    methods: {
      // file has been confirmed to be deleted
      confirmDeleteFile() {
        this.$store.dispatch('datastore/deleteFiles', this.fileDeleteModal.fileIds);
        this.$emit('deleteConfirm');
        this.$refs.fileDeleteModal.hide();
      },

      /**
       * file delete modal has been hidden
       * clear fileDeleteModal value in store
       */
      fileDeleteModalHidden() {
        this.$store.commit('datastore/setFileDeleteModal', null);
      },
    },
    watch: {
      fileDeleteModal() {
        if (this.fileDeleteModal) {
          // show file delete confirmation modal
          this.$refs.fileDeleteModal.show();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .close {
    top: 10px;
    right: 10px;
    line-height: 1;
    transform: none;
  }
</style>
