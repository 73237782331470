<template>
  <div class="dropdown" :id="id" v-if="items.length > 0">
    <a href="#" class="nav-link d-flex align-items-center" data-toggle="dropdown">
      <i :class="icon"></i>
        <span class="text-secondary font-weight-bold">{{ activeItem }}</span>
      <i class="bi bi-chevron-down text-12 ml-1"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" :id="id">
      <a href="#"
         v-for="item in items"
         :key="item.key"
         :disabled="isDisabled"
         @click="changeActiveItem(item.uid)"
         class="dropdown-item d-flex align-items-center">
        <span class="text-secondary" :class="{'font-weight-bold':item.active}">{{ item.value }}</span>
      </a>
    </div>
  </div>
</template>

<script>

import { udpateGlobalFilterSettings } from '@/api/common';

export default {
  name: 'global-filter-dropdown',
  props: {
    json: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      isDisabled: false,
    };
  },
  computed: {
    activeItem() {
      return this.items.find((item) => item.active).value;
    },
  },
  methods: {
    async changeActiveItem(uid) {
      this.isDisabled = true;

      const formData = new FormData();
      formData.append('globalFilterID', this.id);
      formData.append('newValue', uid);

      await udpateGlobalFilterSettings(formData);

      // eslint-disable-next-line no-param-reassign
      this.items.forEach((item) => { item.active = item.uid === uid; });

      // reload the current window
      window.location.reload();

      this.isDisabled = false;
    },
  },
  mounted() {
    this.items = Object.values(JSON.parse(this.json));
  },

};
</script>

<style lang="scss" scoped>

</style>
