export const formStates = {
  activeFormField: {},
  formFieldData: {},
  thumbRefsToBeDeleted: [],
  matrixUploadFields: {},
  matrixModalFormFieldId: '',
  uploadModalSettings: {},
};

export default {
  formStates,
};
