<template>
  <skeleton-box class="mb-2" height="64px"/>
  <skeleton-box class="mb-1" height="48px" v-for="skItem in 4" :key="skItem"/>
  <skeleton-box class="w-25 float-right mt-3" height="16px"/>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'datastore-view-list-skeleton',
    components: {
      skeletonBox,
    },
  };
</script>
