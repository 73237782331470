import { getSuccessNotification, getErrorNotification } from '@/views/engine/utils/notifications';
import { getActionSettings, executeAction } from '@/api/engine/grid';
import GridAction from '../gridAction';

export default class GridActionHref {
  /**
   * delete an item
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.events = {
      handleHrefAction: this.handleHrefAction.bind(this),
    };
    this.props = {
      ...{
        ajaxParams: null,
        xhrAction: null,
        xhrUrl: null,
        senderid: null,
      },
      ...payload.props,
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };
    this.data = {
      formData: null,
      pk: null,
      fk: null,
    };
    this.customEvents = {
      notificationSuccess: getSuccessNotification(),
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.handleHrefAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.handleHrefAction);
  }

  /**
   * bulk button action
   *
   * @param e {MouseEvent}
   */
  async handleHrefAction(e) {
    // prevent default click
    e.preventDefault();

    // needed for end call
    this.data.formData = new FormData();
    this.data.formData.append('gridID', this.props.gridId);

    // pass the necessary data
    const formData = new FormData();
    formData.append('actionID', e.target.dataset.actionid);

    // load action settings
    await getActionSettings(formData)
      .then((response) => {
        const exeData = new FormData();
        Object.keys(response.data).forEach((n) => {
          exeData.append(n, response.data[n]);
        });
        if (e.target.dataset.method) {
          exeData.append('method', e.target.dataset.method);
        }
        if (e.target.dataset.pk) {
          this.data.pk = e.target.dataset.pk;
          exeData.append('pk', e.target.dataset.pk);
        }
        if (e.target.dataset.fk) {
          this.data.fk = e.target.dataset.fk;
          exeData.append('fk', e.target.dataset.fk);
        }

        // execute the action
        executeAction(exeData)
          .then((actionResponse) => {
            // set custom options per method
            this.setCustomOptionsMethod(e.target);

            // default messages
            let notificationMessage = {};

            // report success
            if (actionResponse.data.success) {
              if (actionResponse.data.successMessage) {
                notificationMessage = {
                  title: actionResponse.data.successMessage,
                  type: 'success',
                };
                this.dom.engine.dispatchEvent(getSuccessNotification(notificationMessage));
              }
            } else {
              // default messages
              notificationMessage = {};

              // report error
              if (actionResponse.data.errorMessage) {
                notificationMessage = {
                  title: actionResponse.data.errorMessage,
                  type: 'danger',
                };
                this.dom.engine.dispatchEvent(getErrorNotification(notificationMessage));
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.dom.engine.dispatchEvent(getErrorNotification());
          });
      })
      .catch((error) => {
        console.log(error);
        this.dom.engine.dispatchEvent(getErrorNotification());
      });
  }

  /**
   * set custom options for a method
   *
   * @param target {Object}
   */
  async setCustomOptionsMethod(target) {
    console.log(target);
    const { method } = target.dataset;

    // check current class
    switch (method) {
      case 'generate-datagridfields':
      case 'generate-formfields':
        // add class
        target.classList.add('text-info');
        target.classList.remove('fa-cogs');
        target.classList.add('fa-wrench');
        break;
      case 'form-from-datagridsource':
        this.props.senderid.classList.add('text-info');
        break;
      case 'copy-datagridfield':
        // reload the grid
        this.data.formData.append('pk', this.data.fk); // pass fk as pk
        await this.components.filterAction.applyFilter(this.data.formData);
        break;
      case 'copy-formfield':
        // reload the grid
        this.data.formData.append('pk', this.data.fk); // pass fk as pk
        await this.components.filterAction.applyFilter(this.data.formData);
        break;
      case 'genereate-tables-from-datagridsource':
      case 'copy-form':
      case 'copy-datagrid':
      case 'copy-task':
        // reload the grid
        console.log('reload grid');
        this.components.filterAction.applyFilter(this.data.formData);
        break;
      default:
        console.log(`${method} has no related customOptions in hrefAction class`);
        // by default no options
        break;
    }
  }
}
