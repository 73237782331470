<template>
  <li class="context-menu-item dropdown-item cursor-pointer text-14">
    <slot/>
  </li>
</template>

<script>
  export default {
    name: 'contextMenuItem',
  };
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/include";
</style>
