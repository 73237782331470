// store
import { mapState } from 'vuex';

export default {
  computed: {
    fileDeleteModal: {
      get() {
        return this.$store.state.fileDeleteModal;
      },
      set(value) {
        this.$store.commit('datastore/setFileDeleteModal', value);
      },
    },
    ...mapState({
      requireFileDeleteConfirm: (state) => state.datastore.requireFileDeleteConfirm,
    }),
  },
  methods: {
    /**
     * delete file
     * @param requireConfirmation {Boolean}
     */
    deleteFile(requireConfirmation = this.requireFileDeleteConfirm) {
      if (requireConfirmation) {
        // confirm before deleting
        this.fileDeleteModal = {
          fileIds: [this.file.fileUID],
          fileName: this.file.fileName,
        };
      } else {
        // skip confirmation and delete
        this.$store.dispatch('datastore/deleteFiles', [this.file.fileUID]);
      }
    },
  },
};
