<template>
  <button @click="deleteFiles" class="dropdown-item text-danger" type="button">
    Verwijder
  </button>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'datastore-btn-delete-files',
    computed: {
      ...mapState({
        selectedFiles: (state) => state.datastore.selectedFiles,
        requireFileDeleteConfirm: (state) => state.datastore.requireFileDeleteConfirm,
      }),
      ...mapGetters({
        selectedFilesLength: 'datastore/selectedFilesLength',
        selectedFileIds: 'datastore/getSelectedFileIds',
      }),
    },
    data() {
      return {};
    },
    methods: {
      // delete selected file(s)
      deleteFiles() {
        // no file(s) selected
        if (this.selectedFilesLength < 1) {
          return;
        }

        // confirm before deleting
        if (this.requireFileDeleteConfirm) {
          try {
            if (this.selectedFilesLength > 1) {
              // multiple files
              this.$store.commit('datastore/setFileDeleteModal', {
                fileIds: this.selectedFileIds,
              });
            } else if (this.selectedFileIds.length === 1) {
              // single file
              this.$store.commit('datastore/setFileDeleteModal', {
                fileIds: this.selectedFileIds,
                fileName: this.selectedFiles[0].fileName,
              });
            }
          } catch {
            // delete failed
            this.$store.commit('addMessage', {
              title: 'Unable to delete.',
              type: 'danger',
            });
          }

          // exit fn (need confirmation first)
          return;
        }

        // skip confirmation and delete
        this.$store.dispatch('datastore/deleteFiles', this.selectedFileIds);
      },
    },
  };
</script>
