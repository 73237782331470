<template>
  <div class="swiper-container">
    <slot name="parallax-bg"></slot>
    <div class="swiper-wrapper" :class="wrapperClass">
      <slot></slot>
    </div>
    <slot name="pagination"></slot>
    <slot name="button-prev"></slot>
    <slot name="button-next"></slot>
    <slot name="scrollbar"></slot>
  </div>
</template>

<script>
  import Swiper from 'swiper';
  import 'swiper/swiper.scss';

  export default {
    name: 'swiper',
    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
      wrapperClass: String,
    },
    methods: {
      update() {
        if (this.swiper) {
          if (this.swiper.update) {
            this.swiper.update();
          }

          if (this.swiper.navigation) {
            this.swiper.navigation.update();
          }

          if (this.swiper.pagination) {
            this.swiper.pagination.render();
            this.swiper.pagination.update();
          }
        }
      },
    },
    data() {
      return {
        swiper: null,
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.swiper = new Swiper(this.$el, this.options);
      });
    },
    updated() {
      this.update();
    },
  };
</script>

<style scoped lang="scss">
  // @import "src/scss/abstracts/_variables.scss";
  // @import "src/scss/vendor/bootstrap/_custom.scss";

  .swiper-container:not(.swiper-container-initialized ) {
    opacity: 0;
  }

  .swiper-team-gallery {
    overflow: visible;

    :deep(img) {
      transform: skewY(4deg) scale(1.1);
    }

    .swiper-slide {
      //box-shadow: 3px 12px 20px rgba($dark, 0.1);
      border-radius: 10px;
      overflow: hidden;
    }
  }
</style>
