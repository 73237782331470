import axios from 'axios';
import { root } from '@/utils/paths';
import { getTranslation } from '@/utils/languages';
import { getErrorNotification, getSuccessNotification } from '@/views/engine/utils/notifications';

export default class GridActionPublish {
  /**
   * (un)publish an item
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
    };
    this.data = {
      formData: null,
      ajaxParams: JSON.parse(this.dom.action.dataset.ajaxparams),
    };
    this.events = {
      publishItem: this.publishItem.bind(this),
    };
    this.customEvents = {
      notificationSuccess: getSuccessNotification(),
      notificationError: getErrorNotification(),
    };

    this.mount();
  }

  mount() {
    this.dom.action.addEventListener('click', this.events.publishItem);
  }

  unmount() {
    this.dom.action.removeEventListener('click', this.events.publishItem);
  }

  /**
   *
   * @param e {MouseEvent}
   */
  publishItem(e) {
    e.preventDefault();

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('table', this.data.ajaxParams.tablename);
    this.data.formData.append('tablepk', this.data.ajaxParams.field);
    this.data.formData.append('tableid', this.data.ajaxParams.pk);

    // make axios call
    axios({
      method: 'post',
      url: `${root}common/publish-item`,
      data: this.data.formData,
    })
      .then(() => {
        // handle success
        this.setPublishIcons();
        // @todo set proper server response (published or unpublished)
        this.customEvents.notificationSuccess = new CustomEvent('notification', {
          detail: {
            title: this.dom.action.getAttribute('title'),
            type: 'success',
          },
        });
        this.dom.engine.dispatchEvent(this.customEvents.notificationSuccess);
      })
      .catch(() => this.dom.engine.dispatchEvent(this.customEvents.notificationError));
  }

  setPublishIcons() {
    if (this.dom.action.classList.contains('text-info')) {
      this.dom.action.classList.remove('text-info');
      this.dom.action.title = getTranslation('publish');
      this.dom.action.dataset.originalTitle = getTranslation('publish');
    } else {
      this.dom.action.classList.add('text-info');
      this.dom.action.title = getTranslation('unpublish');
      this.dom.action.dataset.originalTitle = getTranslation('unpublish');
    }
  }
}
