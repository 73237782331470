import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';

export default class RandomAction {
  /**
   * Constructor for the GridFilter class
   *
   * @param randomId {String}
   * @param url {String}
   */
  constructor(randomId, url) {
    console.log(randomId);
    this.dom = {
      random: document.getElementById(`js-replace-random-${randomId}`),
      debugReport: document.getElementById('debugReport'),
      engine: document.getElementById('engine'),
    };
    this.customEvents = {
      randomBeforeReplace: new CustomEvent('randomBeforeReplace', { detail: { randomId } }),
      randomAfterReplace: new CustomEvent('randomAfterReplace', { detail: { randomId } }),
      errorNotification: getErrorNotification(),
    };
    this.randomId = randomId;
    this.url = url;
  }

  /**
   * @param data {FormData}
   */
  applyFilter(data) {
    // make axios call
    return axios({
      method: 'post',
      url: root + this.url,
      data,
    })
      .then((response) => {
        // success
        if (response.data.success) {
          // dispatch the reload event
          this.dom.engine.dispatchEvent(this.customEvents.randomBeforeReplace);

          // set table content
          this.dom.random.innerHTML = response.data.overview;

          // some useful debug info
          if (this.dom.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }

          // after replace actions (rebind events)
          this.dom.engine.dispatchEvent(this.customEvents.randomAfterReplace);
        } else {
          this.dom.engine.dispatchEvent(getErrorNotification({ title: response.data.errorMessage }));
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);

        // dispatch errorNotification event
        this.dom.engine.dispatchEvent(this.customEvents.errorNotification);
      });
  }
}
