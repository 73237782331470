// store
import { mapState } from 'vuex';
import { getWidgetForm } from '@/api/v10/widgets';

export default {
  emits: {
    // Validate updateForm event
    updateForm: ({
      html,
      detailId,
      widgetId,
    }) => {
      if (html && detailId && widgetId) {
        return true;
      }

      // emit props are invalid
      console.warn('Invalid updateForm event payload!');
      return false;
    },
  },
  computed: {
    ...mapState({
      formId: (state) => state.widgets.formId,
      recordId: (state) => state.widgets.recordId,
      activeDetailId: (state) => state.widgets.activeDetailId,
    }),
  },
  methods: {
    editWidget(detailId, widgetId) {
      // do nothing is action is triggered on the same widget
      if (this.activeDetailId === detailId) return;

      // @todo add states to prevent continuous requests?

      // build request data
      const formData = new FormData();
      formData.append('formID', this.formId);
      formData.append('recordID', this.recordId);
      formData.append('detailID', detailId);
      formData.append('activeWidgetID', widgetId);

      // send request
      getWidgetForm(formData)
        .then((response) => {
          // emit updateForm
          this.$emit('updateForm', {
            html: response.data.widgetDetailForm,
            detailId,
            widgetId,
          });
        })
        .catch((error) => console.log(error));
    },
  },
};
