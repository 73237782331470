import $ from 'jquery';
import select2 from 'select2';
import SideNav from '../sidenav';
import layoutHelpers from '../layout-helpers';

// Auto update layout
// eslint-disable-next-line func-names
(function () {
  layoutHelpers.setAutoUpdate(true);
}());

// Collapse menu
// eslint-disable-next-line func-names
(function () {
  if ($('#layout-sidenav').hasClass('sidenav-horizontal') || layoutHelpers.isSmallScreen()) {
    return;
  }

  try {
    layoutHelpers.setCollapsed(
      localStorage.getItem('layoutCollapsed') === 'true',
      false,
    );
  } catch (e) {
    // e
  }
}());

$(() => {
  // Initialize sidenav
  $('#layout-sidenav').each(function layoutSidenavLoop() {
    // eslint-disable-next-line no-new
    new SideNav(this, {
      orientation: $(this).hasClass('sidenav-horizontal') ? 'horizontal' : 'vertical',
    });
  });

  // Initialize sidenav togglers
  $('body').on('click', '.layout-sidenav-toggle', (e) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
    if (!layoutHelpers.isSmallScreen()) {
      try {
        localStorage.setItem('layoutCollapsed', String(layoutHelpers.isCollapsed()));
        // eslint-disable-next-line no-shadow
      } catch (e) {
        // e
      }
    }
  });

  if ($('html').attr('dir') === 'rtl') {
    $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right');
  }
});

// Select2
$(() => {
  if (select2) {
    $('.select2-demo').each(function selectDemoLoop() {
      $(this)
        .wrap('<div class="position-relative"></div>')
        .select2({
          placeholder: 'Select value',
          dropdownParent: $(this).parent(),
        });
    });
  }
});
