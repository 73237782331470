import axios from 'axios';
import { root } from '@/utils/paths';

/**
 * get the action settings from a grid action
 * @param data {FormData}
 * @returns {Promise}
 */
export function getFormSettings(data) {
  return axios({
    method: 'post',
    url: `${root}forms/get-form-settings`,
    data,
  });
}

/**
 * Get files of form field
 * @param data {FormData}
 * @returns {Promise}
 */
export function getFormFieldData(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/get-connected-files`,
    data,
  });
}

/**
 * Get matrix upload data
 * @param data {FormData}
 * @returns {Promise}
 */
export function getMatrixData(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/get-matrix-files`,
    data,
  });
}

/**
 * Get slave field data
 * @param data {FormData}
 * @returns {Promise}
 */
export function getSlaveData(data) {
  return axios({
    method: 'post',
    url: `${root}formgenerator/get-slave`,
    data,
  });
}

export default {
  getFormSettings,
  getFormFieldData,
  getMatrixData,
  getSlaveData,
};
