import { createApp } from 'vue';
import App from './App';
import './registerServiceWorker';
import store from './store';
import components from './components';
import views from './views';

const app = createApp(App);

// Auto register components
Object.keys(components).forEach((key) => {
  app.component(key, components[key]);
});

// register views
Object.keys(views).forEach((key) => {
  app.component(key, views[key]);
});

app.use(store).mount('#page-container');
