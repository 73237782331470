import axios from 'axios';
import { root } from '@/utils/paths';
import { getCritical } from '@/views/engine/utils/alerts';

export default class FormAddElement {
  /**
   * set an item on of off
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    console.log(action);
    this.dom = {
      ...{
        action,
        engine: document.getElementById('engine'),
      },
      ...payload.dom,
    };

    this.props = {
      ...{
        elementType: this.dom.action.dataset.type,
        parentComponent: null,
        parentFormGroup: null,
      },
      ...payload.props,
    };

    this.events = {
      onAddElement: this.onAddElement.bind(this),
    };

    this.customEvents = {
      criticalError: getCritical,
    };

    this.mount();
  }

  mount() {
    this.dom.action.addEventListener('click', this.events.onAddElement);
  }

  unmount() {
    this.dom.action.removeEventListener('click', this.events.onAddElement);
  }

  /**
   * add an element to the form
   *
   * @param e {MouseEvent}
   */
  onAddElement(e) {
    console.log('add an element');

    // prevent default click
    e.preventDefault();

    // define witch element is added
    switch (this.props.elementType) {
      case 'component': {
        console.log('component');

        const formData = new FormData();
        formData.append('formID', 8);
        formData.append('fk', this.props.formId);
        formData.append('refFormID', this.props.formId);
        formData.append('reloadMode', 'build');

        // make axios call
        axios({
          method: 'post',
          url: `${root}formgenerator/add-element-to-form`,
          data: formData,
        })
          .then((response) => {
            // successful reply
            if (response.data.success) {
              // @robert
              // modal form wordt getoond en afgehandeld
              // $('#modal-form-content').html(result.htmlreply);
              // $('#modal-form').modal();
            } else {
              // send error notification
              getCritical();
            }

            // some useful debug info
            if (response.data.debugReport) {
              this.dom.debugReport.innerHTML = response.data.debugReport;
            }
          })
          .catch((error) => {
            console.log(error);
            getCritical();
          });
        break;
      }
      case 'formgroup':
        console.log('formgroup');
        break;
      case 'field':
        console.log('field');
        break;
      default:
        // throw critical alert
        getCritical();
        break;
    }
  }
}
