import * as api from '@/api/engine/form';

export const formActions = {
  /**
   * Get files from form field
   * @param commit
   * @param args {object} - {pk: {string}, formId: {string}, formFieldId: {string}}
   * @returns {Promise}
   */
  getFormFieldData({ commit }, args) {
    console.log('engine/getFormFieldData');

    const formData = new FormData();
    formData.append('pk', args.pk);
    formData.append('formID', args.formId);
    formData.append('formFieldID', args.formFieldId);

    return api.getFormFieldData(formData)
      // success
      .then((response) => {
        commit('setFormFieldData', {
          id: args.pk,
          files: response.data.files,
          settings: response.data.settings,
        });
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to get form field files.',
            type: 'danger',
          },
          { root: true });
      });
  },

  /**
   * Get matrix files
   * @param commit
   * @param args {object} - {pk: {string}, formId: {string}, formFieldId: {string}}
   * @returns {Promise}
   */
  getMatrixData({ commit }, args) {
    console.log('engine/getMatrixData');

    const formData = new FormData();
    formData.append('pk', args.pk);
    formData.append('formID', args.formId);
    formData.append('formFieldID', args.formFieldId);

    return api.getMatrixData(formData)
      // success
      .then((response) => {
        const key = response.data.formFieldId;
        const value = {
          settings: response.data.settings,
          items: Object.values(response.data.slides),
          template: response.data.template,
        };
        // add to store
        commit('setMatrixUploadFields', { [key]: value });
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to get matrix data.',
            type: 'danger',
          },
          { root: true });
      });
  },
};

export default {
  formActions,
};
