<template>
  <button class="btn btn-default btn-xs btn-round border-0 d-flex align-items-center justify-content-center dropdown-item hide-arrow"
          data-toggle="dropdown"
          type="button">
    <slot>
      <i class="bi bi-three-dots ml-1px"></i>
    </slot>
  </button>
</template>

<script>
  export default {
    name: 'dropdownHorizontalEllipsisToggle',
  };
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/include";

  .ml-1px {
    margin-left: 1px;
  }

  .btn-default,
  .btn-default:active {
    color: $btn-default-color;
    background: $white;

    &:hover,
    &:focus {
      box-shadow: 0 1px 4px 0 rgba(#d2d6dc, 0.9);
    }
  }

  .btn {
    $size: 22px;

    width: $size;
    height: $size;
    padding: 0;
  }
</style>
