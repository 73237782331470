import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';

export default class RandomActionHelpRequest {
  /**
   * set an item on of off
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      pk: this.dom.action.dataset.pk,
      table: this.dom.action.dataset.table,
      field: this.dom.action.dataset.field,
    };
    this.events = {
      helpAction: this.manageHelp.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.helpAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.helpAction);
  }

  /**
   * switch the help off
   *
   * @param e {MouseEvent}
   */
  manageHelp(e) {
    e.preventDefault();

    let actionType = 'request';
    let manageIconsInArea = 'none';
    if (e.target.classList.contains('js-help-provided')) actionType = 'support';
    if (this.dom.action.dataset.icons) manageIconsInArea = this.dom.action.dataset.icons;

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('table', this.dom.action.dataset.table);
    this.data.formData.append('field', this.dom.action.dataset.field);
    this.data.formData.append('pair', this.dom.action.dataset.pair);
    this.data.formData.append('actionType', actionType);

    // make axios call
    axios({
      method: 'post',
      url: `${root}classmanagement/manage-help`,
      data: this.data.formData,
    })
      .then((response) => {
        if (!response.data.success) {
          this.showError(response.data.errorMessage);
        } else {
          // handle success
          if (manageIconsInArea !== 'none') this.manageIcons(actionType, manageIconsInArea);
          // @todo set proper server response (published or unpublished)
          const notificationSuccess = new CustomEvent('notification', {
            detail: {
              title: response.data.successMessage,
              type: 'success',
            },
          });
          this.dom.engine.dispatchEvent(notificationSuccess);
        }
      })
      .catch(() => {
        getCritical();
      });
  }

  /**
   * throw error
   *
   * @param errorMessage {String}
   */
  showError(errorMessage) {
    const notificationError = new CustomEvent('notification', {
      detail: {
        title: `${errorMessage}`,
        type: 'danger',
      },
    });
    this.dom.engine.dispatchEvent(notificationError);
  }

  /**
   * manage the icons
   *
   * @param actionType {String}
   * @param areaCalling {String}
   */
  manageIcons(actionType, areaCalling) {
    switch (areaCalling) {
      case 'mydetails':
        // classes to manipulate
        // eslint-disable-next-line no-case-declarations
        const provided = ['display-4', 'text-info', 'fas', 'fa-hand-paper', 'js-help-provided'];
        // eslint-disable-next-line no-case-declarations
        const requested = ['btn', 'btn-xs', 'btn-outline-secondary', 'js-help-requested'];
        // check the action
        if (actionType === 'support') {
          console.log('hulp verleend...');
          provided.forEach((element) => {
            this.dom.action.classList.remove(element);
          });
          requested.forEach((element) => {
            this.dom.action.classList.add(element);
          });
          const inside = this.dom.action.getElementsByTagName('div');
          inside[0].innerHTML = '<span class="ion ion-md-add"></span> Vraag hulp';
        } else {
          console.log('hulp gevraagd...');
          requested.forEach((element) => {
            this.dom.action.classList.remove(element);
          });
          provided.forEach((element) => {
            this.dom.action.classList.add(element);
          });
          const inside = this.dom.action.getElementsByTagName('div');
          inside[0].innerHTML = '';
        }
        break;

      default:
        if (actionType === 'support') {
          console.log('hulp verleend...');
          // manage layout
          const siblings = this.dom.action.parentElement.parentElement.getElementsByTagName('div');
          siblings[0].classList.remove('student-number-helpme');
          siblings[1].classList.remove('student-number-helpme');
          siblings[0].classList.add('student-number-waiting');
          siblings[1].classList.add('student-number-waiting');
          // delete the action
          this.dom.action.remove();
        }
        break;
    }
  }
}
