<template>
  <div class="pb-1">
    <div
      :class="type"
      class="toast position-relative mb-0"
      ref="toast"
      role="alert"
      aria-live="polite"
      aria-atomic="true">
      <div class="toast-body">
        <div class="d-flex align-items-center justify-content-start">
          <div
            :class="`border-${type}`"
            class="icon-wrapper d-flex align-items-center justify-content-center flex-shrink-0">
            <i :class="[iconClass, `text-${type}`]"></i>
          </div>
          <div class="text">
            <div v-if="title && title.trim().length > 0"
                 :class="`text-${type}`"
                 class="font-weight-bold">
              {{ title }}
            </div>
            <div>{{ text }}</div>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="toast" aria-label="Close">
          <i class="text-12 fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import 'bootstrap/js/dist/toast';
  import $ from 'jquery';
  import gsap from 'gsap';

  export default {
    name: 'toast',
    props: {
      title: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: false,
      },
      type: {
        type: String,
        required: false,
      },
      autoHide: {
        type: Boolean,
        required: false,
        default: true,
      },
      delay: {
        type: Number,
        required: false,
        default: 10000, // in ms
      },
    },
    computed: {
      iconClass() {
        let iconClass = '';

        switch (this.type) {
          case 'danger':
            iconClass = 'fas fa-exclamation-circle';
            break;
          case 'warning':
            iconClass = 'fas fa-exclamation-triangle';
            break;
          case 'info':
            iconClass = 'fas fa-info-circle';
            break;
          case 'success':
          case 'primary':
          case 'secondary':
          case 'light':
          case 'dark':
          default:
            iconClass = 'fas fa-check';
            break;
        }

        return iconClass;
      },
    },
    data() {
      return {
        staggerDelay: 350, // in ms
      };
    },
    methods: {
      show() {
        $(this.$refs.toast)
          .toast({
            delay: this.delay,
            autohide: this.autoHide,
          });

        $(this.$refs.toast)
          .toast('show');
      },
      destroy() {
        this.$emit('destroy');
      },
      onHidden() {
        // prepare for gracefully decrease height
        gsap.set(this.$refs.toast, {
          display: 'block',
          y: '-50%',
        });

        // decrease height
        gsap.to(this.$refs.toast, {
          duration: 0.5,
          height: 0,
          onComplete: this.destroy,
        });
      },
    },
    mounted() {
      // get Index of visible toasts
      const toastParent = document.getElementById('toast-parent');
      const myIndex = [...toastParent.querySelectorAll('.toast:not(.show)')].indexOf(this.$refs.toast);

      // listen to hidden event
      $(this.$refs.toast)
        .on('hidden.bs.toast', this.onHidden);

      // show staggered toast
      setTimeout(this.show, (myIndex * this.staggerDelay));
    },
  };
</script>
<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/include";
  @import "~@/assets/scss/abstract/_timing_functions.scss";

  $ease-in: $ease-in-quad;
  $ease-out: $ease-out-quad;
  $duration: 0.35s;

  .toast {
    line-height: 1.35;
    transition: transform $duration $ease-out,
    opacity $duration $ease-out,
    background-color 0.25s;

    &:hover,
    &:focus {
      background: $white;
    }

    &:not(.show) {
      pointer-events: none;
      touch-action: none;
    }

    &:not(.showing):not(.show) {
      transition-timing-function: $ease-in;
      transform: translateY(-50%);
      opacity: 0;
    }
  }

  .toast-body {
    padding-right: $toast-padding-x * 2.5;
  }

  .icon-wrapper {
    $icon-size: 28px;

    width: $icon-size;
    flex-basis: $icon-size;
    height: $icon-size;
    border-radius: 50%;
    margin-right: $toast-padding-x;
    border-width: 1px;
    border-style: solid;
  }

  .text {
    word-break: break-word;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    line-height: 0;
    padding: 5px;
    display: flex;
    transition: $transition-base;

    &:hover {
      transform: scale(1.25);
    }

    &:not(:hover) {
      opacity: 0.4;
    }
  }
</style>
