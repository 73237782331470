export const fileMutations = {
  /**
   * set files state
   * @param state
   * @param files {array}
   */
  setFiles(state, files) {
    console.log('datastore/setFiles');
    state.files = files;
  },

  /**
   * add file id to selection
   * @param state
   * @param file {object}
   */
  addSelectedFile(state, file) {
    console.log('datastore/addSelectedFile');
    state.selectedFiles.push(file);
  },

  /**
   * remove file id from selection
   * @param state
   * @param file {object}
   */
  removeSelectedFile(state, file) {
    console.log('datastore/removeSelectedFile');
    // state.selectedFiles.splice(state.selectedFiles.indexOf(id), 1);
    state.selectedFiles = state.selectedFiles.filter((f) => f.fileUID !== file.fileUID);
  },

  /**
   * set selected file id's
   * @param state
   * @param arr {array}
   */
  setSelectedFiles(state, arr) {
    console.log('datastore/setSelectedFiles');
    state.selectedFiles = arr;
  },

  /**
   * element (or object with positions) on which the tooltip is bound
   * @param state
   * @param ref {Object|HTMLElement}
   */
  setEditRef(state, ref) {
    console.log('datastore/setEditRef');
    state.editRef = ref;
  },

  /**
   * set file to be edited
   * @param state
   * @param file {object}
   */
  setEditFile(state, file) {
    console.log('datastore/setEditFile');
    state.editFile = file;
  },

  /**
   * edit a file
   * @param state
   * @param file {object}
   */
  editFile(state, file) {
    console.log('datastore/editFile');
    // replaces object in array and return as array
    state.files = state.files.map((o) => (file.fileUID === o.fileUID ? file : o));
  },

  /**
   * add file
   * @param state
   * @param file {object}
   */
  addFile(state, file) {
    console.log('datastore/addFile');
    // check if file exists
    const existingIndex = state.files.findIndex((f) => f.fileUID === file.fileUID);

    if (existingIndex >= 0) {
      // replace existing file
      state.files[existingIndex] = file;
    } else {
      // add new file
      state.files.unshift(file);
    }
  },

  /**
   * delete file
   * @param state
   * @param fileId {string}
   */
  deleteFile(state, fileId) {
    console.log('datastore/deleteFile');
    state.files = state.files.filter((file) => file.fileUID !== fileId);
  },

  /**
   * set values for file delete modal
   * @param state
   * @param obj {object}
   */
  setFileDeleteModal(state, obj) {
    console.log('datastore/setFileDeleteModal');
    state.fileDeleteModal = obj;
  },

  /**
   * set thumbnail size
   * @param state
   * @param size {Number} thumbnail size in pixels
   */
  setThumbSize(state, size) {
    console.log('datastore/setThumbSize');
    state.thumbSize = size;
  },

  /**
   * set active file
   * @param state
   * @param file {object}
   */
  setActiveFile(state, file) {
    console.log('datastore/setActiveFile');
    state.activeFile = file;
  },
};

export default {
  fileMutations,
};
