export function capitalizeFirstLetter(string) {
  return string.charAt(0)
    .toUpperCase() + string.slice(1);
}

/**
 * remove accents/diacritics in a string
 * @param str {String}
 * @returns {String}
 */
export function normalizeText(str) {
  /**
   * https://stackoverflow.com/a/37511463
   * 1. Normalize()ing to NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
   *    The è of Crème ends up expressed as e + ̀.
   * 2. Using a regex character class to match the U+0300 → U+036F range, it is now trivial to globally get rid of the diacritics,
   *    which the Unicode standard conveniently groups as the Combining Diacritical Marks Unicode block.
   */
  return str
    // lowercase
    .toLowerCase()
    // 1
    .normalize('NFD')
    // 2
    .replace(/[\u0300-\u036f]/g, '')
    // strip multiple spaces, tabs and newlines
    .replace(/[\t\n/\s{2,}]+/g, '')
    // only keep basic characters, -
    .replace(/[^a-zA-Z0-9-]+/g, '')
    // exceptions
    .replace(/Æ/g, 'A')
    .replace(/æ/g, 'a')
    .replace(/œ/g, 'oe')
    .replace(/Ø/g, 'O')
    .replace(/ø/g, 'o')
    .replace(/Þ/g, 'B')
    .replace(/þ/g, 'b')
    .replace(/ß/g, 'Ss')
    .replace(/á/g, 'a')
    .replace(/à/g, 'a')
    .replace(/â/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ã/g, 'a')
    .replace(/ç/g, 'c')
    .replace(/é/g, 'e')
    .replace(/è/g, 'e')
    .replace(/ê/g, 'e')
    .replace(/ë/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ì/g, 'i')
    .replace(/î/g, 'i')
    .replace(/ï/g, 'i')
    .replace(/ñ/g, 'n')
    .replace(/ó/g, 'o')
    .replace(/ò/g, 'o')
    .replace(/ô/g, 'o')
    .replace(/ö/g, 'o')
    .replace(/ð/g, 'o')
    .replace(/ù/g, 'u')
    .replace(/ú/g, 'u')
    .replace(/û/g, 'u')
    .replace(/ü/g, 'u')
    .replace(/ý/g, 'y')
    .replace(/ÿ/g, 'y');
}

/**
 * normalize text and replace space by delimiter
 * @param str
 * @param delimiter {string}
 * @returns {string}
 */
export function slugifyText(str, delimiter = '-') {
  return normalizeText(str)
    .replace(/ /g, delimiter);
}

/**
 * remove accents/diacritics in a string
 * @param str {String}
 * @returns {String}
 */
export function makePasswordSafe(str) {
  /**
   * https://stackoverflow.com/a/37511463
   * 1. Normalize()ing to NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
   *    The è of Crème ends up expressed as e + ̀.
   * 2. Using a regex character class to match the U+0300 → U+036F range, it is now trivial to globally get rid of the diacritics,
   *    which the Unicode standard conveniently groups as the Combining Diacritical Marks Unicode block.
   */
  return str
    // exceptions
    .replace(/Æ/g, 'A')
    .replace(/æ/g, 'a')
    .replace(/œ/g, 'oe')
    .replace(/Ø/g, 'O')
    .replace(/ø/g, 'o')
    .replace(/Þ/g, 'B')
    .replace(/þ/g, 'b')
    .replace(/ß/g, 'Ss')
    .replace(/ð/g, 'o')
    // 1
    .normalize('NFD')
    // 2
    .replace(/[\u0300-\u036f]/g, '')
    // only keep basic characters, space, -
    .replace(/[^a-zA-Z0-9- ]+/g, '')
    // lowercase
    .toLowerCase()
    // delimiter
    .replace(/ /g, '-');
}
