import axios from 'axios';
import { getErrorNotification, getSuccessNotification } from '@/views/engine/utils/notifications';
// import TickMessage from '../components/tickmessage';

export default class QuickForm {
  /**
   * Constructor for the Quickform class
   */
  constructor(formEl) {
    console.log('quick form constructor');

    this.dom = {
      form: formEl,
      engine: document.getElementById('engine'),
    };

    this.props = {
      handle: null,
      reset: false,
    };

    this.events = {
      onSubmit: this.onSubmit.bind(this),
    };

    this.components = {
      post: null,
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    console.log('mount');

    // set the handle
    this.props.handle = this.dom.form.dataset.handle;
    this.props.reset = this.dom.form.dataset.reset;

    // add submit event
    this.dom.form.addEventListener('submit', this.events.onSubmit);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount');

    // remove submit event
    this.dom.form.removeEventListener('submit', this.events.onSubmit);
  }

  /**
   * manage the callback after post (reloading stuff)
   * @param e {CustomEvent}
   */
  onSubmit(e) {
    console.log(e, 'form submitted');

    // prevent default action
    e.preventDefault();

    // form data
    const formData = new FormData(this.dom.form);

    // make axios call
    axios({
      method: 'post',
      url: this.props.handle,
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          // bind
          const successMsg = getSuccessNotification({
            title: response.data.successMessage,
            type: 'success',
          });

          // dispatch the notification event
          this.dom.engine.dispatchEvent(successMsg);
        } else {
          // throw error
          const errorMsg = getErrorNotification({
            title: response.data.errorMessage,
            type: 'danger',
          });

          this.dom.engine.dispatchEvent(errorMsg);
        }

        // reset the form if required
        if (this.props.reset === 'true') this.dom.form.reset();
      })
      .catch((error) => {
        console.log(error);

        // throw error
        const errorMsg = getErrorNotification({
          title: error.data.errorMessage,
          type: 'danger',
        });

        this.dom.engine.dispatchEvent(errorMsg);
      });
  }
}
