export default {
  search: 'zoeken...',
  yes: 'ja',
  no: 'nee',
  cancel: 'annuleren',
  ok: 'OK',
  save: 'bewaar',
  'are you sure': 'Bent u helemaal zeker?',
  'are you sure to delete': 'Bent u helemaal zeker dat u wilt verwijderen?',
  success: 'Actie gelukt',
  error: 'Actie niet gelukt',
  'something went wrong': 'Er ging iets fout.  Als dit probleem blijft opduiken, gelieve support te contacteren aub?',
  'contact your webpartner': 'Er is een fout opgetreden, gelieve support te contacteren aub?',
  'Drop files here to upload': 'Drop files here to upload',
  'Your browser does not support drag drop file uploads': 'Your browser does not support drag & drop file uploads!',
  'Please use the fallback': 'Please use the fallback form below to upload your files?',
  'File is too big': 'File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.',
  'Type not supported': 'You can\'t upload files of this type.',
  'Server responded with statusCode': 'Server replied with statuscode {{statusCode}}.',
  'Cancel upload': 'Cancel upload',
  'Upload canceled': 'Upload canceled.',
  'Are you sure you want to cancel this upload': 'Are you sure you want to cancel this upload?',
  'Remove file': 'Remove file',
  'You can not upload any more files': 'You can not upload any more files.',
  'Files ready to move': ' Bestanden zijn klaar om te verplaatsen',
  'Files ready to copy': ' Bestanden zijn klaar om te kopiëren',
  'No files selected': ' Er werden geen bestanden geselecteerd',
  Datastore: 'Bibliotheek',
  'Name new folder': 'Geef de naam voor de nieuwe map aub?',
  'New name folder': 'Geef de nieuwe naam voor de map aub?',
  'New name file': 'Geef de nieuwe naam voor het bestand aub?',
  'Almost delete': 'Je staat op het punt te verwijderen...',
  Positionchange: 'Positie wijzigen',
  Positionchanged: 'De positie werd gewijzigd',
  NoneSelected: 'Er is geen selectie',
  AllSelected: 'Alles geselecteerd',
  'Make a choice': 'Maak een keuze',
  'Delete item': 'Verwijderen item',
  Valuechange: 'Waarde wijzigen',
  'Add category': 'Rubriek toevoegen',
  'Required zones': 'Gelieve de verplichte velden in te vullen',
  GridSearchLabel: 'Zoeken',
  GridSearchDefaultLabel: 'Tik iets...',
  publish: 'publiceer item',
  unpublish: 'item niet publiceren',
  attention: 'aandacht!',
  selectAll: 'Alles selecteren',
  deselectAll: 'Alles deselecteren',
};
