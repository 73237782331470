import axios from 'axios';
import $ from 'jquery';
import { getErrorNotification, getSuccessNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';
import { root } from '@/utils/paths';

export default class FormActionPost {
  /**
   * open form in modal
   * @param form {Element}
   * @param payload {Object}
   */
  constructor(form, payload = {}) {
    this.dom = {
      form,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
      tinyMces: form.querySelectorAll('.tiny-mce'),
      modalFormContent: null, // document.getElementById('modal-form-content'),
      replaceFormHolder: null,
      submitter: null,
      $modalForm: $('#modal-form'),
      spinEl: null,
    };

    this.data = {
      formData: null,
      actionSucceeded: true,
      showNotification: true,
      spinning: false,
    };

    this.components = {
      tinyMces: payload.tinyMces,
    };

    this.events = {
      onSubmit: this.onSubmit.bind(this),
    };

    this.customEvents = {
      notificationSuccess: null,
      notificationError: null,
      criticalError: getCritical,
      onSubmitFinally: null,
      onModalFormUnmount: new CustomEvent('onModalFormUnmount'),
      setReferencePK: null,
    };

    this.props = {
      viewId: null,
      widgetform: form.dataset.widgetform,
      isModal: false,
    };

    this.mount();
  }

  mount() {
    this.dom.form.addEventListener('submit', this.events.onSubmit);
  }

  unmount() {
    this.dom.form.removeEventListener('submit', this.events.onSubmit);
  }

  /**
   * on form submit
   * @param e {Event}
   */
  onSubmit(e) {
    console.log('onSubmit');

    // prevent default action
    e.preventDefault();

    // tiny mce instances
    if (this.components.tinyMces) {
      this.components.tinyMces.forEach((instanceTinyMce) => {
        instanceTinyMce.tinymce.triggerSave();
      });
    }

    // attributes
    const dataAttrs = this.dom.form.dataset;

    // spinning
    if (dataAttrs.spin) {
      // set data prop
      this.data.spinning = true;

      // set spinner
      this.dom.spinEl = document.getElementById('spinElement');
      this.dom.spinEl.innerHTML = `<img src="${root}dist/img/loading.gif">`;
    }

    const formData = new FormData(this.dom.form);

    // check for no widgets yet
    const id = parseInt(formData.get('activeWidgetID'), 10);

    // if activeWidgetID is undefined && it's a widget post break here
    if (Number.isNaN(id) && formData.get('widgetPost') === '1') {
      console.log('no widgets yet');
      return;
    }

    // if (!this.dom.submitter) this.dom.submitter = e.submitter;
    if (e.submitter) this.dom.submitter = e.submitter;

    // set the tab to goto if necessary - default 1
    formData.append('gotoTab', this.dom.form.dataset.gotoTab ?? 1);

    // make axios call
    axios({
      method: 'post',
      url: this.dom.form.dataset.handle,
      data: formData,
    })
      .then((response) => {
        // modal form follows separated routine
        if (dataAttrs.modal === 'true') {
          this.props.isModal = true;
          this.modalFormHandling(response);
        } else {
          // default form
          // save the viewId
          this.props.viewId = response?.data?.form?.activeRecordID;

          // successful response
          if (response.data.success) {
            // success
            this.onSuccess(response, formData.get('mode'), {
              title: response.data.successMessage,
              type: 'success',
            }, { showNotification: this.customEvents.showNotification });

            // form has no pending changes
            this.dom.form.dataset.hasPendingChanges = false;

            // after actions
            this.actionsAfterSuccessfulPost();
          } else {
            // errors on the form
            this.onError(response, {
              title: response.data.errorMessage,
              type: 'danger',
            });
          }

          // some useful debug info
          if (response.data.debugReport) {
            if (this.dom.debugReport) this.dom.debugReport.innerHTML = response.data.debugReport;
          }
        }
        // stop spinning
        if (this.data.spinning) {
          this.data.spinning = false;
          this.dom.spinEl.innerHTML = '';
        }
      })
      .catch((error) => {
        console.log('oooppss');
        console.log(error);
        this.onFail();
      });
  }

  /**
   * function manages actions after successful post
   */
  actionsAfterSuccessfulPost() {
    let reloadMode = 'grid';
    let callbackUrl = 'empty';
    let refGridId = 0;
    let refFormId = 0;
    let wizardStep = 0;
    let pk = 0;

    if (this.dom.form.querySelector('input[name="reloadMode"]')) reloadMode = this.dom.form.querySelector('input[name="reloadMode"]').value;
    if (this.dom.form.querySelector('input[name="callbackUrl"]')) callbackUrl = this.dom.form.querySelector('input[name="callbackUrl"]').value;
    if (this.dom.form.querySelector('input[name="refGridID"]')) refGridId = parseInt(this.dom.form.querySelector('input[name="refGridID"]').value, 10);
    if (this.dom.form.querySelector('input[name="refFormID"]')) refFormId = parseInt(this.dom.form.querySelector('input[name="refFormID"]').value, 10);
    if (this.dom.form.querySelector('input[name="wizardStep"]')) wizardStep = parseInt(this.dom.form.querySelector('input[name="wizardStep"]').value, 10);
    if (this.dom.form.querySelector('input[name="callbackFKAsPK"]') && this.dom.form.querySelector('input[name="fk"]')) {
      pk = parseInt(this.dom.form.querySelector('input[name="fk"]').value, 10);
    }

    if (this.data.actionSucceeded && reloadMode) {
      this.customEvents.onSubmitFinally = new CustomEvent('onSubmitFinally', {
        detail: {
          submitter: this.dom.submitter,
          viewID: this.props.viewId,
          pk,
          reloadMode,
          callbackUrl,
          refGridId,
          refFormId,
          wizardStep,
        },
      });

      this.dom.form.dispatchEvent(this.customEvents.onSubmitFinally);

      // only exec when no error on form
      if (this.props.isModal === true) {
        this.dom.form.dispatchEvent(this.customEvents.onModalFormUnmount);
      }
    }
  }

  /**
   * reload form
   * @param response {Object}
   */
  reloadForm(response) {
    // html gets replaced
    this.dom.replaceFormHolder = document.getElementById(`js-replace-formholder-${this.dom.form.dataset.formid}`);
    if (response.data.htmlreply) {
      this.dom.replaceFormHolder.innerHTML = response.data.htmlreply;
    }
  }

  /**
   * callback form is in modal
   * @param response {Object}
   */
  modalFormHandling(response) {
    // check if errors occurred
    if (response.data.success) {
      this.dom.$modalForm.modal('hide');
      this.data.actionSucceeded = true;

      // after post actions
      this.actionsAfterSuccessfulPost();
    } else {
      // error
      this.data.actionSucceeded = false;
      console.warn(response.data.errorMessage);

      // throw error
      this.customEvents.notificationError = getErrorNotification({
        title: response.data.errorMessage,
        type: 'danger',
      });

      // dispatch the notification event
      this.dom.engine.dispatchEvent(this.customEvents.notificationError);
      this.markErrorsOnForm(response, true);
    }
  }

  /**
   * request was successful
   * @param response {Object}
   * @param action {String}
   * @param notification {Object}
   * @param args {Object}
   */
  onSuccess(response, action, notification = {}, args = {}) {
    // save success state
    this.data.actionSucceeded = args.actionSucceeded ?? true;

    // show notification or not
    this.data.showNotification = args.showNotification ?? true;

    // success notification if requested
    if (this.data.showNotification) {
      // bind
      this.customEvents.notificationSuccess = getSuccessNotification(notification);

      // dispatch the notification event
      this.dom.engine.dispatchEvent(this.customEvents.notificationSuccess);
    }

    // if mode is add some settings must be changed to edit if you stay on the form
    if (action === 'add') {
      // change the mode to edit
      this.dom.form.querySelector('input[name="mode"]').value = 'edit';

      // save the pk value
      this.dom.form.querySelector('input[name="pk"]').value = response.data.form.activeRecordID;

      // edit handler instead of add handler
      const { handle } = this.dom.form.dataset;
      const newHandle = `${handle.replace('save', 'update')}/pk-${response.data.form.activeRecordID}`;
      this.dom.form.dataset.handle = newHandle;

      // release the widget form tab
      if (this.props.widgetform === 'true') {
        const widgetEl = document.getElementById('widgets-nav-tab');
        widgetEl.classList.remove('disabled');

        // toggle with jquery
        $('#widgets-nav-tab a').tab('show');

        // the settings form changes as well
        const settingsEl = document.getElementById(`form-${this.dom.form.dataset.formid}`);
        settingsEl.querySelector('input[name="mode"]').value = 'edit';
        settingsEl.querySelector('input[name="pk"]').value = response.data.form.activeRecordID;
        settingsEl.dataset.handle = newHandle;
        const refEl = settingsEl.querySelector('[data-pkref]');
        refEl.value = response.data.form.activeRecordID;
      }

      // dispatch the notification event
      this.customEvents.setReferencePK = new CustomEvent('setReferencePK', { detail: { pk: response.data.form.activeRecordID } });
      this.dom.engine.dispatchEvent(this.customEvents.setReferencePK);
    }

    // reset all errors on the active form
    this.resetErrors();
  }

  /**
   * request had failed
   * @param response {Object}
   * @param notification {Object}
   * @param args {Object}
   */
  onError(response, notification = {}, args = {}) {
    // save success state
    this.data.actionSucceeded = args.actionSucceeded ?? false;

    // show notification or not
    this.data.showNotification = args.showNotification ?? true;

    // success notification if requested
    if (this.data.showNotification) {
      // bind
      this.customEvents.notificationError = getErrorNotification(notification);

      // dispatch the notification event
      this.dom.engine.dispatchEvent(this.customEvents.notificationError);
    }

    // form is reloaded with action messages
    this.markErrorsOnForm(response);
  }

  /**
   * request had failed critically
   */
  onFail() {
    console.log('fail');
    // action did not succeed
    this.data.actionSucceeded = false;

    // don't show notification
    this.data.showNotification = false;

    // show critical alert
    getCritical();
  }

  /**
   * set errors on form
   * @param response {Object}
   * @param isModal {boolean}
   */
  markErrorsOnForm(response, isModal = false) {
    // reset all errors on the active form first
    this.resetErrors(isModal);

    // check if errors
    if (response.data.errors) {
      response.data.errors.forEach((obj) => {
        Object.entries(obj)
          .forEach(([key, value]) => {
            // remove the d-none for the element
            const src = document.querySelector(`[data-formfieldholderid="${key}"]`);

            // set error message visible
            let el = src.querySelector(`[data-formfieldholderid="${key}"] .help-block`);
            if (el) el.classList.remove('d-none');

            // set an has-error on the form group
            el = src.querySelector('.form-group');
            if (el) el.classList.add('has-error');

            // set red borders
            el = src.querySelector(`[data-formfieldid="${key}"]`);
            if (el) el.classList.add('is-invalid');

            // add the error message
            el = src.querySelector('.alert-message');
            if (el) el.textContent = value.message;
          });
      });

      if (!isModal) {
        // set the error badges
        if (this.dom.form.id.includes('widget')) {
          const badgeEl = document.querySelector('#widgetform-badge');
          if (badgeEl) badgeEl.innerHTML = `<span class="badge badge-danger rounded-pill ml-1">${response.data.errors.length}</span>`;
        } else {
          const badgeEl = document.querySelector('#form-badge');
          if (badgeEl) badgeEl.innerHTML = `<span class="badge badge-danger rounded-pill ml-1">${response.data.errors.length}</span>`;
        }
      }
    }
  }

  /**
   * function to reset all error messages
   *
   * @param isModal {boolean}
   */
  resetErrors(isModal = false) {
    // reset all errors on the active form first
    this.dom.form.querySelectorAll('.help-block').forEach((el) => el.classList.add('d-none'));
    this.dom.form.querySelectorAll('.has-error').forEach((el) => el.classList.remove('has-error'));
    this.dom.form.querySelectorAll('.is-invalid').forEach((el) => el.classList.remove('is-invalid'));

    // reset the error badges
    if (!isModal) {
      if (this.dom.form.id.includes('widget')) {
        const badgeEl = document.querySelector('#widgetform-badge');
        if (badgeEl) badgeEl.innerHTML = '';
      } else {
        const badgeEl = document.querySelector('#form-badge');
        if (badgeEl) badgeEl.innerHTML = '';
      }
    }
  }
}
