<template>
  <button
    :disabled="selectedFiles.length > 1"
    @click="openFile"
    class="dropdown-item"
    type="button">
    Bekijk bestand
  </button>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'datastore-btn-view-file',
    computed: {
      ...mapState({
        files: (state) => state.datastore.files,
        selectedFiles: (state) => state.datastore.selectedFiles,
      }),
    },
    methods: {
      // open selected file in a new tab
      openFile() {
        // no file(s) selected
        if (this.selectedFilesLength < 1) {
          return;
        }

        try {
          // try to open file
          const url = this.files.find((file) => file.fileUID === this.selectedFiles[0].fileUID).sourceUrl;
          window.open(url, '_blank', 'noopener,noreferrer');
        } catch {
          // file url not found
          this.$store.commit('addMessage', {
            title: 'Unable to open file url.',
            type: 'danger',
          });
        }
      },
    },
  };
</script>
