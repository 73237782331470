import datastoreOverview from './views/v10/datastore/overview/DatastoreOverview';
import datastoreDetail from './views/v10/datastore/detail/DatastoreDetail';
import widgetDetail from './views/v10/widget/detail/WidgetDetail';
import engine from './views/engine/Engine';

const views = {
  datastoreOverview,
  datastoreDetail,
  widgetDetail,
  engine,
};

export default views;
