<template>
  <div class="row mt-n4 mx-n1">
    <div class="col-auto mt-4 px-1" v-for="(skRow) in 7" :key="skRow">
      <skeleton-box width="192px" height="192px"/>
      <skeleton-box class="d-block mt-2 w-100" height="16px"/>
    </div>
  </div>
  <skeleton-box class="mt-4" width="217px" height="48px"/>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'file-upload-skeleton',
    components: {
      skeletonBox,
    },
  };
</script>

<style scoped lang="scss">
  .file-upload {
    margin-bottom: 0 !important;
  }

  .bi-arrows-move {
    bottom: 0;
    left: 0;
    cursor: move;
  }
</style>
