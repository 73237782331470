<template>
  <skeleton-box class="mb-2" height="64px"/>
  <div class="row mt-n2">
    <div class="col-6 col-sm-4 col-xl-3 mt-2" v-for="skItem in 7" :key="skItem">
      <div class="embed-responsive embed-responsive-1by1">
        <skeleton-box class="embed-responsive-item position-absolute h-100"/>
      </div>
    </div>
  </div>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'datastore-view-grid-skeleton',
    components: {
      skeletonBox,
    },
  };
</script>
