export default {
  data() {
    return {
      initialFormDataString: '',
    };
  },
  methods: {
    /**
     * Return all formData of given selector as string
     * @param selector
     * @returns {string}
     */
    getFormDataString(selector) {
      let str = '';

      document.querySelectorAll(selector)
        .forEach((form) => {
          str += JSON.stringify(Object.fromEntries(new FormData(form)));
        });

      return str;
    },

    /**
     * Set initialFormDataString
     * @param selector
     */
    setInitialFormDataString(selector) {
      this.formSelector = selector;
      this.initialFormDataString = this.getFormDataString(selector);
    },

    // Reset initial state on click
    onSave() {
      if (this.formSelector) {
        this.setInitialFormDataString(this.formSelector);
      }
    },

    /**
     * Window, the document and its resources are about to be unloaded.
     * Compare formData strings and warn user for unsaved changes if necessary
     * @param e {Event}
     * @returns {string|boolean}
     */
    onBeforeUnload(e) {
      // Must have selector
      if (!this.formSelector) return true;

      // Get form data as string
      const formDataString = this.getFormDataString(this.formSelector);

      console.log(this.initialFormDataString);

      // Compare forms data strings
      if (this.initialFormDataString !== formDataString) {
        // (!) note only fires after user interaction
        e.preventDefault();

        /**
         * needed for activation
         * custom message are no longer supported:
         * https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
         */
        // eslint-disable-next-line
        return event.returnValue = 'Are you sure you want to leave?';
      }

      // No changes
      return true;
    },
  },
  mounted() {
    // this.formSelector = null;
    // this.saveButtons = [...document.querySelectorAll('.js-save-form-and-return, .js-save-form-and-next, .js-save-form')];
    // this.saveButtons.forEach((btn) => {
    //   btn.addEventListener('click', this.onSave.bind(this));
    // });
    // window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
  },
  unmounted() {
    // this.saveButtons.forEach((btn) => {
    //   btn.removeEventListener('click', this.onSave.bind(this));
    // });
    //
    // window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
  },
};
