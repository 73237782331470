import Swal from 'sweetalert2';
import { getTranslation } from '@/utils/languages';

export function getCritical(alertObj = {}, cb = null) {
  let obj = alertObj;
  let callback = cb;

  if (obj.detail) obj = obj.detail;

  const defaults = {
    title: 'Whoops...',
    text: getTranslation('contact your webpartner'),
    icon: 'error',
    showCancelButton: false,
    buttonsStyling: false,
    confirmButtonText: 'Ok',
    customClass: {
      title: 'mb-3',
      content: 'lead text-muted mb-3',
      confirmButton: 'btn btn-lg btn-danger',
    },
  };
  const options = { ...defaults, ...obj };
  if (!callback) {
    callback = (result) => {
      if (result.isDismissed) {
        console.log('Dismissed: ', result.dismiss);
      }
    };
  }

  // fire swal alert
  Swal.fire(options).then(callback);
}

export default {
  getCritical,
};
