<template>
  <button class="swal" type="button" ref="swal" @click="show">
    <slot></slot>
  </button>
</template>

<script>
  import Swal from 'sweetalert2';

  export default {
    name: 'swal',
    props: {
      title: {
        type: String,
        required: false,
      },
      text: {
        type: String,
        required: false,
      },
      icon: {
        type: String,
        required: false,
      },
      options: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        defaultOptions: {},
      };
    },
    computed: {
      mergedOptions() {
        const shorthandOptions = {
          title: this.title,
          text: this.text,
          icon: this.icon,
        };
        // Merge defaultOptions with user defined options
        return {
          ...this.defaultOptions,
          ...shorthandOptions,
          ...this.options,
        };
      },
    },
    methods: {
      show() {
        Swal.fire(this.mergedOptions);
      },
    },
  };
</script>

<!--<style scoped lang="scss">-->
<!--@import "../../../scss/appwork/_custom-variables/libs";-->
<!--</style>-->
