import axios from 'axios';
import { root } from '@/utils/paths';
import { getTranslation } from '@/utils/languages';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import GridAction from '../gridAction';

export default class GridActionBtn {
  /**
   * delete an item
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.events = {
      handleBtnAction: this.handleBtnAction.bind(this),
    };
    this.props = {
      ...{
        ajaxParams: null,
        xhrAction: null,
        xhrUrl: null,
      },
      ...payload.props,
    };
    this.data = {
      formData: null,
      formDataReload: null,
    };
    this.customEvents = {
      notificationSuccess: null,
      notificationError: getErrorNotification(),
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.handleBtnAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.handleBtnAction);
  }

  /**
   * button action
   *
   * @param e {MouseEvent}
   */
  handleBtnAction(e) {
    // prevent default click
    e.preventDefault();
    const btn = e.target;

    // get the ajax params
    if (e.target.dataset.ajaxparams) {
      this.props.ajaxParams = JSON.parse(e.target.dataset.ajaxparams);
    }

    if (e.target.dataset.xhraction) {
      this.props.xhrAction = e.target.dataset.xhraction;
    }

    // in case of error
    if (this.props.xhrAction) {
      // pass the necessary data
      this.data.formData = new FormData();
      this.data.formData.append('gridID', this.props.gridId);
      // loop all parameters & add to xhr
      for (const [key, value] of Object.entries(this.props.ajaxParams)) {
        this.data.formData.append(key, value);
      }

      // check current class
      switch (this.props.xhrAction) {
        case 'fastaction-edit':
          this.fastAction('edit');
          // disable the sender
          btn.disabled = true;
          break;
        case 'fastaction-delete':
          this.fastAction('delete');
          // disable the sender
          btn.disabled = true;
          break;
        case 'fastaction-add':
          this.fastAction('add');
          // disable the sender
          btn.disabled = true;
          break;
        default:
          console.warn(`action ${this.props.xhrAction} not defined`);
          this.customEvents.notificationError = getErrorNotification({
            title: getTranslation('Er werd geen actie gekoppeld'),
            type: 'danger',
          });
          this.dom.engine.dispatchEvent(this.customEvents.notificationError);
          break;
      }
    } else {
      this.customEvents.notificationError = getErrorNotification({
        title: getTranslation('Er werd geen actie gekoppeld'),
        type: 'danger',
      });
      this.dom.engine.dispatchEvent(this.customEvents.notificationError);
    }
  }

  /**
   * glue fast action
   *
   * @param actionName {String}
   */
  fastAction(actionName) {
    // log
    console.log(`fast action ${actionName}`);

    // add the action manually
    this.data.formData.append('action', actionName);

    // make axios call
    axios({
      method: 'post',
      url: `${root}datagrids/add-fastaction`,
      data: this.data.formData,
    })
      .then((response) => {
        console.log(response);
        // change the icon
        // this.customEvents.notificationSuccess = getSuccessNotification(response.data.title);
        // this.dom.engine.dispatchEvent(this.customEvents.notificationSuccess);
      })
      .catch((error) => {
        console.log(error);
        this.dom.engine.dispatchEvent(this.customEvents.notificationError);
      })
      // reload overview
      .then(() => {
        this.data.formDataReload = new FormData();
        this.data.formDataReload.append('gridID', this.props.gridId);
        // loop all parameters & add to xhr
        for (const [key, value] of Object.entries(this.props.ajaxParams)) {
          this.data.formDataReload.append(key, value);
        }
        // reload the grid
        this.components.filterAction.applyFilter(this.data.formDataReload);
        this.unmount();
      });
  }
}
