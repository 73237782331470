import axios from 'axios';
import tinymce from 'tinymce/tinymce';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';

export default class FormActionSwitch {
  /**
   * open form in modal
   * @param form {Element}
   */
  constructor(form) {
    this.dom = {
      form,
      engine: document.getElementById('engine'),
      switchers: null, // [...form.querySelectorAll('.js-switch-lang')],
    };

    this.events = {
      onSwitchLanguage: this.onSwitchLanguage.bind(this),
    };

    this.customEvents = {
      notificationSuccess: null,
      notificationError: null,
      criticalError: getCritical,
    };

    this.props = {
      langId: null,
      formId: form.dataset.formid ?? 0,
      widgetform: form.dataset.widgetform ?? null,
      pk: null,
      detailId: null,
      widgetId: null,
    };

    this.mount();
  }

  mount() {
    // set pk
    if (typeof (this.dom.form.querySelector('input[name="pk"]') !== 'undefined') && this.dom.form.querySelector('input[name="pk"]') !== null) {
      this.props.pk = this.dom.form.querySelector('input[name="pk"]').value;
    }

    // select all switchers
    this.dom.switchers = [...this.dom.form.querySelectorAll('.js-switch-lang'), ...document.querySelectorAll('.js-switch-lang')];

    // listen on change
    this.dom.switchers.forEach((lang) => {
      lang.addEventListener('click', this.events.onSwitchLanguage);
    });
  }

  unmount() {
    // listen on change
    this.dom.switchers.forEach((lang) => {
      lang.removeEventListener('click', this.events.onSwitchLanguage);
    });
  }

  /**
   * on form submit
   * @param e {Event}
   */
  onSwitchLanguage(e) {
    console.log('lang switched');

    if (document.body.dataset.isSwitching) return;
    document.body.dataset.isSwitching = 'true';

    // prevent default action
    e.preventDefault();

    // change the active language
    const formData = new FormData();
    formData.append('editLanguageID', e.target.dataset.langto ?? 1);
    formData.append('formID', this.props.formId);
    formData.append('pk', this.props.pk);

    // widgets or not
    if (document.getElementById('js-active-widget-id-widget')) {
      this.props.widgetId = document.getElementById('js-active-widget-id-widget').value;
      this.props.detailId = document.getElementById('detailID').value;
      formData.append('widgetID', this.props.widgetId);
      formData.append('detailID', this.props.detailId);
    }

    // axios call
    axios({
      method: 'post',
      url: e.target.dataset.href,
      data: formData,
    })
      .then((response) => {
        if (response) {
          // load the translations if any
          this.loadTranslations(response);
        } else {
          // errors on the form
          this.onError(response, {
            title: response.data.errorMessage,
            type: 'danger',
          });
        }

        // disable the source fields
        this.disableNonTranslatableFields();
      })
      .catch((error) => {
        // error
        console.log(error);

        // show critical alert
        getCritical();
      })
      .then(() => {
        // reset switching
        delete document.body.dataset.isSwitching;
      });
  }

  /**
   * load the translations on the form
   * @param response {Object}
   */
  loadTranslations(response) {
    // check if any translations
    if (response.data.fieldsToTranslate) {
      for (const [form, fields] of Object.entries(response.data.fieldsToTranslate)) {
        console.log(`${form}:`);
        for (const [field, value] of Object.entries(fields)) {
          console.log(`${field}: ${value}`);
          const el = document.querySelector(`[data-formfieldid="${field}"]`);

          // check if tiny-mce field or not
          if (el.classList.contains('tiny-mce')) {
            tinymce.get(el.id)
              .setContent(value);
          } else {
            el.value = value;
          }
        }
      }

      this.unmount();

      // replace the changed language selector
      document.getElementById('js-formlang-selector').innerHTML = response.data.languageSelector;

      this.mount();
    }
  }

  /**
   * request had failed
   * @param response {Object}
   * @param notification {Object}
   * @param args {Object}
   */
  onError(response, notification = {}) {
    // bind
    this.customEvents.notificationError = getErrorNotification(notification);

    // dispatch the notification event
    this.dom.engine.dispatchEvent(this.customEvents.notificationError);
  }

  /**
   * function to disable all non translatable fields
   */
  disableNonTranslatableFields() {
    // reset all errors on the active form first
    this.dom.form.querySelectorAll('.source').forEach((el) => el.classList.add('disabled'));
  }
}
