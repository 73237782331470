<template>
  <template v-if="state === states.loading">
    <view-grid-skeleton/>
  </template>
  <template v-else>
    <div class="grid-view bg-white overflow-hidden mb-4">
      <div class="grid-view-heading font-weight-bold d-flex flex-wrap align-items-center">
        <label class="heading-item custom-control custom-checkbox flex-shrink-0 d-inline-block cursor-pointer mb-0 pr-4 py-3">
          <input type="checkbox"
                 class="custom-control-input"
                 v-model="isAllChecked"
                 @change="setAllCheckedState">
          <span class="custom-control-label">Selecteer alles</span>
        </label>

        <label class="custom-range d-flex align-items-center ml-auto flex-shrink-0 d-inline-block cursor-pointer mb-0 px-4 py-3">
          <span class="pr-3 d-inline-block">Weergave</span>
          <span class="ticks-wrapper d-block position-relative">
            <input type="range" class="form-control-range" :min="thumbRange.min" :max="thumbRange.max" :step="thumbRange.step" v-model.number="thumbSize">
            <span class="ticks d-flex justify-content-between w-100">
              <span class="tick" v-for="tick in thumbRangeTicks" :key="tick">{{ tick * thumbRange.step }}</span>
            </span>
          </span>
        </label>

      </div>
      <div class="grid-view-body d-flex flex-wrap mx-n2 mt-n4 p-4">
        <template v-for="file in filesFiltered" :key="file.fileUID">
          <grid-item
            v-if="file.noExtensionName.toLowerCase().startsWith(searchValue)"
            :file="file"
            :allow-edit="allowEdit"
            class="px-2 mt-4 mb-0"
            @checkChanged="updateIsAllChecked"
            :ref="setGridItemRef"/>
        </template>
        <div v-show="amountOfFilteredResults === 0" class="pt-4 my-4 text-center w-100">
          <p class="h4 text-muted mb-2">Geen resultaten gevonden</p>
          <p class="mb-0 text-light">Pas uw zoekopdracht aan om te vinden wat u zoekt.</p>
        </div>
      </div>
    </div>

    <!-- Show amount if we have results -->
    <p class="text-right mb-4 text-muted">{{ amountOfFilteredResults }} bestanden</p>
  </template>
</template>

<script>
  // store
  import { mapGetters, mapState } from 'vuex';

  // partials
  import viewGridSkeleton from '@/views/v10/datastore/overview/grid/GridSkeleton';
  import gridItem from './GridItem';

  export default {
    name: 'datastore-view-grid',
    props: {
      allowedExtensions: {
        type: Array, // ['jpg', 'png']
        required: false,
        default() {
          return [];
        },
      },
      allowEdit: {
        type: Boolean,
        required: false,
        default: true,
      },
      deselectFilesOnFolderChange: {
        type: Boolean,
        required: false,
        default() {
          return true;
        },
      },
    },
    data() {
      return {
        amountOfFilteredResults: 0,
        gridItemRefs: [],
        isAllChecked: false,
      };
    },
    computed: {
      filesFiltered() {
        let { files } = this;

        if (this.allowedExtensions.length > 0 && files) {
          files = this.files.filter((f) => this.allowedExtensions.includes(f.extension));
        }

        return files;
      },
      thumbRangeTicks() {
        return this.thumbRange.max / this.thumbRange.step;
      },
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
      thumbSize: {
        get() {
          return this.$store.state.datastore.thumbSize;
        },
        set(value) {
          this.$store.commit('datastore/setThumbSize', value);
        },
      },
      ...mapState({
        activeFolder: (state) => state.datastore.activeFolder,
        activeView: (state) => state.datastore.activeView,
        views: (state) => state.datastore.views,
        searchValue: (state) => state.datastore.searchValue,
        files: (state) => state.datastore.files,
        thumbRange: (state) => state.datastore.thumbRange,
        state: (state) => state.datastore.state,
        states: (state) => state.datastore.states,
      }),
      ...mapGetters({
        selectedFileIds: 'datastore/getSelectedFileIds',
      }),
    },
    components: {
      gridItem,
      viewGridSkeleton,
    },
    methods: {
      /**
       * Keep track of grid item component instances
       * @param gridItemComponent {Object} gridItem
       */
      setGridItemRef(gridItemComponent) {
        if (gridItemComponent) this.gridItemRefs.push(gridItemComponent);
      },

      // check if all items are checked
      async updateIsAllChecked() {
        // wait for dom update
        await this.$nextTick();

        this.isAllChecked = this.gridItemRefs.every((ref) => this.selectedFileIds.includes(ref.file.fileUID));
      },

      // check of uncheck all items
      setAllCheckedState() {
        if (this.isAllChecked) {
          this.selectAll();
        } else {
          this.deselectAll();
        }
      },

      // select all files
      async selectAll() {
        // wait for dom update
        await this.$nextTick();

        // select all grid items
        this.gridItemRefs.forEach((ref) => {
          if (!this.selectedFileIds.includes(ref.file.fileUID)) {
            this.$store.commit('datastore/addSelectedFile', ref.file);
          }
        });
      },

      // deselect all files
      async deselectAll() {
        // wait for dom update
        await this.$nextTick();

        // deselect all grid items
        this.gridItemRefs.forEach((ref) => {
          if (this.selectedFileIds.includes(ref.file.fileUID)) {
            this.$store.commit('datastore/removeSelectedFile', ref.file);
          }
        });
      },

      // reset filtered results
      setAmountOfFilteredResults() {
        this.amountOfFilteredResults = this.filesFiltered.length;
      },
    },
    created() {
      this.setAmountOfFilteredResults();
    },
    mounted() {
      this.updateIsAllChecked();
    },
    beforeUpdate() {
      this.gridItemRefs = [];
    },
    watch: {
      // filter search results by matching noExtensionName (starts with)
      searchValue() {
        if (this.activeView !== this.views.grid) return;
        this.amountOfFilteredResults = this.filesFiltered.filter((file) => file.noExtensionName
          .toLowerCase()
          .startsWith(this.searchValue))
          .length;

        this.updateIsAllChecked();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .grid-view {
    border-radius: $border-radius;
    box-shadow: 0 0.6px 0.9px rgba(0, 0, 0, 0.017),
    0 1.8px 2.5px rgba(0, 0, 0, 0.025),
    0 4.2px 6px rgba(0, 0, 0, 0.033),
    0 14px 20px rgba(0, 0, 0, 0.05);
  }

  .grid-view-heading {
    white-space: nowrap;
    background-color: $file-item-bg-active;
    border-color: $border-color;
    color: #6b7280;
    line-height: 1.46;
    box-shadow: 0 1px 0 0 #e5e7eb;
  }

  .custom-control-label {
    padding-left: $custom-control-gutter;

    &::before {
      top: 50%;
      left: $spacer * 1.5;
      transform: translateY(-50%);
    }
  }

  .heading-item {
    transition: 0.15s ease;

    &:hover {
      background-color: #cbd5e2;
    }
  }
</style>
