import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getSlaveData } from '@/api/engine/form';
import { getTranslation } from '@/utils/languages';

// custom script for form manage master/slave formFields

export default class MasterSlaveField {
  /**
   * change the type selector
   * @param select {Element}
   * @param payload {Object}
   */
  constructor(select, payload) {
    this.dom = {
      select,
      ...payload.dom,
    };

    this.props = {
      ...payload.props,
    };

    this.events = {
      onChange: this.onChange.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.select.addEventListener('change', this.events.onChange);
    console.log(this.dom.select.value);
  }

  // remove event listener
  unmount() {
    this.dom.select.removeEventListener('change', this.events.onChange);
  }

  /**
   * change the selectbox
   * @param e
   */
  // eslint-disable-next-line class-methods-use-this
  async onChange(e) {
    // select all slaves
    const slaves = [...document.querySelectorAll('[data-slave]')];
    let customCall = false;
    if (this.dom.select.hasAttribute('data-customcall')) customCall = this.dom.select.dataset.customcall;

    // prevent default click
    e.preventDefault();

    // fields
    const fieldsToUpdate = {};
    slaves.forEach((item) => {
      fieldsToUpdate[item.id] = item.dataset.formfieldid;
    });

    const formData = new FormData();
    formData.append('formID', this.props.formId);
    formData.append('fieldsToUpdate', JSON.stringify(fieldsToUpdate));
    formData.append('parentValue', e.target.value);
    formData.append('selectedTable', this.dom.select.value);
    formData.append('customCall', customCall);

    // load action settings
    await getSlaveData(formData)
      .then((response) => {
        // loop all slaves
        slaves.forEach((item) => {
          const elSlave = document.getElementById(item.id);
          const elSlaveIdx = elSlave.dataset.formfieldid;

          // remove all current elements
          while (elSlave.options.length > 0) {
            elSlave.remove(0);
          }

          // add "make a choice" option
          const firstOption = document.createElement('option');
          firstOption.value = '';
          firstOption.text = getTranslation('Make a choice');
          elSlave.appendChild(firstOption);

          // add the other entries
          for (const [key, value] of Object.entries(response.data.fields[elSlaveIdx])) {
            const option = document.createElement('option');
            option.value = key;
            option.text = value;
            elSlave.appendChild(option);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.dom.engine.dispatchEvent(getErrorNotification());
      });
  }
}

// this.masterSlave = function masterSlave() {
//   // define
//   let url = '';
//
//   // mixed options
//   const ajaxData = $(this)
//     .data('masterslaveoptions');
//   const formID = $(this)
//     .closest('form')
//     .find('input[name="formID"]')
//     .val();
//
//   // selected value
//   const selectedValue = $(this)
//     .val();
//
//   // add to the ajax options
//   ajaxData.formID = formID;
//   ajaxData.parentSelection = selectedValue;
//
//   // use default url or a custom
//   if (typeof ajaxData.action === 'undefined') {
//     url = `${root}ajax/formgenerator/get-slave`;
//   } else {
//     url = ajaxData.action;
//   }
//
//   // const slaves = ajaxData.slave;
//   // const rules = ajaxData.rule;
//
//   $.ajax({
//     type: 'POST',
//     dataType: 'json',
//     url,
//     data: { ajaxData },
//     success(reply) {
//       $.each(reply, (index, values) => {
//         // set the options blanc
//         $(`#${index}`)
//           .children()
//           .remove()
//           .end()
//           .append(`<option value="">${getTranslation('Make a choice')}</option>`);
//
//         // loop all selectors that must change
//         $.each(values, (key, value) => {
//           // append all values
//           $(`#${index}`)
//             .append(`<option value="${key}">${value}</option>`);
//         });
//       });
//     },
//   });
// };
