import axios from 'axios';
import $ from 'jquery';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getActionSettings } from '@/api/engine/grid';

export default class GridActionLoadModalForm {
  /**
   * open form in modal
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      modalFormContent: document.getElementById('modal-form-content'),
      formEl: null,
      $modalForm: $('#modal-form'),
    };
    this.props = {
      formId: null,
      pk: null,
      fk: null,
      xhrAction: null,
      callbackFkAsPk: null,
    };
    this.events = {
      loadModalForm: this.loadModalForm.bind(this),
      disposeModalForm: this.disposeModalForm.bind(this),
    };
    this.customEvents = {
      formBeforeReplace: null,
      formAfterReplace: null,
      notificationSuccess: null,
      notificationError: getErrorNotification(),
    };

    this.mount();
  }

  mount() {
    this.dom.action.addEventListener('click', this.events.loadModalForm);
    if (this.dom.action.dataset.callbackfkaspk) this.props.callbackFkAsPk = this.dom.action.dataset.callbackfkaspk;
  }

  unmount() {
    this.dom.action.removeEventListener('click', this.events.loadModalForm);
    this.dom.$modalForm.off('hidden.bs.modal');
  }

  /**
   *
   * @param e {MouseEvent}
   */
  async loadModalForm(e) {
    // don't do redirect
    e.preventDefault();

    // load the settings of the form
    const settingsData = new FormData();
    settingsData.append('actionID', e.target.dataset.actionid);

    // eslint-disable-next-line no-unused-vars
    const settings = await getActionSettings(settingsData)
      .then((response) => {
        this.props.formId = response.data.formId;
        this.props.pk = e.target.dataset.pk ?? null;
        this.props.fk = e.target.dataset.fk ?? null;
        this.props.xhrAction = response.data.xhrAction;
      })
      .catch(() => this.dom.engine.dispatchEvent(this.customEvents.notificationError));

    // build target url
    const url = `${root}common/formgenerator/form-as-modal`;

    // pass the required data
    const formData = new FormData();
    formData.append('actionID', e.target.dataset.actionid);
    formData.append('formID', this.props.formId);
    formData.append('xhrAction', this.props.xhrAction);
    if (this.props.pk != null && this.props.pk !== 'undefined') formData.append('pk', this.props.pk);
    if (this.props.fk != null && this.props.fk !== 'undefined') formData.append('fk', this.props.fk);
    if (this.props.callbackFkAsPk != null && this.props.callbackFkAsPk !== 'undefined') formData.append('callbackFKAsPK', this.props.callbackFkAsPk);

    // make axios call
    axios({
      method: 'post',
      url,
      data: formData,
    })
      .then((response) => {
        // listen to modal hidden event
        // this.dom.$modalForm.on('hide.bs.modal', this.events.disposeModalForm);

        // replace content
        this.dom.modalFormContent.innerHTML = response.data.htmlreply;

        // set formEl
        this.dom.formEl = this.dom.modalFormContent.querySelector('form');

        // create custom event
        this.customEvents.formAfterReplace = new CustomEvent('formAfterReplace', { detail: { id: this.dom.formEl.id } });

        // dispatch event
        this.dom.engine.dispatchEvent(this.customEvents.formAfterReplace);

        this.dom.formEl.addEventListener('onModalFormUnmount', this.events.disposeModalForm);

        // show modal
        this.dom.$modalForm.modal('show');
      })
      .catch(() => this.dom.engine.dispatchEvent(this.customEvents.notificationError));
  }

  // modal is no longer visible
  disposeModalForm() {
    // remove modal hidden listener
    // this.dom.$modalForm.off('hidden.bs.modal');

    // create custom event
    this.customEvents.formBeforeReplace = new CustomEvent('formBeforeReplace', { detail: { id: this.dom.formEl.id } });

    // dispatch event - remove form ref
    this.dom.engine.dispatchEvent(this.customEvents.formBeforeReplace);
  }
}
