import axios from 'axios';
import { root } from '@/utils/paths';

/**
 * get all widgets
 * @returns {Promise}
 */
export function getWidgets() {
  return axios({
    method: 'post',
    url: `${root}widgets/get-widgets`,
  });
}

/**
 * get widgets on page
 * @param formData {FormData}
 * @returns {Promise}
 */
export function getPageWidgets(formData) {
  return axios({
    method: 'post',
    url: `${root}widgets/get-related-widgets`,
    data: formData,
  });
}

/**
 * get widget form (details)
 * @param formData {FormData}
 * @returns {Promise}
 */
export function getWidgetForm(formData) {
  return axios({
    method: 'post',
    url: `${root}widgets/get-widget-details`,
    data: formData,
  });
}

/**
 * add widget to page
 * @param formData {FormData}
 * @returns {Promise}
 */
export function addWidgetToPage(formData) {
  return axios({
    method: 'post',
    url: `${root}widgets/add-widget-to-item`,
    data: formData,
  });
}

/**
 * delete widget from page
 * @param formData {FormData}
 * @returns {Promise}
 */
export function deleteWidgetFromPage(formData) {
  return axios({
    method: 'post',
    url: `${root}widgets/delete-widget-from-item`,
    data: formData,
  });
}

export default {
  getWidgets,
  getPageWidgets,
  getWidgetForm,
  addWidgetToPage,
  deleteWidgetFromPage,
};
