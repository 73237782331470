<template>
  <div
    class="context-menu"
    ref="tooltip"
    v-show="activeContextMenuId === uuid"
    tabindex="-1"
    v-click-away="close"
    @contextmenu.capture.prevent>
    <ul class="list-unstyled mb-0">
      <slot :contextData="contextData"></slot>
    </ul>
  </div>
</template>

<script>
  import { createPopper } from '@popperjs/core';
  import { mixin as VueClickAway } from 'vue3-click-away';

  let uuid = 0;

  export default {
    name: 'contextMenu',
    mixins: [VueClickAway],
    props: {
      boundariesElement: {
        type: String,
        default: 'body',
      },
    },
    computed: {
      activeContextMenuId: {
        get() {
          return this.$store.state.activeContextMenuId;
        },
        set(value) {
          this.$store.commit('setActiveContextMenuId', value);
        },
      },
    },
    data() {
      return {
        contextData: {},
        popper: null,
      };
    },
    methods: {
      open(e, contextData) {
        this.activeContextMenuId = this.uuid;
        this.contextData = contextData;

        if (this.popper) {
          this.popper.destroy();
        }

        this.popper = createPopper(this.referenceObject(e), this.$refs.tooltip, {
          placement: 'left-start',
          modifiers: {
            name: 'preventOverflow',
            options: {
              boundary: document.querySelector(this.boundariesElement),
            },
          },
        });

        // Recalculate position
        this.$nextTick(() => {
          this.popper.update();
        });
      },

      referenceObject(e) {
        return {
          getBoundingClientRect: () => ({
            top: e.pageY,
            right: e.pageX,
            bottom: e.pageY,
            left: e.pageX + this.$refs.tooltip.offsetWidth,
            width: 0,
            height: 0,
          }),
          clientWidth: 0,
          clientHeight: 0,
        };
      },

      close() {
        this.activeContextMenuId = '';
        this.contextData = null;
      },
    },
    beforeCreate() {
      this.uuid = uuid.toString();
      uuid += 1;
    },
    beforeMount() {
      if (this.popper) {
        this.popper.destroy();
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/include";

  .context-menu {
    position: fixed;
    padding-top: $dropdown-padding-y;
    padding-bottom: $dropdown-padding-y;
    overflow: hidden;
    box-shadow: $dropdown-box-shadow;
    border-radius: $dropdown-border-radius;
    background: $dropdown-bg;
    z-index: $zindex-dropdown;
  }
</style>
