import { getSuccessNotification, getErrorNotification } from '@/views/engine/utils/notifications';
import { executeAction } from '@/api/engine/grid';
import GridAction from '../gridAction';

export default class GridFastAction {
  /**
   * set an item on of off
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      ...{
        pk: this.dom.action.dataset.pk,
        table: this.dom.action.dataset.table,
        field: this.dom.action.dataset.field,
      },
      ...payload.props,
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };
    this.events = {
      onChange: this.onChange.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('change', this.events.onChange);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('change', this.events.onChange);
  }

  /**
   * change of a fast action
   *
   * @param e
   * @returns {Promise<void>}
   */
  async onChange(e) {
    // prevent default click
    e.preventDefault();

    // needed for end call
    this.data.formData = new FormData();

    const { method } = e.target.dataset;

    switch (method) {
      case 'bulk-actions':
        this.data.formData.append('gridID', this.props.gridId);
        this.data.formData.append('pk', this.props.pk);
        this.data.formData.append('action', 'bulk');
        this.data.formData.append('moduleName', 'datagrids');
        this.data.formData.append('method', 'add-fastaction');
        break;
      default:
        console.log(`${method} has no definition customOptions fastAction class`);
        // by default no options
        break;
    }

    // load action settings
    await executeAction(this.data.formData)
      .then(() => {
        // change check
        e.target.setAttribute('checked', !e.target.checked);
        // set custom options per method
        this.setCustomOptionsFastAction(e.target.dataset.method);
        // report success
        this.dom.engine.dispatchEvent(getSuccessNotification());
      })
      .catch((error) => {
        console.log(error);
        this.dom.engine.dispatchEvent(getErrorNotification());
      });
  }

  /**
   * set custom options for a method
   *
   * @param method {String}
   */
  async setCustomOptionsFastAction(method) {
    // check current class
    switch (method) {
      case 'bulk-actions':
        // reload the grid
        console.log('reload grid');
        await this.components.filterAction.applyFilter(this.data.formData);
        break;
      default:
        console.log(`${method} has no related customOptions in hrefAction class`);
        // by default no options
        break;
    }
  }
}
