<template>
  <div class="card position-relative"
       :class="{ 'mb-1': isCollapsed, 'mb-3': !isCollapsed }">
    <div class="card-body"
         :class="{ 'card-body--is-collapsed': isCollapsed }">
      <div class="row"
           :class="{ 'flex-row-reverse align-items-center': isCollapsed }">
        <div class="d-flex justify-content-end align-items-center flex-wrap ml-auto"
             :class="{ 'col-12': !isCollapsed, 'col-auto': isCollapsed }">
          <div class="dropdown">
            <button class="btn btn-sm icon-btn btn-outline-secondary borderless box-shadow-none"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              <i class="bi bi-gear-fill"></i>
              <span class="sr-only">Instellingen</span>
            </button>
            <div class="dropdown-menu text-14">
              <button class="dropdown-item"
                      type="button"
                      @click="toggleCollapse">
                <template v-if="isCollapsed">
                  <i class="bi bi-arrows-expand mr-1"></i>
                  Openklappen
                </template>
                <template v-else>
                  <i class="bi bi-arrows-collapse mr-1"></i>
                  Toeklappen
                </template>
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item"
                      type="button"
                      :disabled="disableAddNewItem"
                      @click="addItemAtIndex($event, 0)">
                <i class="bi bi-plus-circle mr-1"></i>
                Hierboven toevoegen
              </button>
              <button class="dropdown-item"
                      type="button"
                      :disabled="disableAddNewItem"
                      @click="addItemAtIndex($event,1)">
                <i class="bi bi-plus-circle mr-1"></i>
                Hieronder toevoegen
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item text-danger"
                      type="button"
                      @click="deleteItem">
                <i class="bi bi-x-circle mr-1"></i>
                Verwijderen
              </button>
            </div>
          </div>

          <i class="btn-sm p-0 ml-1 bi bi-arrows-move"></i>
        </div>
        <div class="col-auto pr-4">
          <matrix-file-upload-thumb
            :file="settings"
            :form-field-id="formFieldId"
            :restrictions="matrixField?.settings"
            :is-collapsed="isCollapsed"
            :thumb-size="thumbSize"
            :icon-font-size="iconFontSize"/>
        </div>
        <div class="col" v-if="!isCollapsed">
          <div class="row" v-html="html"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import matrixFileUploadThumb from '@/components/matrix/MatrixFileUploadThumb';

  export default {
    name: 'matrix-file-upload-item',
    emits: ['addMatrixItem'],
    props: {
      formFieldId: {
        type: String,
        required: true,
      },
      html: {
        type: String,
        required: true,
      },
      settings: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        thumbSizes: {
          lg: 192,
          sm: 48,
        },
        iconSizes: {
          lg: 48,
          sm: 24,
        },
        isLoading: true,
        isCollapsed: false,
      };
    },
    computed: {
      disableAddNewItem() {
        return this.matrixField.items?.length >= this.matrixField.settings?.maxMatrices;
      },
      thumbSize() {
        return this.isCollapsed ? this.thumbSizes.sm : this.thumbSizes.lg;
      },
      iconFontSize() {
        return this.isCollapsed ? this.iconSizes.sm : this.iconSizes.lg;
      },
      matrixField() {
        return this.matrixUploadFields[this.formFieldId];
      },
      nextUID() {
        return this.getNextMatrixFieldUID(this.formFieldId);
      },
      ...mapState({
        matrixUploadFields: (state) => state.engine.matrixUploadFields,
      }),
      ...mapGetters({
        getNextMatrixFieldUID: 'engine/getNextMatrixFieldUID',
      }),
    },
    components: {
      matrixFileUploadThumb,
    },
    methods: {
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      },

      /**
       * Add new item above or below current item
       * @param e {event}
       * @param modifier {number} // 0 = before, 1 = after
       */
      addItemAtIndex(e, modifier) {
        let index = this.matrixUploadFields[this.formFieldId]
          // get items of formField
          .items
          // map positions
          .map((item) => item.settings.position)
          // get index of current item
          .indexOf(this.settings.position);

        // target index (with 0 as floor)
        index = Math.max(0, index + modifier);

        // dispatch event
        this.$emit('addMatrixItem', e, index);
      },
      deleteItem() {
        this.$store.commit('engine/deleteMatrixUploadItems', {
          formFieldId: this.formFieldId,
          uids: [this.settings.uid],
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .card-body--is-collapsed {
    padding: 0.75rem;
  }

  .bi-arrows-move {
    cursor: move;
  }
</style>
