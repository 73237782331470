import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';

export function getXhrHtml(data) {
  return axios({
    method: 'post',
    url: data.get('url'),
    data,
  });
}

export function xhrLoad() {
  const xhrTriggers = [...document.querySelectorAll('.xhr')];

  xhrTriggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      const call = trigger.dataset.url;
      const replyEl = document.getElementById(`${trigger.dataset.replyto}-response`);

      // needed for end call
      const formData = new FormData();
      formData.append('xhr', true);
      formData.append('url', call);

      // set spinner
      replyEl.innerHTML = `<img src="${root}dist/img/loading.gif">`;

      getXhrHtml(formData)
        .then((response) => {
          console.log(response);
          replyEl.innerHTML = response.data.html;
        })
        .catch((error) => {
          console.log(error);
          this.dom.engine.dispatchEvent(getErrorNotification());
        });
    });
  });
}

export default {
  xhrLoad,
  getXhrHtml,
};
