<template>
  <div
    :class="{ disabled: files.length < 1 }"
    class="flex-fill position-relative mr-2">
    <input v-model.trim="searchModel"
           @input="setSearchValue(searchModel.toLowerCase());"
           class="form-control"
           type="text"
           placeholder="Zoeken..."
           ref="searchEl">
    <span class="d-inline-block icon-btn search-prepend position-absolute h-100 d-flex align-items-center justify-content-center disabled">
      <i class="bi bi-search text-16 text-dark"></i>
    </span>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'datastore-search',
    data() {
      return {
        searchModel: this.searchValue,
      };
    },
    computed: {
      ...mapState({
        activeFolder: (state) => state.datastore.activeFolder,
        files: (state) => state.datastore.files,
        searchValue: (state) => state.datastore.searchValue,
        activeView: (state) => state.datastore.activeView,
      }),
    },
    methods: {
      setSearchValue(value) {
        this.$store.commit('datastore/setSearchValue', value);
      },

      resetSearchValue() {
        this.searchModel = '';
        this.$store.commit('datastore/setSearchValue', this.searchModel);
      },
    },
    beforeUnmount() {
      this.resetSearchValue();
    },
    watch: {
      activeFolder() {
        this.$nextTick(() => {
          this.resetSearchValue();
        });
      },

      activeView() {
        this.$nextTick(() => {
          this.resetSearchValue();
        });
      },

      files: {
        deep: true,
        handler() {
          this.$nextTick(() => {
            this.resetSearchValue();
          });
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  input {
    padding-left: 2.5rem;
  }

  .search-prepend {
    top: 0;
    left: 0;
  }
</style>
