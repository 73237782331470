<template>
  <div v-if="editFile"
       v-click-away="onClickAway"
       ref="tooltip"
       class="edit-tooltip box-shadow-modal-md"
       role="tooltip">
    <div class="rounded-lg overflow-hidden">
      <div class="edit-tooltip-content position-relative z-2">
        <div class="img-wrapper d-flex align-items-center justify-content-center text-dark">
          <img v-if="editFile.thumb" class="img-fluid w-100 h-100 of-contain" :src="editFile.thumb" :alt="editFile.fileName">
          <i v-else :class="getExtensionIconClass(editFile.extension, true)"></i>
        </div>
        <form @submit.prevent="submit" class="bg-white">
          <div class="px-3 py-4">
            <table class="w-100">
              <tbody>
                <tr v-if="editFile.noExtensionName">
                  <th class="py-1 pr-3 form-label mb-0">Bestandsnaam</th>
                  <td class="py-1 w-100">
                    <input class="form-control" type="text" name="noExtensionName" v-model="modelFileName" @input="cleanFileName">
                    <small v-if="fileNameInvalidCharacter" class="d-block feedback text-secondary my-2">{{ fileNameInvalidCharacter }} is niet toegelaten.</small>
                  </td>
                </tr>
                <template v-if="editFile.translations">
                  <tr v-for="(value, name) in editFile.translations" :key="name">
                    <th class="py-1 pr-3 form-label mb-0 text-nowrap">Beschrijving {{ name.toUpperCase() }}</th>
                    <td class="py-1 w-100">
                      <input class="form-control" :name="'translations[' + name + ']'" :value="value">
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="edit-tooltip-actions text-right position-sticky bottom-0 p-3">
            <div class="mx-n2">
              <button @click="destroy(true);" class="btn btn-link text-body mx-2" type="button">Annuleren</button>
              <button class="btn btn-primary text-transform-none mx-2" type="submit">Opslaan</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="edit-tooltip-arrow z-1" data-popper-arrow></div>
  </div>
</template>

<script>
  import { nextTick } from 'vue';

  // vendor
  import { createPopper } from '@popperjs/core';

  // mixins
  import { mixin as VueClickAway } from 'vue3-click-away';
  import mixinRegex from '@/mixins/datastore/regex';
  import mixinFile from '@/mixins/file';

  export default {
    name: 'edit-tooltip',
    mixins: [VueClickAway, mixinFile, mixinRegex],
    data() {
      return {
        popper: null,
        popperOptions: {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            },
          ],
        },
      };
    },
    computed: {
      editFile: {
        get() {
          return this.$store.state.datastore.editFile;
        },
        set(value) {
          this.$store.commit('datastore/setEditFile', value);
        },
      },
      editRef: {
        get() {
          return this.$store.state.datastore.editRef;
        },
        set(value) {
          this.$store.commit('datastore/setEditRef', value);
        },
      },
    },
    methods: {
      // initialize popper
      async init() {
        // wait for DOM update
        await nextTick();

        this.modelFileName = this.editFile.noExtensionName;

        // create popper instance
        this.popper = createPopper(this.editRef, this.$refs.tooltip, this.popperOptions);
      },

      // destroy popper instance
      destroy(unsetRefs = false) {
        if (unsetRefs) {
          // remove references
          this.editFile = null;
          this.editRef = null;
        }

        // remove existing popper ref
        if (this.popper) {
          this.popper.destroy();
        }
      },

      /**
       * on form submit
       * @param e {Event}
       */
      submit(e) {
        const form = e.target;
        const formData = new FormData(form);

        // append fileId
        formData.append('fileID', this.editFile.fileUID);

        // send request
        this.$store.dispatch('datastore/editFile', formData);

        // close edit
        this.destroy(true);
      },

      // clicked outside target(s)
      onClickAway(e) {
        if (!e.target.classList.contains('edit-tooltip-area')) {
          this.destroy(true);
        }
      },
    },
    beforeUnmount() {
      this.destroy(true);
    },
    watch: {
      // watch for reference changes
      editRef() {
        if (this.editRef && this.editFile) {
          this.destroy();
          this.init();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  $edit-tooltip-width: 25em;
  $edit-tooltip-max-height: 50vh;
  $edit-tooltip-img-bg: $file-thumb-color;
  $edit-tooltip-arrow-size: 10px;
  $edit-tooltip-actions-bg: $body-bg;

  .edit-tooltip {
    position: absolute;
    width: $edit-tooltip-width;
    overflow: visible;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
    z-index: $zIndex-5;

    &[data-show] {
      display: block;
    }

    &[data-popper-placement^='top'] > .edit-tooltip-arrow {
      bottom: -$edit-tooltip-arrow-size * 0.5;
    }

    &[data-popper-placement^='bottom'] > .edit-tooltip-arrow {
      top: -$edit-tooltip-arrow-size* 0.5;

      &::before {
        background: $edit-tooltip-img-bg;
      }
    }

    &[data-popper-placement^='left'] > .edit-tooltip-arrow {
      right: -$edit-tooltip-arrow-size * 0.5;
    }

    &[data-popper-placement^='right'] > .edit-tooltip-arrow {
      left: -$edit-tooltip-arrow-size * 0.5;
    }

    input {
      transform: translateX(1px);
    }

    i {
      font-size: $edit-tooltip-width * 0.25;
    }

    .edit-tooltip-content {
      max-height: $edit-tooltip-max-height;
      overflow: auto;
    }

    .edit-tooltip-arrow,
    .edit-tooltip-arrow::before {
      position: absolute;
      width: $edit-tooltip-arrow-size;
      height: $edit-tooltip-arrow-size;
    }

    .edit-tooltip-arrow {
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

      &::before {
        content: '';
        transform: rotate(45deg);
        background: $edit-tooltip-actions-bg;
      }
    }

    .img-wrapper {
      height: calc(30em * 0.5);
      background: $edit-tooltip-img-bg;
    }

    .btn-primary {
      min-width: 50%;
    }

    .edit-tooltip-actions {
      background: $edit-tooltip-actions-bg;
    }
  }
</style>
