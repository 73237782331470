import { getTranslation } from '@/utils/languages';

/**
 * show error notification
 * @param notificationObj {Object}
 * @returns {CustomEvent}
 */
export function getErrorNotification(notificationObj = {}) {
  let obj = notificationObj;

  // check if custom event (detail is set)
  if (obj.detail) obj = obj.detail;

  const detail = {
    ...{
      title: getTranslation('something went wrong'),
      type: 'danger',
    },
    ...obj,
  };
  return new CustomEvent('notification', {
    detail,
  });
}

/**
 * show success notification
 * @param notificationObj {Object}
 * @returns {CustomEvent}
 */
export function getSuccessNotification(notificationObj = {}) {
  let obj = notificationObj;

  // check if custom event (detail is set)
  if (obj.detail) obj = obj.detail;

  const detail = {
    ...{
      title: getTranslation('success'),
      type: 'success',
    },
    ...obj,
  };

  return new CustomEvent('notification', {
    detail,
  });
}

export default {
  getErrorNotification,
  getSuccessNotification,
};
