<template>
  <skeleton-box class="skeleton-box mb-4" :height="height"/>
  <skeleton-box class="skeleton-box" :height="height"/>
  <skeleton-box class="skeleton-box mt-2" :height="height"/>
  <skeleton-box class="skeleton-box mt-2 w-75" :height="height"/>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'datastore-sidebar-skeleton',
    props: {
      height: {
        type: String,
        required: false,
        default: '32px',
      },
    },
    components: {
      skeletonBox,
    },
  };
</script>
