<template>
  <div class="row">
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info" :id="generateid" role="status" aria-live="polite">Totaal van <span id="ajaxReplace" class="text-primary">{{ totalRows }}</span> recordtjes
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination pagination-sm" data-paginationid="{{ id }}">
          <slot></slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  // import { root } from '@/utils/paths';
  // import Alert from '@/app/alert';
  // import { getTranslation } from '@/utils/languages';

  export default {
    name: 'paginator',
    props: {
      id: {
        type: String,
        required: true,
      },
      totalRows: {
        type: String,
        required: true,
      },
    },
    computed: {
      generateid() {
        return `js-pagination-${this.id}`;
      },
    },
    data() {
      return {
      };
    },
    methods: {
      dummy(value) {
        console.log(value);
      },
    },
    mounted() {
      // this.$root.$on('inserttest', (a) => {
      //   console.log(a);
      // });
    },
  };
</script>

<style scoped lang="scss">

</style>
