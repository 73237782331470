// [::1] is the IPv6 localhost address
const localDomains = ['localhost', '127.0.0.1', '[::1]'];

/**
 * Check if hostname is local or external
 * @param hostname {string}
 */
export function isLocal(hostname) {
  return (localDomains.includes(hostname) || !hostname.includes('.'));
}

/**
 * Get root URL
 * @returns {string}
 */
export function getRoot() {
  const { protocol, hostname, pathname } = window.location;
  let tempRoot = `${protocol}//${hostname}/`;

  if (isLocal(hostname)) {
    const segments = pathname.split('/');
    tempRoot += `${segments[1]}/`;
  }

  return tempRoot;
}

// Alias for getRoot();
export const root = getRoot();
export { root as default };
