import { getErrorNotification } from '@/views/engine/utils/notifications';
import axios from 'axios';
import { root } from '@/utils/paths';
import { getCritical } from '@/views/engine/utils/alerts';
import RandomAction from '@/views/engine/randomcomponent/randomAction';

export default class RandomFilterWhoNeedsHelp {
  /**
   * constructor for the RandomFilterHelpOnly class
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
    };
    this.props = {
      randomId: 'tasks',
      ajaxUrl: 'classmanagement/who-needs-help',
    };
    this.data = {
      formData: null,
    };
    this.components = {
      filterAction: new RandomAction(this.props.randomId, this.props.ajaxUrl),
    };
    this.events = {
      helpAction: this.whoNeedsHelp.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  mount() {
    this.dom.action.addEventListener('click', this.events.helpAction);
  }

  unmount() {
    this.dom.action.removeEventListener('click', this.events.helpAction);
  }

  /**
   * load tasks where help is required
   *
   * @param e {MouseEvent}
   */
  async whoNeedsHelp(e) {
    e.preventDefault();
    console.log('filter tasks with help requested...');

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('go', 'true');

    // apply the filter
    await this.components.filterAction.applyFilter(this.data.formData);

    // umount
    this.unmount();
  }
}
