export const fileStates = {
  files: [],
  selectedFiles: [],
  requireFileDeleteConfirm: true,
  fileDeleteModal: null,
  editFile: null,
  editRef: null,
  activeFile: null,
  maxUploadFiles: 20,
  maxUploadFilesize: 25,
  maxParallelUploads: 1, // 1 image per upload call
  thumbSize: 128,

  thumbRange: {
    min: 64,
    max: 256,
    step: 64,
  },
};

export default {
  fileStates,
};
