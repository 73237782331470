import axios from 'axios';
import { root } from '@/utils/paths';

/**
 * get the action settings from a grid action
 * @param data {FormData}
 * @returns {Promise}
 */
export function getActionSettings(data) {
  return axios({
    method: 'post',
    url: `${root}datagrids/get-action-settings`,
    data,
  });
}

/**
 * get the action settings from a grid action
 * @param data {FormData}
 * @returns {Promise}
 */
export function executeAction(data) {
  const moduleName = data.get('moduleName');
  const method = data.get('method');

  return axios({
    method: 'post',
    url: `${root}${moduleName}/${method}`,
    data,
  });
}

/**
 * get the settings of the grid related to a grid action
 * @param data {FormData}
 * @returns {Promise}
 */
export function getActionRelatedGridInfo(data) {
  return axios({
    method: 'post',
    url: `${root}datagrids/get-actionrelated-gridinfo`,
    data,
  });
}
export default {
  getActionSettings,
  getActionRelatedGridInfo,
  executeAction,
};
