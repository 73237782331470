import RandomAction from './randomAction';

export default class RandomFilterAction {
  /**
   * constructor for the RandomFilterAction class
   * @param payload {Object}
   */
  constructor(payload) {
    this.dom = {
      ...payload.dom,
    };
    this.props = {
      ...payload.props,
    };
    this.data = {
      ...payload.data,
    };
    this.components = {
      filterAction: new RandomAction(this.props.randomId, this.props.ajaxUrl),
    };
    this.events = {
      onSelectChange: this.onSelectChange.bind(this),
    };

    this.mount();
  }

  mount() {
    console.log('mount random filter action');

    this.dom.esFormEl.forEach((filter) => {
      switch (filter.tagName.toLowerCase()) {
        case 'select':
          filter.addEventListener('change', this.events.onSelectChange);
          break;
        default:
          break;
      }
    });
  }

  unmount() {
    console.log('unmount random es search');
    this.dom.esFormEl.forEach((filter) => {
      switch (filter.tagName.toLowerCase()) {
        case 'select':
          filter.removeEventListener('change', this.events.onSelectChange);
          break;
        default:
          break;
      }
    });

    // unset components
    this.components = null;
  }

  // change a select box
  async onSelectChange(e) {
    // get the selected values
    const selectedValues = [].slice.call(e.target.selectedOptions).map((a) => a.value);

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('randomID', this.props.randomId);
    this.data.formData.append('caller', e.target.name);
    if (e.target.dataset.behave) {
      this.data.formData.append('behavior', e.target.dataset.behave);
    } else {
      this.data.formData.append('behavior', 'default');
    }

    // send as array or as single element
    if (selectedValues.length > 1) {
      this.data.formData.append('filters[]', selectedValues);
    } else {
      this.data.formData.append('filters', selectedValues);
    }

    // apply the filter
    await this.components.filterAction.applyFilter(this.data.formData);

    // umount
    this.unmount();
  }
}
