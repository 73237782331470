// custom script for select formFieldTypeID (manage formFields)

export default class FormFieldTypesManager {
  /**
   * change the type selector
   * @param select {Element}
   */
  constructor(select) {
    this.dom = {
      select,
    };
    this.events = {
      onChange: this.onChange.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.select.addEventListener('change', this.events.onChange);

    const selected = this.dom.select.value;
    const container = document.getElementById('select-related-container');

    if (parseInt(selected, 10) !== 7) {
      if (container) {
        container
          .classList
          .add('d-none');
      }
    } else {
      container
        .classList
        .remove('d-none');
    }
  }

  // remove event listener
  unmount() {
    this.dom.select.removeEventListener('change', this.events.onChange);
  }

  /**
   * change the selectbox
   * @param e
   */
  // eslint-disable-next-line class-methods-use-this
  onChange(e) {
    const selected = e.target.value;

    if (parseInt(selected, 10) !== 7) {
      document.getElementById('select-related-container').classList.add('d-none');
    } else {
      document.getElementById('select-related-container').classList.remove('d-none');
    }
  }
}
