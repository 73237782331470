<template>
  <div :id="generateid" class="gridholder flex-basis-100">
    <slot></slot>
  </div>
</template>

<script>
  import { root } from '@/utils/paths';
  import Alert from '@/app/alert';

  export default {
    name: 'autoform',
    props: {
      id: {
        type: String,
        required: true,
      },
      url: {
        type: String,
        required: true,
      },
      params: {
        type: Object,
        required: true,
      },
    },
    computed: {
      generateid() {
        return `autogrid${this.id}`;
      },
    },
    data() {
      return {
        timer: null,
        searchValue: '',
      };
    },
    methods: {
      reloadGrid() {
        // add post parameters
        this.formData.append('gridid', this.dataGridId);
        this.formData.append('filterToLevel', this.levelValue);
        this.formData.append('filter', this.filterValue);

        // add each ajaxParameter as single post parameter
        Object.keys(this.params).forEach((key) => {
          this.formData.append(`${key}`, `${this.params[key]}`);
        });

        // define the area to replace
        this.elementToReplace = this.dataGrid.querySelector('.js-replace');
        this.paginationToReplace = this.dataGrid.querySelector(`#js-pagination-${this.dataGridId}`);
        this.paginationRowsToReplace = this.dataGrid.querySelector(`#js-pagination-rows-${this.dataGridId}`);

        // if no replace area is defined > exit
        if (!this.elementToReplace) {
          return;
        }

        // ajax fetch
        fetch(root + this.action, {
          method: this.method,
          body: this.formData,
        })
          .then((response) => {
            // async is alive
            if (!response.ok) {
              throw new Error('No response, file not found.');
            }
            return response.json();
          })
          .then((data) => {
            if (this.paginationToReplace && this.paginationRowsToReplace) {
              if (data.paginator) {
                this.paginationToReplace.innerHTML = data.paginator;
                this.paginationRowsToReplace.innerHTML = data.totalRows;
              } else {
                this.paginationToReplace.innerHTML = '';
                this.paginationRowsToReplace.innerHTML = '';
              }
            }

            // async is ready -> data is complete
            this.elementToReplace.innerHTML = data.overview;
          })
          .catch((error) => {
            // error occurred
            this.alert = new Alert({ text: error });
            this.alert.show();
          });
      },
      dummy(value) {
        console.log(value);
      },
    },
    mounted() {
      // this.$root.$on('inserttest', (a) => {
      //   console.log(a);
      // });
    },
  };
</script>

<style scoped lang="scss">

</style>
