<template>
  <modal :isModalDialogCentered="true"
         id="delete-thumbs-modal"
         ref="modal"
         @hidden="fileDeleteModalHidden">
    <template v-slot:header>
      <div class="modal-header justify-content-center text-center pb-4">
        <h5 class="modal-title mt-1">
          Bent u zeker dat u de huidige selectie wilt leegmaken?
          <small class="d-block text-muted">Actie kan niet ongedaan gemaakt worden.</small>
        </h5>
        <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body d-flex justify-content-center pt-4">
        <button type="button" class="btn btn-link text-dark px-0 mr-4 box-shadow-none" data-dismiss="modal">Annuleer</button>
        <button type="button" class="btn btn-danger btn-confirm-delete text-transform-none box-shadow-none" @click="confirm">Leegmaken</button>
      </div>
    </template>
  </modal>
</template>

<script>
  // components
  import modal from '@/components/modal/Modal';

  // mixins
  import mixinFormFieldFiles from '@/mixins/engine/form/formFieldsFiles';

  export default {
    name: 'file-upload-delete-thumbs-modal',
    mixins: [mixinFormFieldFiles],
    components: {
      modal,
    },
    computed: {
      thumbRefs: {
        get() {
          return this.$store.state.engine.thumbRefsToBeDeleted;
        },
        set(value) {
          this.$store.commit('engine/setThumbRefsToBeDeleted', value);
        },
      },
    },
    methods: {
      // File has been confirmed to be deleted
      confirm() {
        // delete thumbs
        this.deleteFormFieldFiles();
        // hide modal
        this.$refs.modal.hide();
      },

      // File delete modal has been hidden, clear refs
      fileDeleteModalHidden() {
        this.thumbRefs = [];
      },
    },
    mounted() {
      console.log('in modal');
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .close {
    top: 10px;
    right: 10px;
    line-height: 1;
    transform: none;
  }
</style>
