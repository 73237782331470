<template>
  <span
    :style="{ height, width: computedWidth, 'border-radius': borderRadius }"
    class="skeleton-box d-inline-block position-relative overflow-hidden">
  </span>
</template>

<script>
  export default {
    name: 'skeletonBox',
    props: {
      maxWidth: {
        default: 100,
        type: Number,
      },
      minWidth: {
        default: 80,
        type: Number,
      },
      height: {
        default: '1em',
        type: String,
      },
      width: {
        default: '100%',
        type: String,
      },
      borderRadius: {
        default: '.25rem',
        type: String,
      },
    },
    computed: {
      computedWidth() {
        return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .skeleton-box {
    vertical-align: middle;
    background-color: $file-item-bg-active;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%) skew(15deg);
      background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
      );
      animation: shimmer 3s infinite;
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%) skew(15deg);
      }
    }
  }
</style>
