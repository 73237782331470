/**
 * convert degrees to radians
 * @param degrees {number}
 * @returns {number}
 */
export function degreesToRadians(degrees) {
  return (degrees / 180) * Math.PI;
}

export default {
  degreesToRadians,
};
