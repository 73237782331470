<template>
  <div id="engine" ref="engine">
    <slot></slot>
    <div class="toasts-wrapper" id="toast-parent">
      <toast
        v-for="message in $root.messages"
        :title="message.title"
        :text="message.text"
        :type="message.type"
        :key="message.title"
        @destroy="$root.destroyToast(message)">
      </toast>
    </div>
  </div>
</template>

<script>
  import mixinBeforeUnload from '@/mixins/beforeUnload';
  import Grid from './grid/grid';
  import Form from './form/form';
  import QuickForm from './quickform/quickform';
  import RandomComponent from './randomcomponent/randomComponent';

  // mixins

  export default {
    name: 'engine',
    data() {
      return {
        grids: [],
        forms: [],
        quickForms: [],
        randomComponents: [],
      };
    },
    mixins: [mixinBeforeUnload],
    components: {},
    methods: {
      /**
       * before grid gets replaced
       * @param e {CustomEvent}
       */
      gridBeforeReplace(e) {
        console.log('gridBeforeReplace');
        const { gridId } = e.detail;

        // find grid instance
        let grid = this.grids.find((g) => g.props.gridId === String(gridId));

        // unbind all referenced
        if (grid) grid.unmount();

        // remove from instances
        this.grids = this.grids.filter((g) => g !== grid);

        // remove reference
        grid = null;
      },

      /**
       * after grid has been replaced
       * @param e {CustomEvent}
       */
      gridAfterReplace(e) {
        console.log('gridAfterReplace');
        const { gridId } = e.detail;
        const gridEl = document.querySelector(`.js-c-grid[data-gridid="${gridId}"]`);

        // add new grid instance
        this.addGrid(gridEl);
      },

      /**
       * before random component gets replaced
       * @param e {CustomEvent}
       */
      randomBeforeReplace(e) {
        console.log('randomBeforeReplace');
        const { randomId } = e.detail;

        // find grid instance
        let random = this.randomComponents.find((r) => r.props.randomId === String(randomId));

        // unbind all referenced
        if (random) random.unmount();

        // remove from instances
        this.randomComponents = this.randomComponents.filter((r) => r !== random);

        // remove reference
        random = null;
      },

      /**
       * after random component has been replaced
       * @param e {CustomEvent}
       */
      randomAfterReplace(e) {
        console.log('randomAfterReplace');
        const { randomId } = e.detail;
        const randomEl = document.querySelector(`.js-c-random[data-randomid="${randomId}"]`);

        // add new grid instance
        if (randomEl) this.addRandomEl(randomEl);
      },

      /**
       * before form gets replaced
       * @param e {CustomEvent}
       */
      formBeforeReplace(e) {
        console.log('formBeforeReplace');
        const { id } = e.detail;

        // find form instance
        let form = this.forms.find((f) => f.dom.formEl.id === String(id));

        // unbind all referenced
        if (form) form.unmount();

        // remove from instances
        this.forms = this.forms.filter((f) => f !== form);

        // remove reference
        form = null;
      },

      /**
       * after form has been replaced
       * @param e {CustomEvent}
       */
      formAfterReplace(e) {
        console.log('formAfterReplace');
        const { id } = e.detail;
        const formEl = document.getElementById(id);
        // add new form instance
        this.addForm(formEl);
      },

      /**
       * show bootstrap toast
       * @param messageObj {Object}
       */
      notification(messageObj) {
        this.$root.$store.commit('addMessage', messageObj.detail);
      },

      /**
       * add grid instance
       * @param gridEl {HTMLElement}
       */
      addGrid(gridEl) {
        const grid = new Grid(gridEl);
        this.grids.push(grid);
      },

      /**
       * add randomComponents instance
       * @param randomEl {HTMLElement}
       */
      addRandomEl(randomEl) {
        // make sure form is unique
        if (!this.randomComponents.find((f) => f.dom.randomComponents.id === randomEl.id)) {
          const randomCmp = new RandomComponent(randomEl);
          this.randomComponents.push(randomCmp);
        }
      },

      /**
       * add form instance
       * @param formEl {HTMLElement}
       */
      addForm(formEl) {
        // make sure form is unique
        if (!this.forms.find((f) => f.dom.formEl.id === formEl.id)) {
          const form = new Form(formEl);
          this.forms.push(form);

          this.formTrackChanges();
        }
      },

      /**
       * add quickForm instance
       * @param formEl {HTMLElement}
       */
      addQuickForm(formEl) {
        // make sure form is unique
        if (!this.forms.find((f) => f.dom.formEl.id === formEl.id)) {
          const form = new QuickForm(formEl);
          this.quickForms.push(form);
        }
      },

      // keep track of changes to forms
      formTrackChanges() {
        console.log('formTrackChanges');
        this.setInitialFormDataString('.js-c-form');
      },

      /**
       * manage the callback after post (reloading stuff)
       * @param e {CustomEvent}
       */
      onSaveAll(e) {
        console.log('Save all');

        e.preventDefault();
        const redirectUrl = e.currentTarget.dataset.ref;

        // this.forms.forEach((form) => form.components.post.events.onSubmit());
        // @todo remove setTimout
        this.forms.forEach((f, index) => {
          setTimeout(() => {
            const form = f;
            if (redirectUrl) form.data.redirectUrl = redirectUrl;
            form.dom.formEl.dispatchEvent(new Event('submit'));
          }, (1000 * index));
        });
      },
    },
    mounted() {
      // notification messaging
      this.$refs.engine.addEventListener('notification', this.notification);

      // grid events
      this.$refs.engine.addEventListener('gridBeforeReplace', this.gridBeforeReplace);
      this.$refs.engine.addEventListener('gridAfterReplace', this.gridAfterReplace);

      // random component events
      this.$refs.engine.addEventListener('randomBeforeReplace', this.randomBeforeReplace);
      this.$refs.engine.addEventListener('randomAfterReplace', this.randomAfterReplace);

      // form events
      this.$refs.engine.addEventListener('formBeforeReplace', this.formBeforeReplace);
      this.$refs.engine.addEventListener('formAfterReplace', this.formAfterReplace);
      this.$refs.engine.addEventListener('formTrackChanges', this.formTrackChanges);

      // add grid elements
      [...document.querySelectorAll('.js-c-grid')].forEach((gridEl) => this.addGrid(gridEl));

      // add form elements
      [...document.querySelectorAll('.js-c-form')].forEach((formEl) => this.addForm(formEl));

      // add quickForm elements
      [...document.querySelectorAll('.js-quick-form')].forEach((formEl) => this.addQuickForm(formEl));

      // add random components elements
      [...document.querySelectorAll('.js-c-random')].forEach((randomEl) => this.addRandomEl(randomEl));

      // add save all
      [...document.querySelectorAll('.js-save-all')].forEach((saveBtnEl) => {
        saveBtnEl.addEventListener('click', this.onSaveAll.bind(this));
      });
    },
    beforeUnmount() {
      // notification messaging
      this.$refs.engine.removeEventListener('notification', this.notification);

      // grid events
      this.$refs.engine.removeEventListener('gridBeforeReplace', this.gridBeforeReplace);
      this.$refs.engine.removeEventListener('gridAfterReplace', this.gridAfterReplace);

      // random component events
      this.$refs.engine.removeEventListener('randomBeforeReplace', this.randomBeforeReplace);
      this.$refs.engine.removeEventListener('randomAfterReplace', this.randomAfterReplace);

      // form events
      this.$refs.engine.removeEventListener('formBeforeReplace', this.formBeforeReplace);
      this.$refs.engine.removeEventListener('formAfterReplace', this.formAfterReplace);
    },
  };
</script>
