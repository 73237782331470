import axios from 'axios';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getTranslation } from '@/utils/languages';

/**
 * open form in modal
 * @param url {String}
 * @param data {Object}
 */
export default function reloadArea(url, data) {
  console.log('reloadArea...');

  // the reference area
  const areaId = data.areaId;
  const areaRefEl = document.getElementById(`autoarea-${areaId}`);

  const formData = new FormData();
  formData.append('gotoTab', areaRefEl.dataset.activetab ?? 1);

  // add all optional ajax params
  Object.keys(data)
    .forEach((key) => {
      formData.append(key, data[key]);
    });

  axios({
    method: 'post',
    url,
    data: formData,
  })
    .then((response) => {
      const engine = document.getElementById('engine');
      const areaEl = document.getElementById(`js-replace-area-${areaId}`);
      const randomId = 'tasks';
      const randomBeforeReplace = new CustomEvent('randomBeforeReplace', { detail: { randomId } });
      const randomAfterReplace = new CustomEvent('randomAfterReplace', { detail: { randomId } });

      // dispatch grid before replace event
      engine.dispatchEvent(randomBeforeReplace);

      // replace grid
      areaEl.innerHTML = response.data.overview;

      // dispatch grid after replace event
      engine.dispatchEvent(randomAfterReplace);
    })
    // call failed
    .catch(() => getErrorNotification({
      text: `${getTranslation('error')}`,
      type: 'danger',
    }));
}
