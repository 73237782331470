<template>
  <!-- Heading -->
  <heading></heading>

  <!-- Content -->
  <div class="row">
    <!-- Folders -->
    <aside class="col-md-4 col-xl-3 pr-xl-5">
      <sidebar/>
    </aside>

    <!-- Files -->
    <div class="col-md-8 col-xl-9 position-relative">
      <!-- Upload errors -->
      <div v-if="uploadErrors.length > 0 && state === states.ready" class="alert alert-warning alert-dismissible fade show">
        <button type="button" class="close py-1" data-dismiss="alert">×</button>
        <div class="font-weight-bold text-16 mb-2">De volgende bestanden konden niet worden geüpload.</div>
        <ul class="list-unstyled mb-0">
          <li v-for="(error, index) in uploadErrors" :key="error.fileName" :class="{'mt-2': index === 1}" class="text-14">
            <div class="font-weight-bold">{{ error.fileName }}</div>
            {{ error.message }}
          </li>
        </ul>
      </div>

      <!-- Folder has no files, show upload -->
      <div v-show="state === states.ready && activeFolder && (files.length < 1 || isDrag)">
        <dropzone
          :use-custom-slot=true
          :autoDiscover=false
          :options="{
              previewTemplate: 'dz-template',
              previewsContainer: '#previews',
              clickable: true,
              createImageThumbnails: true,
              uploadMultiple: true,
              maxFilesize: maxUploadFilesize,
              parallelUploads: maxParallelUploads,
             }"
          @sendingMultiple="uploadSendingMultiple"
          @successMultiple="uploadSuccessMultiple"
          @addedFile="dragCancel"
          @dragleave="dragCancel"
          role="form"
          :class="dropzoneClass"
          id="dropzone-dnd"
          ref="dz-dnd">
          <template v-slot:dz-message>
            <template v-if="isDrag">
              <div class="dz-message text-center d-flex align-items-center justify-content-center m-0 pointer-events-none">
                <div class="content">
                  <div class="text-center lead font-weight-light mw-50">
                    <p class="display-3 mb-4">Drop it like it's hot</p>
                    Sleep je bestanden hier om ze toe te voegen aan '<span class="font-weight-semibold">{{ activeFolder.folderName }}</span>'
                    <div class="d-block text-14 mt-1 text-center opacity-75">Max {{ maxUploadFiles }} bestanden en max bestandsgrootte van {{ maxUploadFilesize }}mb</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="dz-message needsclick text-center d-flex align-items-center justify-content-center m-0">
                <div class="content">
                  <i class="bi bi-folder d-block mx-auto display-1 mb-3"></i>
                  <div class="text-center text-16 font-weight-light">
                    Deze map is momenteel leeg. <br>
                    Sleep je bestanden hier of
                    <span class="text-primary text-underline font-weight-normal cursor-pointer">voeg bestanden toe.</span>
                    <div class="d-block text-light text-12 mt-5 text-center">Max {{ maxUploadFiles }} bestanden en max bestandsgrootte van {{ maxUploadFilesize }}mb</div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </dropzone>
      </div>

      <!-- Folder has files -->
      <div v-show="state === states.loading || files.length > 0">
        <!-- List view -->
        <view-list v-if="activeView === views.list" ref="viewList"/>

        <!-- Grid view -->
        <view-grid v-else ref="viewGrid"/>

        <!-- Quick edit -->
        <edit-tooltip ref="editTooltip"/>
      </div>
    </div>
  </div>

  <!-- Dropzone preview template -->
  <template-list></template-list>

  <!-- Folder delete modal -->
  <modal
    id="folder-delete-modal"
    size="sm"
    ref="folderDeleteModal"
    :isModalDialogCentered="true"
    @hidden="folderDeleteModalHidden"
  >
    <template v-slot:header>
      <div class="modal-header justify-content-center text-center pb-4">
        <h5 class="modal-title mt-1">
          Map
          <span v-if="folderDeleteModal?.name">&#32;'{{ folderDeleteModal.name }}'</span>
          &#32;verwijderen?
          <small class="d-block text-muted">Actie kan niet ongedaan gemaakt worden.</small>
        </h5>
        <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body d-flex justify-content-center pt-4">
        <button type="button" class="btn btn-link text-dark px-0 mr-4 box-shadow-none" data-dismiss="modal">Annuleer</button>
        <button type="button" class="btn btn-danger btn-confirm-delete text-transform-none box-shadow-none" @click="confirmDeleteFolder">Verwijder</button>
      </div>
    </template>
  </modal>

  <!-- File delete modal -->
  <file-delete-modal></file-delete-modal>
</template>

<script>
  // store
  import { mapState } from 'vuex';

  // components
  import dropzone from '@/components/dropzone/Dropzone';
  import templateList from '@/components/dropzone/TemplateList';
  import modal from '@/components/modal/Modal';

  // mixins
  import mixinDatastoreUpload from '@/mixins/datastore/upload';

  // partials
  import sidebar from './sidebar/Sidebar';
  import heading from './heading/Heading';
  import viewList from './list/List';
  import viewGrid from './grid/Grid';
  import fileDeleteModal from '../partials/FileDeleteModal';
  import editTooltip from '../partials/EditTooltip';

  export default {
    name: 'datastore-overview',
    mixins: [mixinDatastoreUpload],
    components: {
      sidebar,
      viewList,
      viewGrid,
      dropzone,
      templateList,
      modal,
      editTooltip,
      heading,
      fileDeleteModal,
    },
    computed: {
      dropzoneClass() {
        if (this.isDrag) {
          return 'dropzone dropzone-fs';
        }

        return 'dropzone dnd dz-clickable py-4 px-3 justify-content-center is-custom position-relative rounded d-flex align-items-center justify-content-center';
      },
      ...mapState({
        folderDeleteModal: (state) => state.datastore.folderDeleteModal,
      }),
    },
    methods: {
      // folder has been confirmed to be deleted
      confirmDeleteFolder() {
        this.$store.dispatch(
          'datastore/deleteFolder',
          {
            folderId: this.folderDeleteModal.folderId,
            parentId: this.folderDeleteModal.parentId,
          },
        );

        this.$refs.folderDeleteModal.hide();
      },

      /**
       * folder delete modal has been hidden
       * clear folderDeleteModal value in store
       */
      folderDeleteModalHidden() {
        this.$store.commit('datastore/setFolderDeleteModal', null);
      },
    },
    watch: {
      folderDeleteModal() {
        if (this.folderDeleteModal) {
          // show folder delete confirmation modal
          this.$refs.folderDeleteModal.show();
        }
      },
    },
    async created() {
      await this.$store.dispatch('datastore/getAllFolders');

      // get first folder
      const [firstFolder] = this.folders;
      // set first folder as active folder
      this.activeFolder = firstFolder;

      // Get files
      await this.$store.dispatch('datastore/getFilesFromFolder', {
        folderId: this.activeFolder.folderId,
      });

      // View list specific action
      if (this.activeView === this.views.list && this.activeFolder) this.$refs.viewList.buildRows();

      // All done
      this.isReady = true;
    },
    mounted() {
      this.isDrag = false;
      document.body.addEventListener('dragenter', this.dragEnter.bind(this));
    },
    beforeUnmount() {
      document.body.removeEventListener('dragover', this.dragEnter.bind(this));
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .close {
    top: 10px;
    right: 10px;
    line-height: 1;
    transform: none;
  }

  .bi-folder {
    color: #DBE2EB;
  }

  .dropzone.dnd {
    min-height: 60vh;
  }
</style>
