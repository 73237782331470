import { getErrorNotification } from '@/views/engine/utils/notifications';
import GridAction from '@/views/engine/grid/gridAction';
import { setSortingClassAndAttribute, getFilterRequestData } from './gridUtils';

export default class GridSorter {
  /**
   * Constructor for the GridSorter class
   * @param payload {Object}
   */
  constructor(payload) {
    this.dom = {
      ...{
        engine: document.getElementById('engine'),
        tbody: document.getElementById(`js-replace-autogrid-${payload.props.gridId}`),
      },
      ...payload.dom,
    };
    this.props = {
      ...{},
      ...payload.props,
    };
    this.data = {
      ...{
        formData: null,
        order: '',
      },
      ...payload.data,
    };
    this.events = {
      onSortClick: this.onSortClick.bind(this),
    };
    this.customEvents = {
      notification: getErrorNotification(),
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };

    this.mount();
  }

  /**
   * bind the click event on each pagination link
   */
  mount() {
    // set a sorting to each sort element on the page
    // add an event listener for the click event of the clear search button
    this.dom.gridSorterEl.addEventListener('click', this.events.onSortClick);
  }

  /**
   * bind the click event on each pagination link
   * necessary for document binding and keeping the actions work after ajax replace of the elements
   */
  unmount() {
    // set a pagination filter to each grid on the page
    // add an event listener for the click event of the clear search button
    this.dom.gridSorterEl.removeEventListener('click', this.events.onSortClick);
  }

  /**
   * function to go to the right page in the grids dataset
   * @param e {MouseEvent}
   */
  async onSortClick(e) {
    // don't take the native click event
    e.preventDefault();

    // manipulate ordering for next click
    const sortingOrder = setSortingClassAndAttribute(e.target);
    const orderBy = e.target.dataset.order;

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('gridID', this.props.gridId);
    this.data.formData.append('orderby', orderBy);
    this.data.formData.append('sort', sortingOrder);

    // add search filter
    if (this.dom.gridSearchFormEl) {
      this.data.formData.append('filter', getFilterRequestData(this.dom.gridSearchFormEl));
    }

    // apply the filter
    await this.components.filterAction.applyFilter(this.data.formData);

    // exec the filter action
    this.unmount();
  }
}
