/**
 * Takes an array of objects and returns an array with unique objects
 * @param arr {array}
 * @param key {string}
 * @returns {array}
 */
export function getUniqueArrayByKey(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

/**
 * Deep search for object within nested arrays
 * @param arr {array}
 * @param key {string}
 * @param value {any}
 * @param nestingKey {string}
 * @returns {object} { item: {object}, trail: {array} }
 */
export function findDeep(arr, key, value, nestingKey) {
  const trail = [];
  let found = false;

  function recurse(deepArr, deepKey, deepValue, deepNestingKey) {
    deepArr.forEach((item) => {
      trail.push(item);

      if (item[deepKey] === deepValue) {
        found = true;
        return item;
      }

      if (item[deepNestingKey] && !found) {
        return recurse(item[deepNestingKey], deepKey, deepValue, deepNestingKey);
      }

      trail.pop();
      return false;
    });
  }

  recurse(arr, key, value, nestingKey);

  if (found) {
    return {
      item: trail[trail.length - 1],
      trail,
    };
  }

  return false;
}

/**
 * remove object from deep nested array
 * @param arr {array}
 * @param findObj {object}
 * @param nestingKey {string}
 */
export function removeDeep(arr, findObj, nestingKey) {
  arr.forEach((item, index) => {
    const key = Object.keys(findObj)[0];
    const value = Object.values(findObj)[0];

    // found! - merge objects
    if (item[key] === value) {
      // eslint-disable-next-line no-param-reassign
      arr.splice(index, 1);
    }

    // check in children
    if (item[nestingKey] && item[nestingKey].length > 0) {
      removeDeep(item[nestingKey], findObj, nestingKey);
    }
  });
}

/**
 * Deep search for object within nested arrays
 * @param arr {array}
 * @param findObj {object}
 * @param replaceObj {object}
 * @param nestingKey {string}
 */
export function replaceDeep(arr, findObj, replaceObj, nestingKey) {
  arr.forEach((item, index) => {
    const key = Object.keys(findObj)[0];
    const value = Object.values(findObj)[0];

    // found! - merge objects
    if (item[key] === value) {
      // eslint-disable-next-line no-param-reassign
      arr[index] = {
        ...item,
        ...replaceObj,
      };
    }

    // check in children
    if (item[nestingKey] && item[nestingKey].length > 0) {
      replaceDeep(item[nestingKey], findObj, replaceObj, nestingKey);
    }
  });
}

export function sortDeep(arr, findObj, sortParam, nestingKey, sortType = 'ASC_ALPHABETIC') {
  arr.forEach((item, index) => {
    const key = Object.keys(findObj)[0];
    const value = Object.values(findObj)[0];

    // found! - sort array
    if (item[key] === value) {
      switch (sortType) {
        default:
          // alphabetic ascending
          arr[index][nestingKey].sort((a, b) => a[sortParam].localeCompare(b[sortParam]));
          break;
      }
    }

    // check in children
    if (item[nestingKey] && item[nestingKey].length > 0) {
      sortDeep(item[nestingKey], findObj, sortType, nestingKey);
    }
  });
}

export default {
  findDeep,
  removeDeep,
  replaceDeep,
  sortDeep,
  getUniqueArrayByKey,
};
