export default class RandomActionStatusRatebox {
  /**
   * finish a task
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      rateStatuses: [...action.querySelectorAll('.emoij')],
    };
    this.events = {
      clickAction: this.onClick.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    console.log('mount rate status...');

    if (this.dom.rateStatuses) {
      this.dom.rateStatuses.forEach((action) => {
        action.addEventListener('click', this.events.clickAction);
      });
    }
  }

  // remove event listener
  unmount() {
    if (this.dom.rateStatuses) {
      this.dom.rateStatuses.forEach((action) => {
        action.removeEventListener('click', this.events.clickAction);
      });
    }
  }

  /**
   * finish a task
   *
   * @param e {MouseEvent}
   */
  onClick(e) {
    // reset all
    this.dom.rateStatuses.forEach((action) => {
      const rateBox = action.querySelector('.rate-box');
      rateBox.classList.add('d-none');
    });

    const relatedForm = e.currentTarget.closest('form');
    const rateBox = e.currentTarget.querySelector('.rate-box');
    rateBox.classList.remove('d-none');
    relatedForm.querySelector('button[name="save"]').dataset[rateBox.dataset.update] = rateBox.dataset.rate;
  }
}
