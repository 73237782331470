<template>
  <div class="card-thumb" :class="{ 'allow-edit': allowEdit }">
    <div v-if="allowEdit" class="thumb d-flex align-items-center justify-content-center position-relative"
         ref="thumb"
         :style="'width: ' + thumbSize + 'px; height: ' + thumbSize + 'px;'">
      <img v-if="file.thumb"
           class="of-contain w-100 h-100"
           :src="file.thumb"
           :alt="file.noExtensionName"
           loading="lazy">
      <i v-else
         :class="getExtensionIconClass(file.extension, true)"
         :style="'font-size: ' + thumbSize * 0.5 + 'px;'">
      </i>
      <ul class="toggle-actions list-unstyled d-flex flex-wrap align-items-center justify-content-center mb-0 w-100 h-100">
        <li v-if="thumbSize > 64">
          <a class="btn p-0 border-0 box-shadow-none" :href="file.sourceUrl" target="_blank" rel="noreferrer noopener">
            <i class="far fa-eye" title="Open in nieuwe tab"></i>
          </a>
        </li>
        <li>
          <button class="btn p-0 border-0 box-shadow-none" @click="edit">
            <i class="far fa-edit edit-tooltip-area"></i>
          </button>
        </li>
        <li>
          <button class="btn p-0 border-0 box-shadow-none text-danger" @click="deleteFile">
            <i class="far fa-trash-alt"></i>
          </button>
        </li>
      </ul>
    </div>
    <div v-else
         class="thumb d-flex align-items-center justify-content-center position-relative cursor-pointer"
         ref="thumb"
         :style="'width: ' + thumbSize + 'px; height: ' + thumbSize + 'px;'"
         @click="$refs.checkbox.click()">
      <img v-if="file.thumb"
           class="of-contain w-100 h-100"
           :src="file.thumb"
           :alt="file.noExtensionName"
           loading="lazy">
      <i v-else
         :class="getExtensionIconClass(file.extension, true)"
         :style="'font-size: ' + thumbSize * 0.5 + 'px;'">
      </i>
    </div>
    <label class="cursor-pointer">
      <span class="d-block mt-2" :style="'width: ' + thumbSize + 'px;'">
        <span class="custom-control custom-checkbox flex-shrink-0 w-100 mb-0 text-dark">
          <input type="checkbox" class="custom-control-input" v-model="isChecked" @input="onInput" ref="checkbox">
          <span class="custom-control-label d-block w-100 text-truncate">{{ file.noExtensionName }}</span>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  // mixins
  import mixinFile from '@/mixins/file';
  import mixinDeleteFile from '@/mixins/datastore/deleteFile';

  export default {
    name: 'datastore-grid-item',
    mixins: [mixinFile, mixinDeleteFile],
    emits: ['checkChanged'],
    props: {
      file: {
        type: Object,
        required: true,
      },
      allowEdit: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        isChecked: false,
      };
    },
    computed: {
      editFile: {
        get() {
          return this.$store.state.datastore.editFile;
        },
        set(value) {
          this.$store.commit('datastore/setEditFile', value);
        },
      },
      editRef: {
        get() {
          return this.$store.state.datastore.editRef;
        },
        set(value) {
          this.$store.commit('datastore/setEditRef', value);
        },
      },
      ...mapState({
        thumbSize: (state) => state.datastore.thumbSize,
        selectedFiles: (state) => state.datastore.selectedFiles,
        activeView: (state) => state.datastore.activeView,
        views: (state) => state.datastore.views,
      }),
    },
    methods: {
      onInput() {
        if (this.$refs.checkbox.checked) {
          this.$store.commit('datastore/addSelectedFile', this.file);
        } else {
          this.$store.commit('datastore/removeSelectedFile', this.file);
        }

        this.$emit('checkChanged');
      },

      // set checked state
      setCheckedState() {
        this.isChecked = !!this.selectedFiles.some((file) => file.fileUID === this.file.fileUID);
      },

      // quick edit file
      edit() {
        if (this.editFile?.fileUID === this.file.fileUID) {
          // tooltip is already shown, close
          this.editFile = null;
          this.editRef = null;
        } else {
          // show tooltip
          this.editFile = this.file;
          this.editRef = this.$refs.thumb;
        }
      },
    },
    created() {
      this.setCheckedState();
    },
    watch: {
      selectedFiles: {
        deep: true,
        handler() {
          if (this.activeView !== this.views.grid) return;

          this.setCheckedState();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  $thumb-border-color: #e5e7eb;
  $checkbox-size: 18px;

  .card-thumb.allow-edit:hover .toggle-actions {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }

  .card-thumb:not(.allow-edit) img:hover {
    transition: opacity 0.25s;
  }

  .card-thumb:not(.allow-edit) img:hover {
    opacity: 0.75;
  }

  .thumb {
    border: 1px solid $thumb-border-color;
    border-radius: $border-radius;
    color: $file-thumb-color;

    &:hover {
      border-color: $primary;
    }
  }

  .custom-checkbox {
    width: $checkbox-size;
    height: $checkbox-size;
  }

  .toggle-actions {
    $gutter: 6px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: $zIndex-1;
    opacity: 0;
    pointer-events: all;
    transform: scale(1.15);
    backdrop-filter: blur(4px);
    background: rgba($white, 0.8);
    transition: $transition-base;

    > li {
      padding: $gutter;
    }
  }
</style>
