import GridAction from './gridAction';
import { getFilterRequestData } from './gridUtils';

export default class GridPaginator {
  /**
   * Constructor for the GridPaginator class
   * @param payload {Object}
   */
  constructor(payload) {
    this.dom = {
      ...{
        paginatorLinks: [...payload.dom.gridPaginatorEl.querySelectorAll('a')],
      },
      ...payload.dom,
    };
    this.props = {
      ...{},
      ...payload.props,
    };
    this.data = {
      ...{
        formData: null,
        activeTab: payload.dom.gridEl.dataset.tab,
      },
      ...payload.data,
    };
    this.events = {
      onPaginate: this.onPaginate.bind(this),
    };
    this.customEvents = {
      paginationUpdated: null,
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };

    this.mount();
  }

  /**
   * bind the click event on each pagination link
   */
  mount() {
    // add click events to links
    this.dom.paginatorLinks.forEach((link) => {
      link.addEventListener('click', this.events.onPaginate);
    });
  }

  unmount() {
    this.dom.paginatorLinks.forEach((link) => {
      link.removeEventListener('click', this.events.onPaginate);
    });

    this.components = null;
  }

  /**
   * function to go to the right page in the grids dataset
   * @param e {MouseEvent}
   */
  async onPaginate(e) {
    // don't take the native click event
    e.preventDefault();

    this.data.formData = new FormData();

    // init filter value
    this.props.activeTab = parseInt(e.target.dataset.tabid, 10);

    // pass the necessary data
    this.data.formData.append('gridID', this.props.gridId);
    this.data.formData.append('gotoTab', this.props.activeTab);

    // manipulate the data attribute on the grid
    this.dom.gridEl.dataset.activetab = this.props.activeTab;

    // add search filter
    if (this.dom.gridSearchFormEl) {
      this.data.formData.append('filter', getFilterRequestData(this.dom.gridSearchFormEl));
    }

    // exec the filter action
    await this.components.filterAction.applyFilter(this.data.formData);

    // set the updated activePage
    this.customEvents.paginationUpdated = new CustomEvent('paginationUpdated', { detail: this.props.activeTab });
    this.dom.gridEl.dispatchEvent(this.customEvents.paginationUpdated);

    // umount
    this.unmount();
  }
}
