<template>
  <button
    @click="switchViewTo(view)"
    :class="{ 'active pointer-events-none': activeView === view }"
    class="btn btn-outline-gray icon-btn d-flex align-items-center justify-content-center"
    type="button">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'datastore-btn-switch-view',
    props: {
      view: {
        type: String,
        required: true,
      },
    },
    computed: {
      activeView: {
        get() {
          return this.$store.state.datastore.activeView;
        },
        set(value) {
          this.$store.commit('datastore/setView', value);
        },
      },
    },
    methods: {
      /**
       * switch view
       * @param view {string}
       */
      switchViewTo(view) {
        if (this.activeView !== view) {
          this.activeView = view;
        }
      },
    },
  };
</script>
