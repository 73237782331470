export const folderStates = {
  ROOT_FOLDER_ID: '1',
  TEMP_ID_PREFIX: 'TMP-',
  folders: [],
  tempFolders: [],
  activeFolder: null,
  activeRenameFolderId: '',
  requireFolderDeleteConfirm: true,
  folderDeleteModal: null,
};

export default {
  folderStates,
};
