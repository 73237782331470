<template>
  <div class="row">
    <div class="col">
      <skeleton-box height="48px"/>
      <skeleton-box class="mt-2" height="48px"/>
      <skeleton-box class="mt-2" height="48px"/>
      <skeleton-box class="mt-2" height="128px"/>
    </div>

    <div class="col-md-4">
      <skeleton-box class="mb-3" height="48px"/>

      <div :class="{ 'mt-1': index > 0 }" v-for="(skItem, index) in 3" :key="skItem">
        <skeleton-box height="140px"/>
      </div>
    </div>
  </div>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'widget-detail-skeleton',
    components: {
      skeletonBox,
    },
  };
</script>
