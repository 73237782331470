export default class RevealItem {
  /**
   * delete an item
   * @param action {Element}
   * @param targetId {String}
   */
  constructor(action, targetId) {
    this.dom = {
      action,
      targetId,
      engine: document.getElementById('engine'),
    };
    this.events = {
      revealField: this.revealField.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.revealField);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.revealField);
  }

  /**
   *
   * @param e {MouseEvent}
   */
  async revealField(e) {
    // prevent default handle
    e.preventDefault();

    const target = this.dom.engine.querySelector(`[data-formfieldid="${this.dom.targetId}"]`);
    const inputType = target.getAttribute('type');
    console.log(inputType);
    if (inputType === 'text') {
      target.setAttribute('type', 'password');
    } else {
      target.setAttribute('type', 'text');
    }
  }
}
