// Add onHover event for dropdowns
import $ from 'jquery';

const SELECTOR = '[data-toggle=dropdown][data-trigger=hover]';
const TIMEOUT = 150;

function openDropdown($i) {
  let t = $i.data('dd-timeout');

  if (t) {
    clearTimeout(t);
    t = null;
    $i.data('dd-timeout', t);
  }

  if ($i.attr('aria-expanded') !== 'true') $i.dropdown('toggle');
}

function closeDropdown($i) {
  let t = $i.data('dd-timeout');

  if (t) clearTimeout(t);

  t = setTimeout(() => {
    let t2 = $i.data('dd-timeout');

    if (t2) {
      clearTimeout(t2);
      t2 = null;
      $i.data('dd-timeout', t2);
    }

    if ($i.attr('aria-expanded') === 'true') $i.dropdown('toggle');
  }, TIMEOUT);

  $i.data('dd-timeout', t);
}

$(() => {
  $('body')
    .on('mouseenter', `${SELECTOR}, ${SELECTOR} ~ .dropdown-menu`, function onMouseEnter() {
      // const $toggle = $(this).hasClass('dropdown-toggle') ? $(this) : $(this).prev('.dropdown-toggle');
      const $dropdown = $(this).hasClass('dropdown-menu') ? $(this) : $(this).next('.dropdown-menu');

      if (window.getComputedStyle($dropdown[0], null).getPropertyValue('position') === 'static') return;

      // Set hovered flag
      if ($(this).is(SELECTOR)) {
        $(this).data('hovered', true);
      }

      openDropdown(
        $(this).hasClass('dropdown-toggle') ? $(this) : $(this).prev('.dropdown-toggle'),
      );
    })
    .on('mouseleave', `${SELECTOR}, ${SELECTOR} ~ .dropdown-menu`, function onMouseLeave() {
      // const $toggle = $(this).hasClass('dropdown-toggle') ? $(this) : $(this).prev('.dropdown-toggle');
      const $dropdown = $(this).hasClass('dropdown-menu') ? $(this) : $(this).next('.dropdown-menu');

      if (window.getComputedStyle($dropdown[0], null).getPropertyValue('position') === 'static') return;

      // Remove hovered flag
      if ($(this).is(SELECTOR)) {
        $(this).data('hovered', false);
      }

      closeDropdown(
        $(this).hasClass('dropdown-toggle') ? $(this) : $(this).prev('.dropdown-toggle'),
      );
    })
    .on('hide.bs.dropdown', function hideDropdown(e) {
      if ($(this).find(SELECTOR).data('hovered')) e.preventDefault();
    });
});
