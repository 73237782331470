import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      activeFormField: (state) => state.engine.activeFormField,
    }),
  },
  methods: {
    deleteFormFieldFiles() {
      // store ids
      const fileIds = [];

      this.thumbRefs.forEach((thumbRef) => {
        // No-param reassign
        const thumbInstance = thumbRef;
        // Set state
        thumbInstance.isRemoving = true;
        // Add id to array
        fileIds.push(thumbInstance.file.fileUID);
      });

      // Delete form field file(s)
      this.$store.commit('engine/deleteFormFieldFiles', {
        id: this.activeFormField.pk,
        fileIds,
      });

      // reset activeFormField
      this.$store.commit('engine/setActiveFormField', null);
    },
  },
};
