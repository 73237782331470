<template>
  <div class="card" v-for="(skRow, index) in 2" :key="skRow" :class="{ 'mt-4': index > 0 }">
    <div class="card-body">
      <div class="row">
        <div class="file-upload col-auto pr-4">
          <skeleton-box width="192px" height="192px"/>
        </div>
        <div class="col">
          <div v-for="(skCol, index) in 3" :key="skCol" :class="{ 'mt-2': index > 0 }">
            <skeleton-box height="48px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <skeleton-box class="mt-4" width="217px" height="48px"/>
</template>

<script>
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  export default {
    name: 'matrix-file-upload-item-skeleton',
    components: {
      skeletonBox,
    },
  };
</script>

<style scoped lang="scss">
  .file-upload {
    margin-bottom: 0 !important;
  }

  .bi-arrows-move {
    bottom: 0;
    left: 0;
    cursor: move;
  }
</style>
