import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';
import GridAction from '../gridAction';

export default class GridActionChangePositionSelect {
  /**
   * change the position of an item
   * @param action {Element}
   * @param payload {Object}
   */
  constructor(action, payload) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      ...{
        pk: this.dom.action.dataset.pk,
        categoryid: this.dom.action.dataset.categoryid ?? 0,
        sourceposition: this.dom.action.dataset.sourceposition,
        targetposition: null,
      },
      ...payload.props,
    };
    this.events = {
      changeSelect: this.changeSelectItem.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };
    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('change', this.events.changeSelect);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('change', this.events.changeSelect);
  }

  /**
   * switch item on or off
   *
   * @param e {MouseEvent}
   */
  changeSelectItem(e) {
    // don't take the native click event
    e.preventDefault();

    // the new value
    this.props.targetposition = e.target.value;

    // check required parameters
    if (!this.props.pk || !this.props.targetposition) {
      // throw critical alert
      getCritical();
    } else {
      this.data.formData = new FormData();
      this.data.formData.append('gridID', this.props.gridId);
      this.data.formData.append('sourceposition', this.props.sourceposition);
      this.data.formData.append('targetposition', this.props.targetposition);
      this.data.formData.append('pk', this.props.pk);
      this.data.formData.append('categoryid', this.props.categoryid);

      // make axios call
      axios({
        method: 'post',
        url: `${root}common/change-position`,
        data: this.data.formData,
      })
        .then((response) => {
          if (!response.data.success) {
            // send error notification
            const notification = { title: response.data.errorMessage, type: 'danger' };
            this.customEvents.notificationError = getErrorNotification(notification);
            this.dom.engine.dispatchEvent(this.customEvents.notificationError);
            // throw critical alert
            // getCritical();
          }

          // some useful debug info
          if (response.data.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }
        })
        .catch(() => getCritical())

        // finally
        .then(() => {
          // pass the necessary data
          this.data.formData = new FormData();
          this.data.formData.append('gridID', this.props.gridId);

          // add all optional ajax params
          Object.keys(this.props.ajaxParams)
            .forEach((key) => {
              this.data.formData.append(key, this.props.ajaxParams[key]);
            });

          // apply the filter
          this.components.filterAction.applyFilter(this.data.formData);
          this.unmount();
        });
    }
  }
}
