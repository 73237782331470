import Sortable from 'sortablejs';

/**
 * set the right data-sort attribute
 */
export function setSortingClassAndAttribute(thElement) {
  const activeSortClass = thElement.dataset.orderSymbol;

  // init
  let newSymbolClass = '';
  let newSortClass = '';
  let sortingOrder = 'ASC';
  let newDataOrder = '';
  const removeClass = `gridjs-sort-${activeSortClass}`;
  let currentSortClass = activeSortClass;
  if (currentSortClass === 'neutral') currentSortClass = 'sorting';

  // sibling button thElement
  const symbol = thElement.parentNode.childNodes[1];
  const parent = thElement.parentNode;
  const allButtons = thElement.closest('.gridjs-tr').getElementsByTagName('button');
  const allOrders = thElement.closest('.gridjs-tr').getElementsByTagName('div');

  // set all btn to neutrol
  allButtons.forEach((btn) => {
    if (btn.classList.contains('gridjs-sort-asc')) {
      btn.classList.remove('gridjs-sort-asc');
    }
    if (btn.classList.contains('gridjs-sort-desc')) {
      btn.classList.remove('gridjs-sort-desc');
    }
    btn.classList.add('gridjs-sort-neutral');
  });

  // set all orders to neutrol
  allOrders.forEach((div) => {
    div.setAttribute('data-order-symbol', 'neutral');
  });

  // check current class
  switch (currentSortClass) {
    case 'sorting':
    case 'sort_desc':
      newSymbolClass = 'gridjs-sort-asc';
      newSortClass = 'sort_desc';
      sortingOrder = 'ASC';
      newDataOrder = 'sort_asc';
      break;
    case 'sort_asc':
      newSymbolClass = 'gridjs-sort-desc';
      newSortClass = 'sort_asc';
      sortingOrder = 'DESC';
      newDataOrder = 'sort_desc';
      break;
    default:
      break;
  }

  // change the attributes of the th
  if (thElement.classList.contains(currentSortClass)) {
    thElement.classList.remove(currentSortClass);
  }
  thElement.classList.add(newSortClass);
  thElement.setAttribute('data-order-symbol', newDataOrder);

  // change the attributes of the th
  if (parent.classList.contains(currentSortClass)) {
    parent.classList.remove(currentSortClass);
  }
  parent.classList.add(newSortClass);

  // remove old en set new symbol
  if (symbol.classList.contains(removeClass)) {
    symbol.classList.remove(removeClass);
  }
  symbol.classList.add(newSymbolClass);

  // return the sorting order
  return sortingOrder;
}

export function dragItem(el) {
  const sortOpts = el.dataset.options ? JSON.parse(el.dataset.options) : {};
  console.log(sortOpts);
  //
  // Object.keys(sortOpts)
  //   .forEach((values) => {
  //     const prefix = sortOpts[values].substring(0, 3);
  //
  //     if (prefix === 'fn.') {
  //       sortOpts[values] = sortOpts[values].replace(prefix, '');
  //     }
  //   });
  //
  // // make the grid sortable
  // initSortable(el, sortOpts);
}

export function initSortable(el, opts) {
  Sortable.create(el, opts);
}

/**
 * bulkSelect option

 * @param e {event}
 *
 */
export function bulkSelect(e) {
  [...document.querySelectorAll('.bulkaction')].forEach((el) => {
    if (e.target.checked) {
      el.setAttribute('checked', 'checked');
    } else {
      el.removeAttribute('checked');
    }
  });
}

/**
 * return grid filter search value
 * @param gridSearchFormEl {Element}
 * @returns {string}
 */
export function getFilterRequestData(gridSearchFormEl) {
  let filterRequest;
  const inputSearch = gridSearchFormEl.querySelector('.search-input');

  // add search filter
  const { value } = inputSearch;

  // check if filter is empty
  filterRequest = value;

  // show all if empty
  if (value === null || value === '') {
    filterRequest = '%';
  }

  return filterRequest;
}

export default {
  setSortingClassAndAttribute,
  initSortable,
  bulkSelect,
  getFilterRequestData,
};
