<template>
  <modal :isModalDialogCentered="true"
         id="matrix-items-delete-modal"
         ref="modal"
         @hidden="fileDeleteModalHidden">
    <template v-slot:header>
      <div class="modal-header justify-content-center text-center pb-4">
        <h5 class="modal-title mt-1">
          Bent u zeker dat u alle velden wilt verwijderen?
        </h5>
        <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body d-flex justify-content-center pt-4">
        <button type="button" class="btn btn-link text-dark px-0 mr-4 box-shadow-none" data-dismiss="modal">Annuleer</button>
        <button type="button" class="btn btn-danger btn-confirm-delete text-transform-none box-shadow-none" @click="confirm">Alles verwijderen</button>
      </div>
    </template>
  </modal>
</template>

<script>
  // components
  import { mapState } from 'vuex';
  import modal from '@/components/modal/Modal';

  export default {
    name: 'matrix-items-delete-modal',
    components: {
      modal,
    },
    computed: {
      matrixField() {
        return this.matrixUploadFields[this.matrixModalFormFieldId];
      },
      matrixModalFormFieldId: {
        get() {
          return this.$store.state.engine.matrixModalFormFieldId;
        },
        set(value) {
          this.$store.commit('engine/setMatrixModalFormFieldId', value);
        },
      },
      ...mapState({
        matrixUploadFields: (state) => state.engine.matrixUploadFields,
      }),
    },
    methods: {
      // File has been confirmed to be deleted
      confirm() {
        // delete items
        this.matrixField.items = [];
        // hide modal
        this.$refs.modal.hide();
      },

      // File delete modal has been hidden, clear refs
      fileDeleteModalHidden() {
        this.matrixModalFormFieldId = [];
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .close {
    top: 10px;
    right: 10px;
    line-height: 1;
    transform: none;
  }
</style>
