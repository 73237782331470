<template>
  <div class="modal" tabindex="-1" role="dialog" aria-hidden="true"
       :class="{ 'fade': hasFade, }"
       ref="el">
    <div
      :class="{
        'modal-dialog-scrollable': isModalDialogScrollable,
        'modal-dialog-centered': isModalDialogCentered,
        'modal-sm': size === 'sm',
        'modal-lg': size === 'lg',
        'modal-xl': size === 'xl',
      }"
      class="modal-dialog">
      <div class="modal-content">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/js/dist/modal';
import $ from 'jquery';

export default {
  name: 'modal',
  emits: [
    'show',
    'shown',
    'hide',
    'hidden',
    'hidePrevented',
  ],
  props: {
    isModalDialogScrollable: {
      type: Boolean,
      default: false,
      required: false,
    },
    isModalDialogCentered: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasFade: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: '',
      required: false,
    },
    // https://getbootstrap.com/docs/4.6/components/modal/#options
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    mergedOptions() {
      // Merge defaultOptions with user defined options
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
  data() {
    return {
      defaultOptions: {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
      },
    };
  },
  methods: {
    /**
     * manually toggles a modal. Returns to the caller before the modal has actually been shown or hidden
     * (i.e. before the shown.bs.modal or hidden.bs.modal event occurs).
     * https://getbootstrap.com/docs/4.6/components/modal/#modaltoggle
     */
    toggle() {
      $(this.$el)
        .modal('toggle');
    },
    /**
     * manually opens a modal. Returns to the caller before the modal has actually been shown
     * (i.e. before the shown.bs.modal event occurs).
     * https://getbootstrap.com/docs/4.6/components/modal/#modalshow
     */
    show() {
      $(this.$el)
        .modal('show');
    },
    /**
     * manually hides a modal. Returns to the caller before the modal has actually been hidden
     * (i.e. before the hidden.bs.modal event occurs).
     * https://getbootstrap.com/docs/4.6/components/modal/#modalhide
     */
    hide() {
      $(this.$el)
        .modal('hide');
    },
    /**
     * manually readjust the modal’s position if the height of a modal changes while it is open
     * (i.e. in case a scrollbar appears).
     * https://getbootstrap.com/docs/4.6/components/modal/#modalhandleupdate
     */
    handleUpdate() {
      $(this.$el)
        .modal('handleUpdate');
    },
    /**
     * destroys an element’s modal
     * https://getbootstrap.com/docs/4.6/components/modal/#modaldispose
     */
    dispose() {
      $(this.$el)
        .modal('dispose');
    },
    /**
     * This event fires immediately when the show instance method is called.
     * If caused by a click, the clicked element is available as the relatedTarget property of the event.
     */
    onShow() {
      this.$emit('show');
    },
    /**
     This event is fired when the modal has been made visible to the user
     (will wait for CSS transitions to complete).
     If caused by a click, the clicked element is available as the relatedTarget property of the event.
     */
    onShown() {
      this.$emit('shown');
    },
    /*
    this event is fired immediately when the hide instance method has been called.
     */
    onHide() {
      this.$emit('hide');
    },
    /*
    this event is fired when the modal has finished being hidden from the user
    (will wait for CSS transitions to complete).
     */
    onHidden() {
      this.$emit('hidden');
    },
    /**
     *This event is fired when the modal is shown,
     * its backdrop is static and a click outside the modal or an escape key press is
     * performed with the keyboard option or data-keyboard set to false.
     */
    onHidePrevented() {
      this.$emit('hidePrevented');
    },
  },
  mounted() {
    // init
    $(this.$el)
      .modal(this.mergedOptions);

    // listen to events
    $(this.$el)
      .on('show.bs.modal', this.onShow);
    $(this.$el)
      .on('shown.bs.modal', this.onShown);
    $(this.$el)
      .on('hide.bs.modal', this.onHide);
    $(this.$el)
      .on('hidden.bs.modal', this.onHidden);
    $(this.$el)
      .on('hidePrevented.bs.modal', this.onHidePrevented);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/appwork/_appwork/include";
@import "~@/assets/scss/abstract/_timing_functions.scss";
</style>
