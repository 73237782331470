export const gridMutations = {
  /**
   * set active GridId
   * @param state
   * @param id {String}
   */
  setActiveGridId(state, id) {
    console.log('engine/setActiveGridId');
    state.activeGridId = id;
  },
};

export default {
  gridMutations,
};
