<template>
  <button class="card h-100 p-0" type="button" :title="description" @click="onClick">
    <img class="img-fluid card-img-top flex-grow-1" :src="thumb" :alt="name">
    <span class="card-footer d-block w-100 text-left position-relative">
      <small class="text-muted pr-4">{{ name }}</small>
      <span class="btn btn-icon d-flex align-items-center justify-content-center btn-primary bg-primary rounded-circle p-0">
        <i class="fas fa-plus"></i>
      </span>
    </span>
  </button>
</template>
<script>
import { mapState } from 'vuex';
import mixinEditWidget from '@/mixins/widget/editWidget';
import { root } from '@/utils/paths';

export default {
  name: 'widget-add',
  props: {
    thumb: {
      default: `${root}dist/img/noimage_50.png`,
    },
    widgetId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      formId: (state) => state.widgets.formId,
      recordId: (state) => state.widgets.recordId,
      detailId: (state) => state.widgets.activeDetailId,
    }),
  },
  mixins: [mixinEditWidget],
  emits: ['onComplete'],
  methods: {
    async onClick() {
      // build request data
      const formData = new FormData();
      formData.append('formID', this.formId);
      formData.append('recordID', this.recordId);
      formData.append('detailID', this.detailId);
      formData.append('widgetID', this.widgetId);

      // send request
      const widget = await this.$store.dispatch('widgets/addWidgetToPage', formData);

      // done
      this.editWidget(widget.recordDetailID, widget.widgetID);
      this.$emit('onComplete');
    },
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/appwork/_appwork/_include.scss";

$icon-btn-size: 24px;
$icon-btn-offset: 0.75rem;

button {
  transition: $transition-base;
}

button:focus,
button:hover {
  border-color: $primary;
  background: rgba(24, 28, 33, 0.03);
}

button:not(:focus):not(:hover) {
  .btn-icon {
    transform: translateX($icon-btn-offset) translateY(-50%);
    opacity: 0;
    pointer-events: none;
    user-focus: none;
  }
}

.btn-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $icon-btn-offset;
  width: $icon-btn-size;
  height: $icon-btn-size;
  font-size: 10px;
}
</style>
