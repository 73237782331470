import axios from 'axios';
import { root } from '@/utils/paths';

/**
 * get regex
 * @param data {FormData}
 * @returns {Promise}
 */
export function getRegex(data) {
  return axios({
    method: 'post',
    url: `${root}common/get-regex`,
    data,
  });
}

/**
 * set global filter value
 * @param data {FormData}
 * @returns {Promise}
 */
export function udpateGlobalFilterSettings(data) {
  return axios({
    method: 'post',
    url: `${root}common/update-globalfilter-settings`,
    data,
  });
}

export default {
  getRegex,
  udpateGlobalFilterSettings,
};
