<template>
  <div class="thumb-wrapper position-relative overflow-hidden rounded">
    <button class="thumb btn-reset d-flex flex-column align-items-center justify-content-center position-relative"
            :style="`width: ${thumbSize}px; height: ${thumbSize}px;`"
            type="button"
            ref="thumb"
            @click="onThumbClick">
      <img v-if="file?.thumb"
           class="of-contain img-fluid"
           :src="file?.thumb"
           :width="thumbSize"
           :height="thumbSize"
           alt="Thumbnail"
           loading="lazy">
      <template v-else>
        <i class="bi bi-image" :style="`font-size: ${iconFontSize}px`"></i>
        <div v-if="restrictions && restrictions?.btnUploadText && !isCollapsed"
             class="placeholder-text text-truncate mt-2 text-13">
          {{ restrictions.btnUploadText }}
        </div>
      </template>
    </button>

    <div v-show="!isCollapsed && file?.thumb">
      <button class="btn btn-delete p-2 border-0 box-shadow-none d-flex align-items-center justify-content-center"
              type="button"
              :disabled="isRemoving"
              @click="deleteThumb">
        <i v-if="isRemoving" class="spinner d-inline-block"></i>
        <i v-else class="far fa-trash-alt"></i>
      </button>
    </div>
  </div>
  <input :name="`FileID[${formFieldId}][]`" type="hidden" :value="file.fileUID">
  <input :name="`rel-drset[${formFieldId}]`" type="hidden" :value="`${restrictions?.RelateTo}`">
</template>

<script>
  import $ from 'jquery';

  export default {
    name: 'matrix-file-upload-thumb',
    props: {
      file: {
        type: Object,
        required: true,
      },
      formFieldId: {
        type: String,
        required: true,
      },
      restrictions: {
        type: Object,
        required: false,
      },
      isCollapsed: {
        type: Boolean,
        required: true,
        default: false,
      },
      thumbSize: {
        type: Number,
        required: false,
        default: 192, // in px
      },
      iconFontSize: {
        type: Number,
        required: false,
        default: 48, // in px
      },
    },
    data() {
      return {
        isRemoving: false,
      };
    },
    methods: {
      onThumbClick() {
        const files = [];

        // add file if we have any
        if (this.file.fileUID) files.push(this.file);

        // set upload modal settings
        this.$store.commit('engine/setUploadModalSettings', {
          files,
          restrictions: this.restrictions,
          formFieldId: this.formFieldId,
          slideId: this.file.uid,
          action: 'engine/updateMatrixUploadThumb',
        });

        // show modal
        $('#modal-library')
          .modal('show');
      },
      deleteThumb() {
        // disable remove
        this.isRemoving = true;

        // delete thumb
        this.$store.commit('engine/updateMatrixUploadThumb', {
          files: [],
          slideId: this.file.uid,
          formFieldId: this.formFieldId,
        });

        // enable remove
        this.isRemoving = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  $thumb-border-color: #e5e7eb;

  .thumb-wrapper {
    border: 1px solid $thumb-border-color;
  }

  .thumb {
    color: $file-thumb-color;
    transition: none;

    &:hover {
      border-color: $input-border-color;

      .bi-image {
        color: $secondary;
      }

      .placeholder-text {
        color: $body-color;
      }
    }
  }

  .placeholder-text {
    color: $secondary;
  }

  .btn-delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zIndex-1;

    &,
    &:hover,
    &:focus {
      background-color: $white;
    }

    &:hover,
    &:focus {
      color: $danger;
    }
  }
</style>
