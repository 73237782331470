<template>
  <div class="card card--widget position-relative"
       :class="{ active: detailId === activeDetailId }"
       ref="card">
    <button class="card-body d-flex border-0 bg-transparent p-3"
            type="button"
            :title="`${description} bewerken`"
            @click="editWidget(detailId, widgetId)">
      <img class="img-fluid flex-shrink-0" :src="thumb" :alt="description" :width="thumbWidth">
    </button>

    <ul class="nav flex-column position-absolute mb-0 py-3 px-2">
      <li class="nav-item">
        <div class="dropdown" ref="dropdown">
          <button class="btn btn-action rounded-circle text-body"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </div>
      </li>

      <li class="nav-item mt-auto">
        <button class="btn btn-action rounded-circle text-danger"
                type="button"
                @click="deleteWidget">
          <i class="far fa-trash-alt"></i>
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import $ from 'jquery';
import mixinEditWidget from '@/mixins/widget/editWidget';
import { root } from '@/utils/paths';

export default {
  name: 'widget-detail-thumb',
  props: {
    thumb: {
      default: `${root}dist/img/noimage_50.png`,
    },
    widgetId: {
      type: String,
      required: true,
    },
    detailId: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      formId: (state) => state.widgets.formId,
      activeDetailId: (state) => state.widgets.activeDetailId,
      pageWidgets: (state) => state.widgets.pageWidgets,
    }),
    ...mapGetters({
      totalPageWidgets: 'widgets/totalPageWidgets',
      activePageWidget: 'widgets/activePageWidget',
    }),
  },
  mixins: [mixinEditWidget],
  data() {
    return {
      thumbWidth: 150, // in px
      dropdownZIndexValue: 100,
    };
  },
  methods: {
    async deleteWidget() {
      const activeRecordId = this.activePageWidget.recordDetailID;
      const newLength = this.totalPageWidgets - 1;
      // check if we need to change active widget
      const changeActiveWidget = (activeRecordId === this.detailId && newLength > 0);
      // get index for next widget in line
      let index = this.pageWidgets.indexOf(this.activePageWidget);

      if (index === 0) {
        // first item is deleted, set next item as active
        index = 1;
      } else {
        // set previous widget as active
        index -= 1;
      }

      // set next widget in line in case active got deleted
      const newActiveWidget = this.pageWidgets[index];

      // build request params
      const formData = new FormData();
      formData.append('formID', this.formId);
      formData.append('recordID', this.recordId);
      formData.append('detailID', this.detailId);

      // delete widget from pageWidgets
      const message = await this.$store.dispatch('widgets/deleteWidgetFromPage', formData);

      // show success message
      this.$store.commit('addMessage', {
        title: message,
        type: 'success',
      });

      // if we've deleted the current active widget, set new active widget
      // (!) note at this points the widget is deleted and store refs are undefined
      if (changeActiveWidget) {
        this.editWidget(newActiveWidget.recordDetailID, newActiveWidget.widgetID);
      }
    },

    // dropdown is about to be shown
    onDropdownShow() {
      // increase card z-index
      this.$refs.card.style.zIndex = this.dropdownZIndexValue;
    },

    // dropdown is hidden
    onDropdownHidden() {
      // reset card z-index
      this.$refs.card.style.zIndex = 'auto';
    },
  },
  mounted() {
    $(this.$refs.dropdown)
      .on('show.bs.dropdown', this.onDropdownShow.bind(this))
      .on('hidden.bs.dropdown', this.onDropdownHidden.bind(this));
  },
  unmounted() {
    $(this.$refs.dropdown)
      .off('show.bs.dropdown', this.onDropdownShow.bind(this))
      .off('hidden.bs.dropdown', this.onDropdownHidden.bind(this));
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/appwork/_appwork/_include.scss";

.card--widget {
  cursor: move;
  border-color: $border-color;

  &,
  &::before {
    transition: $transition-base;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 12px 0;
    border-color: transparent $file-item-bg-active transparent transparent;
    transform: scale(0);
    pointer-events: none;
  }

  &:hover {
    border-color: $file-item-bg-active;
    background: rgba(24, 28, 33, 0.03);
  }

  &.active {
    border-color: $file-item-bg-active;
    background: $file-item-bg-active;

    &::before {
      transform: translateX(-100%) translateY(-50%);
    }
  }

  &.disabled {
    pointer-events: all;
  }
}

.nav {
  top: 0;
  right: 0;
  height: 100%;
  z-index: 10;
}

.btn-action {
  $size: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size !important;
  height: $size !important;
  padding: 0;
  border: 0;

  &.text-danger:hover {
    background: rgba($danger, 0.1);
  }

  &.text-danger:focus,
  &.text-danger:active {
    background: rgba($danger, 0.15);
    box-shadow: 0 0 0 2px rgba($danger, 0.2);
  }

  &.text-body:hover {
    background: rgba($body-color, 0.1);
  }

  &.text-body:focus,
  &.text-body:active {
    background: rgba($body-color, 0.15);
    box-shadow: 0 0 0 2px rgba($body-color, 0.2);
  }

  .fa-edit {
    transform: translateX(1px);
  }
}
</style>
