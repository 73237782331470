export const formMutations = {
  /**
   * set active form field
   * @param state
   * @param formField {object} {pk: {string}, formId: {string}, formFieldId: {string}}
   */
  setActiveFormField(state, formField) {
    console.log('engine/setActiveFormField');
    state.activeFormField = formField;
  },

  /**
   * set form field data
   * @param state
   * @param args {object} {id: {string}, files: {array}}
   */
  setFormFieldData(state, args) {
    console.log('engine/setFormFieldData');
    state.formFieldData[args.id] = {
      files: args.files ?? [],
      settings: args.settings ?? {},
    };
  },

  /**
   * Set form field files
   * @param state
   * @param args {object} {id: {string}, files: {array}}
   */
  setFormFieldFiles(state, args) {
    console.log('engine/setFormFieldFiles');
    // Get values or set to empty array
    state.formFieldData[args.formFieldId].files = args.files;
  },

  /**
   * Delete form field files
   * @param state
   * @param args {object} {id: {string}, fileIds: {array}}
   */
  deleteFormFieldFiles(state, args) {
    console.log('engine/deleteFormFieldFiles');
    // Get values or set to empty array
    let files = Object.values(state.formFieldData[args.id].files) ?? [];
    // Remove deleted files
    files = files.filter((file) => !args.fileIds.includes(file.fileUID));
    // Set files
    state.formFieldData[args.id].files = files;
  },

  setThumbRefsToBeDeleted(state, refs) {
    console.log('engine/setThumbRefsToBeDeleted');
    state.thumbRefsToBeDeleted = refs;
  },

  /**
   * Add matrix upload fields
   * @param state
   * @param fields {object} //  { formFieldId: { items: [{ html: {}, settings: {}}], settings: {}, template: {} } }
   */
  setMatrixUploadFields(state, fields) {
    console.log('engine/setMatrixUploadFields');
    // get key
    const formFieldId = Object.keys(fields)[0];
    // set position & uid to fields
    Object.values(fields[formFieldId].items)
      .forEach((field, index) => {
        /* eslint-disable no-param-reassign */
        field.settings.position = index;
        field.settings.uid = index;
        /* eslint-enable no-param-reassign */
      });

    // apply formField values
    state.matrixUploadFields[formFieldId] = fields[formFieldId];
  },

  /**
   * Update matrix upload thumb values
   * @param state
   * @param field
   */
  updateMatrixUploadThumb(state, field) {
    console.log('engine/updateMatrixUploadThumb');
    // get matrix field
    const formField = state.matrixUploadFields[field.formFieldId];
    if (!formField) return;
    // get slide
    const item = formField.items.find((o) => o.settings.uid === field.slideId);
    if (!item) return;
    // edit slide thumb values
    item.settings.fileUID = field.files[0]?.fileUID;
    item.settings.folderId = field.files[0]?.folderId;
    item.settings.thumb = field.files[0]?.thumb;
  },

  /**
   * Delete matrix upload items
   * @param state
   * @param args {object} { formFieldId: string , uids: array }
   */
  deleteMatrixUploadItems(state, args) {
    console.log('engine/deleteMatrixUploadItems');
    const formField = state.matrixUploadFields[args.formFieldId];
    let fields = Object.values(formField.items);

    fields = fields.filter((field) => !args.uids.includes(field.settings.uid));
    state.matrixUploadFields[args.formFieldId].items = fields;
  },

  /**
   * Set matrix formFieldId to which the modal reacts to
   * @param state
   * @param id {string}
   */
  setMatrixModalFormFieldId(state, id) {
    console.log('engine/setMatrixModalFormFieldId');
    state.matrixModalFormFieldId = id;
  },

  /**
   * Set settings to be used in upload modal
   * @param state
   * @param settings {object}
   */
  setUploadModalSettings(state, settings) {
    console.log('engine/setUploadModalSettings');
    state.uploadModalSettings = settings;
  },
};

export default {
  formMutations,
};
