import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';
import reloadArea from './reloadArea';
import $ from 'jquery';
import { getActionSettings } from '@/api/engine/grid';

export default class RandomActionArchiveTask {
  /**
   * finish a task
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      modalForm: $('#modal-form'),
      modalFormContent: document.getElementById('modal-form-content'),
    };
    this.data = {
      formData: null,
    };
    this.props = {
      pk: this.dom.action.dataset.task,
    };
    this.events = {
      finishAction: this.archiveTask.bind(this),
    };
    this.customEvents = {
      notificationError: getErrorNotification(),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.finishAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.finishAction);
  }

  /**
   * finish a task
   *
   * @param e {MouseEvent}
   */
  archiveTask(e) {
    e.preventDefault();
    console.log('archive a task...');

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('actionType', 'archive');
    this.data.formData.append('task', this.props.pk);

    // make axios call
    axios({
      method: 'post',
      url: `${root}classmanagement/update-task-status`,
      data: this.data.formData,
    })
      .then((response) => {
        if (!response.data.success) {
          this.showError(response.data.errorMessage);
        } else {
          // handle success
          const notificationSuccess = new CustomEvent('notification', {
            detail: {
              title: response.data.successMessage,
              type: 'success',
            },
          });
          this.dom.engine.dispatchEvent(notificationSuccess);

          // remove parent div from tasks
          const parentRow = document.getElementById(`task-holder-${this.props.pk}`);
          parentRow.remove();
        }
      })
      .catch(() => {
        getCritical();
      });
  }

  /**
   * throw error
   *
   * @param errorMessage {String}
   */
  showError(errorMessage) {
    const notificationError = new CustomEvent('notification', {
      detail: {
        title: `${errorMessage}`,
        type: 'danger',
      },
    });
    this.dom.engine.dispatchEvent(notificationError);
  }
}
