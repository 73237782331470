// store
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isDrag: false,
      isReady: false,
    };
  },
  computed: {
    state: {
      get() {
        return this.$store.state.datastore.state;
      },
      set(value) {
        this.$store.commit('datastore/setState', value);
      },
    },
    folders: {
      get() {
        return this.$store.state.datastore.folders;
      },
      set(value) {
        this.$store.commit('datastore/setFolders', value);
      },
    },
    activeFolder: {
      get() {
        return this.$store.state.datastore.activeFolder;
      },
      set(value) {
        this.$store.commit('datastore/setActiveFolder', value);
      },
    },
    files: {
      get() {
        return this.$store.state.datastore.files;
      },
      set(value) {
        this.$store.commit('datastore/setFiles', value);
      },
    },
    ...mapState({
      maxUploadFiles: (state) => state.datastore.maxUploadFiles,
      maxUploadFilesize: (state) => state.datastore.maxUploadFilesize,
      maxParallelUploads: (state) => state.datastore.maxParallelUploads,
      uploadErrors: (state) => state.datastore.uploadErrors,
      views: (state) => state.datastore.views,
      activeView: (state) => state.datastore.activeView,
      states: (state) => state.datastore.states,
      selectedFiles: (state) => state.datastore.selectedFiles,
    }),
  },
  methods: {
    /**
     * posting folderID to api post
     * @param file {object}
     * @param xhr {xhr}
     * @param formData {FormData}
     */
    uploadSendingMultiple(file, xhr, formData) {
      if (!formData.has('uploadFolderID')) {
        formData.append('uploadFolderID', this.activeFolder.folderId);
      }
    },

    /**
     * file(s) has been uploaded successfully
     * @param files {array}
     */
    uploadSuccessMultiple(files) {
      console.log('uploadSuccessMultiple');
      const data = JSON.parse(files[0].xhr.response);
      const success = [];

      try {
        data.forEach((o) => {
          if (o.status.isSuccess) {
            this.$store.commit('datastore/addFile', o.file);
            success.push(o.file);
          } else {
            this.$store.commit('datastore/addUploadError', {
              fileName: o.file.fileName,
              message: o.status.message,
            });
          }
        });

        if (success.length === 1) {
          // single file uploaded
          this.$store.commit('addMessage', {
            title: `${success[0].fileName} werd successvol toegevoegd.`,
            type: 'success',
          });
        } else if (success.length > 0) {
          // multiple file uploaded
          this.$store.commit('addMessage', {
            title: `${success.length} bestanden werden successvol toegevoegd.`,
            type: 'success',
          });
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },

    // call failed
    uploadErrorMultiple() {
      console.log('error');
    },

    // file is dragged into view
    dragEnter() {
      console.log('dragEnter');
      document.body.classList.add('disable-scroll');
      this.isDrag = true;
    },

    // file drag cancelled
    dragCancel() {
      console.log('dragCancel');
      document.body.classList.remove('disable-scroll');
      this.isDrag = false;
    },

    // Build GridJS rows if list view is active
    async buildListViewRows() {
      // wait for DOM to update
      await this.$nextTick();
      if (this.activeView === this.views.list && this.$refs.viewList) this.$refs.viewList.buildRows();
    },
  },
  watch: {
    isReady() {
      if (this.isReady) {
        this.state = this.states.ready;
      } else {
        this.state = this.states.loading;
      }
    },

    // View changed
    activeView() {
      // List view specific
      this.buildListViewRows();
    },

    // New folder selected
    activeFolder: {
      deep: true,
      async handler() {
        if (!this.isReady) return;

        // Get files
        await this.$store.dispatch('datastore/getFilesFromFolder', {
          folderId: this.activeFolder.folderId,
        });

        // reset selection on folder change if deselectFilesOnFolderChange is set to true
        switch (this.activeView) {
          case this.views.grid: {
            if (this.$refs.viewGrid && this.$refs.viewGrid.deselectFilesOnFolderChange) this.$refs.viewGrid.deselectAll();
            break;
          }
          case this.views.list: {
            if (this.$refs.viewList && this.$refs.viewList.deselectFilesOnFolderChange) this.$refs.viewList.deselectAllRows();
            break;
          }
          default:
            break;
        }
      },
    },

    // Files have changed due to update (add, delete, change view, ...)
    files: {
      deep: true,
      handler() {
        if (!this.isReady) return;
        // List view actions
        this.buildListViewRows();

        if (this.activeView === this.views.grid && this.$refs.viewGrid) {
          // reset filtered results (search)
          this.$refs.viewGrid.setAmountOfFilteredResults();
        }
      },
    },
  },
  mounted() {
    // non-reactive prop
    this.dragEvent = this.dragEnter.bind(this);
  },
  beforeUnmount() {
    document.body.removeEventListener('dragenter', this.dragEvent);
  },
};
