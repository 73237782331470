<template>
  <button class="btn btn-outline-gray icon-btn d-flex align-items-center justify-content-center mr-2"
          title="Selectie wissen"
          type="button"
          @click="deselectAll"
          :disabled="selectedFiles.length < 1">
    <i class="bi bi-eraser-fill text-18"></i>
  </button>
</template>

<script>
  export default {
    name: 'datastore-btn-erase-selection',
    computed: {
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
    },
    methods: {
      deselectAll() {
        this.selectedFiles = [];
      },
    },
  };
</script>
