import axios from 'axios';
import { root } from '@/utils/paths';

/* *****************************************************
 * @note file upload is handled within dropzone itself *
 ***************************************************** */

/**
 * fetch all folders (including sub-folders)
 * @returns {Promise}
 */
export function getFolders() {
  return axios({
    method: 'post',
    url: `${root}datastore/get-all-folders`,
  });
}

/**
 * add new folder
 * @param data {FormData}
 * @returns {Promise}
 */
export function addFolder(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/add-folder`,
    data,
  });
}

/**
 * change folder name
 * @param data {FormData}
 * @returns {Promise}
 */
export function renameFolder(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/rename-folder`,
    data,
  });
}

/**
 * delete folder
 * @param data {FormData}
 * @returns {Promise}
 */
export function deleteFolder(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/delete-folder`,
    data,
  });
}

/**
 * get all files from given folder
 * @param data {FormData}
 * @returns {Promise}
 */
export function getFilesFromFolder(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/get-folder`,
    data,
  });
}

/**
 * get file by file id
 * @param data {FormData}
 * @returns {Promise}
 */
export function getFileById(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/get-file`,
    data,
  });
}

/**
 * remove files by fileUID
 * @param data {FormData}
 * @returns {Promise}
 */
export function deleteFiles(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/delete-files`,
    data,
  });
}

/**
 * edit file
 * @param data {FormData}
 * @returns {Promise}
 */
export function editFile(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/edit-file`,
    data,
  });
}

/**
 * download zip containing files (provided by fileIds)
 * @param data {FormData}
 * @returns {Promise}
 */
export function downloadFiles(data) {
  return axios({
    method: 'post',
    url: `${root}datastore/download-files`,
    data,
  });
}

export default {
  getFolders,
  addFolder,
  renameFolder,
  deleteFolder,
  getFilesFromFolder,
  getFileById,
  deleteFiles,
  editFile,
  downloadFiles,
  // getUploadSettings,
};
