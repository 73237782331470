/**
 * return icon for filetype
 * @param extension {string}
 * @param outline {boolean}
 * @returns {string}
 */
export function getExtensionIconClass(extension, outline = true) {
  let iconClass = outline ? 'far ' : 'fas ';

  switch (extension) {
    // image
    case 'png':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'webp':
      iconClass += 'fa-file-image';
      break;

    // word
    case 'doc':
    case 'docx':
      iconClass += 'fa-file-word';
      break;

    // powerpoint
    case 'ppt':
    case 'pptx':
      iconClass += 'fa-file-powerpoint';
      break;

    // excel
    case 'xls':
    case 'xlsx':
      iconClass += 'fa-file-excel';
      break;

    // pdf
    case 'pdf':
      iconClass += 'fa-file-pdf';
      break;

    // video
    case 'mp4':
    case 'webm':
      iconClass += 'fa-file-video';
      break;

    // audio
    case 'mp3':
    case 'wav':
    case 'wma':
      iconClass += 'fa-file-audio';
      break;

    // archive
    case 'zip':
    case 'rar':
    case '7zip':
      iconClass += 'fa-file-archive';
      break;

    default:
      iconClass += 'fa-file';
      break;
  }

  return iconClass;
}

/**
 * return file extension
 * @param fileName {string}
 * @param includeDot {boolean}
 * @returns {string|*}
 */
export function getFileExtension(fileName, includeDot = false) {
  if (includeDot) {
    return `.${fileName
      .split('.')
      .pop()}`;
  }
  return fileName
    .split('.')
    .pop();
}

/**
 * generate unique file name
 * @param url {string} optional to determine file extension
 * @returns {string}
 */
export function generateFileName(url = '') {
  return Date
    .now()
    .toString() + getFileExtension(url, true);
}

export function downloadFile(url, name = generateFileName(url)) {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  getExtensionIconClass,
  getFileExtension,
  downloadFile,
};
