export default class GridActionBulkSelect {
  /**
   * delete an item
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
      debugReport: document.getElementById('debugReport'),
      bulkActions: [],
    };
    this.events = {
      setBulkActionsCheckedState: this.setBulkActionsCheckedState.bind(this),
      setBulkActionCheckedState: this.setBulkActionCheckedState.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.setBulkActions('.bulkaction');
    this.dom.action.addEventListener('change', this.events.setBulkActionsCheckedState);
    this.dom.bulkActions.forEach((input) => input.addEventListener('change', this.events.setBulkActionCheckedState));
    this.setBulkActionCheckedState();
  }

  // remove event listener
  unmount() {
    this.setBulkActions();
    this.dom.action.removeEventListener('change', this.events.setBulkActionsCheckedState);
    this.dom.bulkActions.forEach((input) => input.removeEventListener('change', this.events.setBulkActionCheckedState));
  }

  /**
   * Return checked inputs
   * @returns {array}
   */
  getCheckedBulkActions() {
    return this.dom.bulkActions.filter((el) => el.checked);
  }

  /**
   * Checks if are all selected
   * @returns {boolean}
   */
  getAllCheckedState() {
    return this.dom.bulkActions.length === this.getCheckedBulkActions().length;
  }

  /**
   * Populate bulk actions
   * @param selector {string}
   */
  setBulkActions(selector = '') {
    if (selector.length > 0) {
      this.dom.bulkActions = [...document.querySelectorAll(selector)];
    }
  }

  // Set main bulk action check state
  setBulkActionCheckedState() {
    this.dom.action.checked = this.getAllCheckedState();
  }

  // Set checked state of bulk actions
  setBulkActionsCheckedState() {
    // keep local reference as getAllCheckedState() will change by bulkAction change event
    const isAllChecked = this.getAllCheckedState();
    this.dom.bulkActions.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.checked = !isAllChecked;
    });
  }
}
