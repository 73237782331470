<template>
  <div :id="`gridjs__${uuid}`" :data-uuid="uuid" :class="`gridjs__wrapper`"></div>
</template>

<script>
  /* based on https://github.com/grid-js/gridjs-vue/blob/master/src/gridjs-vue.vue */
  import { Grid, html } from 'gridjs';
  import { nanoid } from 'nanoid';
  import elementReady from 'element-ready';

  export default {
    name: 'grid',
    emits: ['ready', 'update', 'cellClick', 'row-selection-add', 'row-selection-remove', 'select-all-rows', 'deselect-all-rows'],
    props: {
      autoWidth: {
        type: Boolean,
        default: true,
      },
      classNames: {
        type: Object,
        default: undefined,
      },
      cols: {
        type: [Array, Function],
        default: undefined,
      },
      from: {
        type: [String, Function],
        default: undefined,
      },
      language: {
        type: Object,
        default: undefined,
      },
      pagination: {
        type: [Object, Boolean],
        default: false,
      },
      rows: {
        type: [Array, Function],
        default: undefined,
      },
      search: {
        type: Boolean,
        default: false,
      },
      moveSearchTo: {
        type: String,
        required: false,
      },
      server: {
        type: [Object, Function],
        default: undefined,
      },
      sort: {
        type: [Object, Boolean],
        default: false,
      },
      styles: {
        type: Object,
        default: undefined,
      },
      theme: {
        type: String,
        default: 'mermaid',
      },
      width: {
        type: String,
        default: '100%',
      },
      fixedHeader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dict: {
          error: {
            columnsUndefined: 'Column headers are undefined',
            rowsUndefined: 'No data to display',
          },
        },
        grid: null,
        uuid: null,
        wrapper: null,
        sortingEls: [],
        gridjsChecks: [],
      };
    },
    computed: {
      options() {
        const options = {
          autoWidth: this.autoWidth,
          columns: this.cols ? this.cols : [this.dict.error.columnsUndefined],
          // eslint-disable-next-line no-nested-ternary
          data: this.rows ? this.rows : this.from || this.server ? undefined : [[this.dict.error.rowsUndefined]],
          pagination: this.pagination,
          sort: this.sort,
          width: this.width,
          fixedHeader: this.fixedHeader,
          language: {
            search: {
              placeholder: 'Type a keyword...',
            },
            sort: {
              sortAsc: 'Sort column ascending',
              sortDesc: 'Sort column descending',
            },
            pagination: {
              previous: 'Previous',
              next: 'Next',
              navigate: (page, pages) => `Page ${page} of ${pages}`,
              page: (page) => `Page ${page}`,
              showing: 'Showing',
              of: 'of',
              to: 'to',
              results: 'results',
            },
            loading: 'Loading...',
            noRecordsFound: () => html(
              '   <div class="py-1"> '
              + '           <div class="my-4 py-2 text-center w-100">'
              + '             <p class="h4 text-muted mb-2">Geen resultaten gevonden</p>'
              + '             <p class="mb-0 text-light">Pas uw zoekopdracht aan om te vinden wat u zoekt.</p>'
              + '           </div>'
              + '         </div>',
            ),
            error: 'An error happened while fetching the data',
          },
        };

        // let classNames

        if (this.classNames) options.className = this.classNames;
        if (this.from) {
          options.from = typeof this.from === 'string'
            ? document.querySelector(this.from)
            : document.createRange()
              .createContextualFragment(this.from());
        }
        if (this.language) options.language = this.language;
        if (this.search) options.search = this.search;
        if (this.server) options.server = this.server;
        if (this.styles) options.style = this.styles;

        return options;
      },
    },
    watch: {
      autoWidth() {
        this.update();
      },
      classNames() {
        this.update();
      },
      cols() {
        this.update();
      },
      from() {
        this.update();
      },
      language() {
        this.update();
      },
      pagination() {
        this.update();
      },
      rows() {
        this.update();
      },
      search() {
        this.update();
      },
      server() {
        this.update();
      },
      sort() {
        this.update();
      },
      styles() {
        this.update();
      },
      width() {
        this.update();
      },
    },
    async mounted() {
      // give table a unique id
      this.uuid = nanoid();

      // select the unique wrapper element
      this.wrapper = await elementReady(`[data-uuid="${this.uuid}"]`, { stopOnDomReady: false });

      // instantiate grid.js
      if (this.wrapper && (this.options.data || this.options.from || this.options.server)) {
        this.grid = new Grid(this.options).render(this.wrapper);
      }

      this.grid.on('ready', () => {
        this.$emit('ready', true);

        // bind sorting th click
        this.sortingEls = [...document.querySelectorAll('.gridjs-th-sort')];
        this.sortingEls.forEach((el) => {
          el.addEventListener('click', this.sortingClick);
        });

        // track row changes
        this.gridjsChecks = [...this.$el.querySelectorAll('.gridjs-check-row')];
        this.gridjsChecks.forEach((input) => {
          input.addEventListener('change', this.onCheckRowChange);
        });

        // track column changes
        this.gridjsChecks = [...this.$el.querySelectorAll('.gridjs-check-columns')];
        this.gridjsChecks.forEach((input) => {
          input.addEventListener('change', this.onCheckColumnsChange);
        });
      });

      this.grid.on('cellClick', (...args) => this.$emit('cellClick', args));
    },
    unmounted() {
      // unload from memory
      this.grid = undefined;
      this.wrapper = undefined;
    },
    methods: {
      update() {
        if (this.grid) {
          this.grid.updateConfig(this.options)
            .forceRender();
        }
        this.$emit('update', true);
      },

      /**
       * clicked on sorting th
       * @param e {MouseEvent}
       */
      sortingClick(e) {
        const { currentTarget } = e;
        if (!currentTarget.classList.contains('active')) {
          this.sortingEls.forEach((el) => {
            if (el !== currentTarget) {
              // remove active class on other elements
              el.classList.remove('active');
            } else {
              // add active class
              currentTarget.classList.add('active');
            }
          });
        }
      },

      onCheckRowChange(e) {
        const input = e.target;

        if (input.checked) {
          this.$emit('row-selection-add', input.value);
        } else {
          this.$emit('row-selection-remove', input.value);
        }
      },

      onCheckColumnsChange(e) {
        if (e.target.checked) {
          this.$emit('select-all-rows');
        } else {
          this.$emit('deselect-all-rows');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/appwork/_appwork/include";

  th,
  td {
    vertical-align: middle;
  }

  :deep(.gridjs-head),
  :deep(.gridjs-search) {
    display: none !important;
  }

  :deep(.gridjs-table) {
    @include font-size(14px);
  }
</style>
