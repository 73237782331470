<template>
  <template v-if="file">
    <!-- Heading -->
    <header class="header-sticky d-flex align-items-center justify-content-between py-3 mb-4">
      <!-- Title -->
      <h4 class="font-weight-bold text-truncate mb-0 pr-5 mr-auto">
        <span class="text-muted font-weight-light">Bibliotheek&nbsp;/&nbsp;</span>{{ file?.fileName }}
      </h4>

      <!-- Actions -->
      <div class="d-flex justify-content-end">
        <a class="btn icon-btn btn-outline-secondary d-flex justify-content-center align-items-center py-0 mr-2" :href="overviewUrl">
          <i class="bi bi-arrow-left-short text-24"></i>
        </a>

        <!-- Edit -->
        <button type="button" class="btn btn-outline-secondary disabled mr-2">Bewerk</button>

        <!-- Download -->
        <button
          @click="downloadFile"
          :disabled="!file?.sourceUrl || !file?.fileName"
          type="button"
          class="btn btn-outline-secondary mr-2">
          Download
        </button>

        <div class="btn-group flex-shrink-0">
          <div class="btn-group">
            <!-- Save -->
            <button @click="save" type="button" class="btn btn-primary">Opslaan</button>

            <!-- Options -->
            <button type="button" class="btn btn-primary dropdown-toggle-split dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-chevron-down text-14"></i>
            </button>

            <!-- View -->
            <div class="dropdown-menu" x-placement="bottom-start">
              <a v-if="file?.sourceUrl" class="dropdown-item" target="_blank" rel="noreferrer noopener" :href="file.sourceUrl">
                Bekijk in nieuwe tab
              </a>

              <!-- Delete -->
              <button class="dropdown-item text-danger" type="button" @click="onClickDelete">Verwijderen</button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <div class="row">
      <div class="col-md-8 pr-lg-5">
        <!-- Editable -->
        <div class="card">
          <div class="card-body card-form-groups">
            <form class="js-form-check-changes" ref="form">
              <div v-if="file?.fileName" class="form-group">
                <label class="form-label" for="filename">Bestandsnaam</label>
                <input type="text" class="form-control" name="noExtensionName" id="filename" v-model="modelFileName" @input="cleanFileName">
                <small v-if="fileNameInvalidCharacter" class="d-block feedback text-secondary my-2">{{ fileNameInvalidCharacter }} is niet toegelaten.</small>
              </div>

              <template v-if="file?.translations">
                <!-- More than 1 translations -->
                <template v-if="Object.keys(file.translations).length > 1">
                  <div class="row row-form-groups">
                    <div v-for="(value, name) in file.translations" :key="name" class="form-group col-xl-6">
                      <label class="form-label" :for="'alt-' + name">Beschrijving
                        <span class="text-uppercase">{{ name }}</span>
                      </label>
                      <input type="text" class="form-control" :id="'alt-' + name" :name="'translations[' + name + ']'" :value="value">
                    </div>
                  </div>
                </template>
                <!-- 1 translation -->
                <template v-else>
                  <div v-for="(value, name) in file.translations" :key="name" class="form-group">
                    <label class="form-label" :for="'alt-' + name">Alt tekst
                      <span class="text-uppercase">{{ name }}</span></label>
                    <input type="text" class="form-control" :id="'alt-' + name" :name="'translations[' + name + ']'" :value="value">
                  </div>
                </template>
              </template>
            </form>
          </div>
        </div>

        <!-- Non-editable -->
        <div class="card bg-border mt-4">
          <div class="card-body">
            <table class="w-100">
              <tbody>
                <tr v-if="file?.path">
                  <th class="py-2 pr-4 form-label mb-0">Locatie</th>
                  <td class="py-2 w-100">{{ pathToArrows(file.path) }}</td>
                </tr>
                <tr v-if="file?.extension">
                  <th class="py-2 pr-4 form-label mb-0">Extensie</th>
                  <td class="py-2 w-100">{{ file.extension }}</td>
                </tr>
                <tr v-if="file?.size">
                  <th class="py-2 pr-4 form-label mb-0">Bestandsgroote</th>
                  <td class="py-2 w-100">{{ file.size }} {{ sizeUnit }}</td>
                </tr>
                <tr v-if="file?.dateAdd">
                  <th class="py-2 pr-4 form-label mb-0">Geüpload op</th>
                  <td class="py-2 w-100">{{ file.dateAdd }}</td>
                </tr>
                <tr v-if="file?.dateModified">
                  <th class="py-2 pr-4 form-label mb-0">Gewijzigd op</th>
                  <td class="py-2 w-100"></td>
                </tr>
                <tr v-if="file?.width && file?.height">
                  <th class="py-2 pr-4 form-label mb-0">Dimensies</th>
                  <td class="py-2 w-100">{{ file.width }} x {{ file.height }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div class="col-md-4">
        <aside v-if="file?.sourceUrl">
          <a :href="file.sourceUrl"
             target="_blank"
             rel="noreferrer noopener"
             class="card bg-file-img">
            <div class="embed-responsive embed-responsive-1by1">
              <img class="embed-responsive-item of-contain" :src="file.sourceUrl">
            </div>
          </a>
        </aside>
      </div>
    </div>

    <!-- File delete modal -->
    <file-delete-modal @deleteConfirm="goToOverview"></file-delete-modal>
  </template>

  <template v-else>
    <!-- File not found -->
    <template v-if="fileNotFound">
      <div class="mw-50 text-center mx-auto py-5">
        <img class="img-fluid mt-5" :src="svgfileNotFound" alt="file_not_found" width="200">
        <h1 class="mt-5">Bestand niet gevonden</h1>
        <p class="text-18 font-weight-light text-muted">We hebben overal gezocht, maar het bestand niet gevonden.<br> Mogelijk is het verplaatst of niet langer beschikbaar.
        </p>
        <a class="btn btn-lg btn-primary d-inline-block mt-4" :href="overviewUrl">Terug naar overzicht</a>
      </div>
    </template>
    <template v-else>
      <!-- Loading -->
      <div class="row align-items-center py-3 mb-4">
        <div class="col-md-8 col-xl-7 pr-xl-5 d-flex align-items-center">
          <skeleton-box class="w-75" height="44px"/>
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <div class="col-4">
              <skeleton-box height="44px"/>
            </div>
            <div class="col-4">
              <skeleton-box height="44px"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 pr-lg-5">
          <skeleton-box height="300px"/>
          <skeleton-box class="mt-4" height="250px"/>
        </div>
        <div class="col-md-4">
          <div class="embed-responsive embed-responsive-1by1">
            <skeleton-box class="embed-responsive-item position-absolute h-100"/>
          </div>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
  import { mapState } from 'vuex';

  // utils
  import { downloadFile } from '@/utils/files';

  // mixins
  import mixinDeleteFile from '@/mixins/datastore/deleteFile';
  import mixinRegex from '@/mixins/datastore/regex';
  import mixinBeforeUnload from '@/mixins/beforeUnload';

  // components
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  // partials
  import svgFileNotFound from '@/assets/img/404_file.svg';
  import fileDeleteModal from '../partials/FileDeleteModal';

  // assets

  const pathName = window.location.pathname;
  const id = pathName
    .substring(pathName.lastIndexOf('/') + 1)
    .split('-')[1];
  const formClass = '.js-form-check-changes';

  export default {
    name: 'datastore-detail',
    mixins: [mixinDeleteFile, mixinRegex, mixinBeforeUnload],
    data() {
      return {
        fileNotFound: false,
        fileNameInvalidCharacter: null,
        overviewUrl: `${window.location.href.split('/file/')[0]}/overview`,
        sizeUnit: 'mb',
        svgFileNotFound,
      };
    },
    computed: {
      ...mapState({
        file: (state) => state.datastore.activeFile,
        files: (state) => state.datastore.files,
      }),
    },
    components: {
      fileDeleteModal,
      skeletonBox,
    },
    methods: {
      // get file from id
      async getFile() {
        // set active file
        await this.$store.dispatch('datastore/getFileById', id);

        if (this.file) {
          // file was found
          this.resetState();

          // keep track of changes to forms
          this.setInitialFormDataString(formClass);
        } else {
          // file was not found
          this.showErrorMessage();
        }
      },

      /**
       * reset file state
       */
      resetState() {
        // set data
        this.modelFileName = this.file?.noExtensionName;
        this.fileNameInvalidCharacter = '';
        this.fileNotFound = false;

        // save current form json
        this.$nextTick(() => {
         this.setInitialFormDataString(formClass);
        });
      },

      // download file
      downloadFile() {
        downloadFile(this.file.sourceUrl, this.file.fileName);
      },

      // save changes
      save() {
        const formData = new FormData(this.$refs.form);
        const formDataString = this.getFormDataString(formClass);

        // if form hasn't changed, don't save
        if (this.initialFormDataString === formDataString) {
          this.showSuccessMessage();
          return;
        }

        // append file id
        formData.append('fileID', this.file.fileUID);

        // send edit file request
        this.$store.dispatch('datastore/editFile', formData)
          .then(() => {
            this.resetState();
            this.showSuccessMessage();
          })
          .catch(() => this.showErrorMessage());
      },

      // on delete click
      onClickDelete() {
        // delete file
        this.deleteFile();

        // if we have no confirmation, go back to overview
        if (!this.requireFileDeleteConfirm) this.goToOverview();
      },

      // return to files overview
      goToOverview() {
        window.location = this.overviewUrl;
      },

      /**
       * replace / by ⭢
       * @param path {string}
       * @returns {string}
       */
      pathToArrows(path) {
        return path.substring(1)
          .replace('/', ' ⭢ ');
      },

      // unable to load file
      showSuccessMessage() {
        this.$store.commit('addMessage',
          {
            title: 'Wijzigingen werden opgeslagen.',
            type: 'success',
          },
          { root: true });
      },

      // unable to load file
      showErrorMessage() {
        this.fileNotFound = true;
      },
    },
    created() {
      // get file (by id)
      this.getFile();
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .card-form-groups .form-group:last-of-type {
    margin-bottom: 0 !important;
  }

  .card-form-groups .row-form-groups:nth-last-of-type(-n+2) {
    @include media-breakpoint-up(xl) {
      margin-bottom: 0 !important;
    }
  }

  .btn-group > .dropdown-toggle {
    &::after {
      display: none;
    }
  }
</style>
