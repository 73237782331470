/**
 * Returns a non-existent class or id
 * @param selector { string }
 * @returns { string }
 */
export function getUniqueSelector(selector) {
  if (document.querySelector(selector)) {
    let counter = 1;
    while (document.querySelector(selector + String(counter))) {
      counter += 1;
    }
  }

  return selector;
}

export default {
  getUniqueSelector,
};
