<template>
  <a v-if="selectedFiles.length === 1"
     :href="root + 'datastore/file/fileID-' + selectedFiles[0].fileUID"
     class="dropdown-item"
     type="button">
    Bewerk
  </a>
  <button v-else class="dropdown-item" type="button" disabled>Bewerk</button>
</template>

<script>
  import { mapState } from 'vuex';

  // utils
  import { root } from '@/utils/paths';

  export default {
    name: 'datastore-btn-edit-file',
    computed: {
      ...mapState({
        selectedFiles: (state) => state.datastore.selectedFiles,
      }),
    },
    data() {
      return {
        root,
      };
    },
    methods: {},
  };
</script>
