import { folderStates } from './folder/states';
import { folderActions } from './folder/actions';
import { folderMutations } from './folder/mutations';

import { fileStates } from './file/states';
import { fileGetters } from './file/getters';
import { fileActions } from './file/actions';
import { fileMutations } from './file/mutations';

const views = {
  list: 'VIEW_LIST',
  grid: 'VIEW_GRID',
};

const states = {
  ready: 'STATE_READY',
  loading: 'STATE_LOADING',
};

// initial state
const data = () => ({
  views,
  states,
  state: states.loading,
  activeView: views.list,
  searchValue: '',
  uploadErrors: [],
  ...folderStates,
  ...fileStates,
});

const getters = { ...fileGetters };

// actions
const actions = { ...folderActions, ...fileActions };

// mutations
const mutations = {
  /**
   * change view
   * @param state
   * @param isLoading {Boolean}
   */
  setLoadingState(state, isLoading) {
    console.log('datastore/setLoadingState');
    state.loading = isLoading;
  },

  /**
   * change view
   * @param state
   * @param view {string}
   */
  setView(state, view) {
    console.log('datastore/setView');
    state.activeView = view;
  },

  /**
   * set search value
   * @param state
   * @param value {string}
   */
  setSearchValue(state, value) {
    console.log('datastore/setSearchValue');
    state.searchValue = value;
  },

  /**
   * set new state
   * @param state
   * @param newState {string}
   */
  setState(state, newState) {
    console.log('datastore/setState');
    // same state, do nothing
    if (state.state === newState) return;

    // check if newState exists in states
    if (!Object.values(state.states).includes(newState)) {
      console.warn(`Unknown state: ${newState}`);
      return;
    }

    state.state = newState;
  },

  /**
   * add upload error
   * @param state
   * @param error {*}
   */
  addUploadError(state, error) {
    console.log('datastore/addUploadError');
    state.uploadErrors.push(error);
  },

  /**
   * remove upload error
   * @param state
   * @param error {*}
   */
  removeUploadError(state, error) {
    console.log('datastore/removeUploadError');
    state.uploadErrors = state.uploadErrors.filter((item) => item !== error);
  },
  ...folderMutations,
  ...fileMutations,
};

export default {
  namespaced: true,
  state: data, // fixes upper scope no-shadow - https://stackoverflow.com/a/54744970
  getters,
  actions,
  mutations,
};
