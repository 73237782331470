// import axios from 'axios';
// import { root } from '@/utils/paths';
import GridAction from './gridAction';

export default class GridLevelFilter {
  /**
   * constructor for the GridSearch class
   * @param payload {Object}
   */
  constructor(payload) {
    this.dom = {
      ...{
        selectLevelFilter: payload.dom.gridEl.querySelector('.js-level-filter'),
      },
      ...payload.dom,
    };
    this.props = {
      ...{
        filterToLevel: null,
      },
      ...payload.props,
    };
    this.data = {
      ...{
        formData: null,
      },
      ...payload.data,
    };
    this.components = {
      filterAction: new GridAction(this.props.gridId, this.props.ajaxUrl),
    };
    this.events = {
      onFilterLevel: this.onFilterLevel.bind(this),
    };

    // bind all actions
    this.mount();
  }

  // bind
  mount() {
    if (this.dom.selectLevelFilter) this.dom.selectLevelFilter.addEventListener('change', this.events.onFilterLevel);
  }

  // unbind
  unmount() {
    if (this.dom.selectLevelFilter) this.dom.selectLevelFilter.removeEventListener('change', this.events.onFilterLevel);
    this.components = null;
  }

  /**
   * function to filter a grid based on the selected level
   * @param e {MouseEvent}
   */
  async onFilterLevel(e) {
    // inits
    console.log('onFilterLevel');
    this.props.filterToLevel = e.target.value;

    // pass the necessary data
    this.data.formData = new FormData();
    this.data.formData.append('gridID', this.props.gridId);
    this.data.formData.append('gotoTab', this.props.activeTab);
    this.data.formData.append('filterToLevel', this.props.filterToLevel);

    await this.components.filterAction.applyFilter(this.data.formData);

    // umount
    this.unmount();
  }
}
