import $ from 'jquery';
import Util from 'bootstrap/js/src/util';
import Alert from 'bootstrap/js/src/alert';
import Button from 'bootstrap/js/src/button';
import Carousel from 'bootstrap/js/src/carousel';
import Collapse from 'bootstrap/js/src/collapse';
import Dropdown from 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';
import Scrollspy from 'bootstrap/js/src/scrollspy';
import Tab from 'bootstrap/js/src/tab';
import Tooltip from 'bootstrap/js/src/tooltip';
import Popover from 'bootstrap/js/src/popover';

// Tooltip extension
//

const bsTooltipSetContent = Tooltip.prototype.setContent;

// Set tooltip state
Tooltip.prototype.setContent = function tooltipSetContent() {
  const state = this.element.getAttribute('data-state');

  if (state) {
    $(this.getTipElement()).addClass(`tooltip-${state.replace(/[^a-z0-9_-]/ig, '')}`);
  }

  bsTooltipSetContent.call(this);
};

// Popover extension
//

const bsPopoverSetContent = Popover.prototype.setContent;

// Set popover state
Popover.prototype.setContent = function popoverSetContent() {
  const state = this.element.getAttribute('data-state');

  if (state) {
    $(this.getTipElement()).addClass(`popover-${state.replace(/[^a-z0-9_-]/ig, '')}`);
  }

  bsPopoverSetContent.call(this);
};

export {
  Util,
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Scrollspy,
  Tab,
  Tooltip,
  Popover,
};
