import { formStates } from './form/states';
import { formGetters } from './form/getters';
import { formActions } from './form/actions';
import { formMutations } from './form/mutations';

import { gridStates } from './grid/states';
import { gridMutations } from './grid/mutations';

// initial state
const data = () => ({
  activePage: 1,
  searchDelay: 500,
  ...gridStates,
  ...formStates,
});

const getters = { ...formGetters };

// actions
const actions = { ...formActions };

// mutations
const mutations = {
  /**
   * set active active page number
   * @param state
   * @param num {Integer}
   */
  setActivePage(state, num) {
    state.activePage = num;
  },
  ...formMutations,
  ...gridMutations,
};

export default {
  namespaced: true,
  state: data, // fixes upper scope no-shadow - https://stackoverflow.com/a/54744970
  getters,
  actions,
  mutations,
};
