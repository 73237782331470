<template>
  <template v-if="state === states.loading">
    <sidebar-skeleton/>
  </template>
  <template v-else>
    <!-- Add root folder -->
    <button
      v-if="showAddRootFolder"
      @click.prevent="addRootFolder"
      type="button"
      class="btn btn-sm mb-4 border-0 borderless p-0 d-flex align-items-center bg-transparent btn-add-root-folder text-transform-none">
      <span class="text-15 flex-shrink-0">
        <i class="bi bi-plus-circle line-height-1"></i>
      </span>
      Map toevoegen
    </button>

    <!-- Root folders -->
    <ul class="list-unstyled ul-deep-nest">
      <folder
        v-for="folder in folders"
        :key="folder.folderId"
        :folder="folder"
        :allowDelete="allowDeleteFolder"
        :disabled="!filteredFolders.includes(folder)"
        ref="folder">
      </folder>
    </ul>
  </template>
</template>

<script>
  import { mapState } from 'vuex';
  // partials
  import sidebarSkeleton from '@/views/v10/datastore/overview/sidebar/SidebarSkeleton';
  import folder from './Folder';

  export default {
    name: 'datastore-sidebar',
    props: {
      showAddRootFolder: {
        type: Boolean,
        required: false,
        default: true,
      },
      allowDeleteFolder: {
        type: Boolean,
        required: false,
        default: true,
      },
      lockedFolderIds: {
        type: Array, // array of folderId's ['2', '4']
        required: false,
        default() {
          return [];
        },
      },
    },
    computed: {
      filteredFolders() {
        // only keep lockedFolderIds if any are set
        if (this.lockedFolderIds.length > 0) {
          return this.folders.filter((f) => this.lockedFolderIds.includes(f.folderId));
        }

        // folders are not locked, show all folders
        return this.folders;
      },
      ...mapState({
        folders: (state) => state.datastore.folders,
        activeRenameFolderId: (state) => state.datastore.activeRenameFolderId,
        state: (state) => state.datastore.state,
        states: (state) => state.datastore.states,
      }),
    },
    components: {
      folder,
      sidebarSkeleton,
    },
    methods: {
      addRootFolder() {
        const tempFolder = {
          folderName: '',
          folderId: this.$store.state.datastore.TEMP_ID_PREFIX + Math.round((Math.random() * 1000)),
          parentFolderId: this.$store.state.datastore.ROOT_FOLDER_ID,
        };

        // add temp folder
        this.$store.commit('datastore/addTempFolder', tempFolder);

        // trigger rename
        this.$nextTick(() => {
          this.$refs.folder.$refs.btnRenameFolder.click('');
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";

  .btn-add-root-folder {
    color: $body-color;
    box-shadow: none !important;

    span {
      margin-right: 0.375rem;
    }

    &:hover,
    &:focus {
      color: $primary;
    }
  }
</style>
