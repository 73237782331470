<template>
  <!-- Conditional class cannot be set on modal! -->
  <div :class="{'is-dragging': isDrag}">
    <modal
      id="modal-library"
      size="xl"
      ref="modalLibrary"
      :isModalDialogCentered="true"
      @show="onModalShow"
      @hidden="onModalHidden">
      <template v-slot:body>
        <div class="modal-body pt-4">
          <div class="row">
            <!-- Folders -->
            <aside class="col-md-4 col-xl-3 pr-xl-5">
              <sidebar :lockedFolderIds="restrictions.lockedFolderIds"
                       :showAddRootFolder="restrictions.showAddRootFolder"
                       :allowDeleteFolder="false"/>
            </aside>

            <!-- Files -->
            <div class="col-md-8 col-xl-9 position-relative">
              <!-- Header -->
              <template v-if="state === states.loading">
                <div class="row no-gutter mb-4">
                  <div class="col-md-9 pr-2">
                    <skeleton-box height="32px"/>
                  </div>
                  <div class="col-md-3">
                    <div class="row no-gutters">
                      <div class="col-md-6 pr-2">
                        <skeleton-box height="32px"/>
                      </div>

                      <div class="col-md-6">
                        <skeleton-box height="32px"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- Actions -->
                <div class="d-flex mb-4">
                  <!-- Search -->
                  <search></search>

                  <btnEraseSelection></btnEraseSelection>

                  <!-- Views -->
                  <div :class="{ disabled: files.length < 1 }" class="btn-group btn-group-toggle flex-shrink-0">
                    <!-- List -->
                    <btn-switch-view :view="views.list" title="Lijst weergave">
                      <i class="bi bi-list-ul text-18"></i>
                    </btn-switch-view>

                    <!-- Grid -->
                    <btn-switch-view :view="views.grid" title="Raster weergave">
                      <i class="bi bi-grid-3x3-gap-fill text-16"></i>
                    </btn-switch-view>
                  </div>
                </div>
              </template>

              <!-- Upload errors -->
              <div v-if="uploadErrors.length > 0 && state === states.ready" class="alert alert-warning alert-dismissible fade show">
                <button type="button" class="close py-1" data-dismiss="alert">×</button>
                <div class="font-weight-bold text-16 mb-2">De volgende bestanden konden niet worden geüpload.</div>
                <ul class="list-unstyled mb-0">
                  <li v-for="(error, index) in uploadErrors" :key="error.fileName" :class="{'mt-2': index === 1}" class="text-14">
                    <div class="font-weight-bold">{{ error.fileName }}</div>
                    {{ error.message }}
                  </li>
                </ul>
              </div>

              <!-- Restrictions -->
              <div class="alert d-block text-12 mb-2"
                   :class="{'alert-warning': isSaveDisabled, 'alert-white': !isSaveDisabled}"
                   v-if="state === states.ready">
                <file-upload-restrictions
                  :min-files="restrictions.minFiles"
                  :max-files="restrictions.maxFiles"
                  :allowed-extensions="restrictions.allowedExtensions"/>
              </div>

              <!-- Folder has files -->
              <!-- List view -->
              <view-list v-if="activeView === views.list"
                         ref="viewList"
                         config="modal"
                         :maxFiles="restrictions.maxFiles"
                         :allowedExtensions="restrictions.allowedExtensions"
                         :deselect-files-on-folder-change="false"/>

              <!-- Grid view -->
              <view-grid v-else
                         ref="viewGrid"
                         :allowedExtensions="restrictions.allowedExtensions"
                         :allowEdit="false"
                         :deselect-files-on-folder-change="false"/>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="modal-footer d-flex position-sticky bg-white">
          <template v-if="state === states.loading">
            <div class="row no-gutters w-100">
              <div class="col-md-4 col-xl-3 pr-xl-5">
                <skeleton-box height="32px"/>
              </div>
              <div class="col-md-8 col-xl-9 text-right">
                <skeleton-box class="w-25 mr-2" height="32px"/>
                <skeleton-box class="w-25" height="32px"/>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="my-0 ml-0 mr-auto pr-5">
              <dropzone
                :use-custom-slot=true
                :autoDiscover=false
                :options="{
                        url: `${root}datastore/file-upload`,
                        previewTemplate: 'dz-template',
                        previewsContainer: '#previews',
                        clickable: true,
                        createImageThumbnails: true,
                        uploadMultiple: true,
                        paramName: restrictions.paramName,
                        maxFilesize: restrictions.maxFilesize,
                       }"
                @sendingMultiple="uploadSendingMultiple"
                @errorMultiple="uploadErrorMultiple"
                @successMultiple="uploadSuccessMultiple"
                @addedFile="dragCancel"
                @dragleave="dragCancel"
                role="form"
                class="dropzone dz-clickable d-inline-block border-0 p-0"
                :class="{'dropzone-fs': isDrag}"
                id="dropzone-file-upload">
                <template v-slot:dz-message>
                  <template v-if="isDrag">
                    <!-- upload drag & drop -->
                    <div class="dz-message">
                      <div class="content">
                        <div class="text-center lead font-weight-light mw-50">
                          <p class="display-3 mb-4">Drop it like it's hot</p>
                          Sleep je bestanden hier om ze toe te voegen aan '<span class="font-weight-semibold">{{ activeFolder.folderName }}</span>'
                          <div class="d-block text-14 mt-1 text-center opacity-75">
                            <file-upload-restrictions
                              :min-files="restrictions.minFiles"
                              :max-files="restrictions.maxFiles"
                              :allowed-extensions="restrictions.allowedExtensions"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <!-- upload button -->
                    <div class="dz-message m-0 text-left">
                      <button type="button"
                              class="btn btn btn-primary d-flex align-items-center flex-shrink-0 dz-message my-0 ml-auto">
                        <i class="bi bi-plus-circle-fill mr-2 text-16"></i>
                        {{ restrictions.btnUploadText }}
                      </button>
                    </div>
                  </template>
                </template>
              </dropzone>
            </div>

            <div class="m-0">
              <button type="button" class="btn btn-default mr-2" data-dismiss="modal">Sluiten</button>
              <button type="button"
                      class="btn btn-primary d-inline-flex align-items-center flex-wrap"
                      :disabled="isSaveDisabled || isSaving"
                      @click="save">
                {{ restrictions.btnAddText }}
                <span v-if="isSaving" class="spinner d-inline-block ml-2"></span>
              </button>
            </div>
          </template>
        </div>
      </template>
    </modal>
  </div>

  <!-- Dropzone preview template -->
  <template-list></template-list>
</template>

<script>
  import $ from 'jquery';
  import dropzone from '@/components/dropzone/Dropzone';
  import modal from '@/components/modal/Modal';
  import { root } from '@/utils/paths';
  import { findDeep } from '@/utils/array';
  import sidebar from '@/views/v10/datastore/overview/sidebar/Sidebar';
  import viewList from '@/views/v10/datastore/overview/list/List';
  import viewGrid from '@/views/v10/datastore/overview/grid/Grid';
  import datastoreBtnEraseSelection from '@/views/v10/datastore/overview/heading/BtnEraseSelection';
  import datastoreSearch from '@/views/v10/datastore/overview/heading/Search';
  import datastoreBtnSwitchView from '@/views/v10/datastore/overview/heading/BtnSwitchView';
  import skeletonBox from '@/components/skeleton/SkeletonBox';
  import templateList from '@/components/dropzone/TemplateList';
  import fileUploadRestrictions from '@/components/upload/FileUploadRestrictions';
  import mixinDatastoreUpload from '@/mixins/datastore/upload';

  export default {
    name: 'file-upload-modal',
    mixins: [mixinDatastoreUpload],
    data() {
      return {
        root,
        isSaving: false,
        defaultRestrictions: {
          allowedExtensions: [],
          acceptedFiles: null, // mime types (image/jpeg)
          btnUploadText: 'Bestand uploaden',
          btnAddText: 'Opslaan',
          lockedFolderIds: [], // ['2', '4']
          minFiles: 1,
          maxFiles: null,
          paramName: 'file',
          showAddRootFolder: true,
        },
      };
    },
    computed: {
      isSaveDisabled() {
        // Max is set
        if (this.restrictions.maxFiles) {
          const min = Math.min(this.restrictions.minFiles, this.restrictions.maxFiles);
          const max = Math.max(this.restrictions.minFiles, this.restrictions.maxFiles);
          // check if in range
          return (this.selectedFiles.length < min || this.selectedFiles.length > max);
        }

        // Max is not set, disable if less than min
        return this.selectedFiles.length < this.restrictions.minFiles;
      },
      restrictions() {
        // merge defaults restrictions with user defined
        return { ...this.defaultRestrictions, ...this.uploadModalSettings.restrictions };
      },
      uploadModalSettings: {
        get() {
          return this.$store.state.engine.uploadModalSettings;
        },
        set(value) {
          this.$store.commit('engine/setUploadModalSettings', value);
        },
      },
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
    },
    components: {
      dropzone,
      modal,
      sidebar,
      search: datastoreSearch,
      btnSwitchView: datastoreBtnSwitchView,
      btnEraseSelection: datastoreBtnEraseSelection,
      viewList,
      viewGrid,
      skeletonBox,
      templateList,
      fileUploadRestrictions,
    },
    methods: {
      /**
       * This event fires immediately when the show instance method is called
       */
      async onModalShow() {
        const folders = this.uploadModalSettings.files.map((file) => file.folderId);

        // get folders
        await this.$store.dispatch('datastore/getAllFolders');

        // set active folder
        this.activeFolder = this.getActiveFolder(folders);

        // get files
        await this.$store.dispatch('datastore/getFilesFromFolder', {
          folderId: this.activeFolder.folderId,
        });

        // set selected id's
        this.setCheckedState(this.uploadModalSettings.files);

        // keep current selected file ids reference
        this.updateOldCheckedFiles();

        // view list specific action
        if (this.activeView === this.views.list && this.activeFolder) this.$refs.viewList.buildRows();

        // listen drag and drop file upload
        this.isDrag = false;
        document.body.addEventListener('dragenter', this.dragEvent);

        // all done
        this.isReady = true;
      },

      /**
       * This event is fired when the modal has finished being hidden from the user
       * (will wait for CSS transitions to complete).
       */
      onModalHidden() {
        console.log('hidden');
        this.$store.commit('engine/setActiveFormField', null);
        this.uploadModalSettings = {};
        this.selectedFiles = [];
        this.activeFolder = null;
        document.body.removeEventListener('dragenter', this.dragEvent);
        this.dragCancel();
        this.isReady = false;
      },

      /**
       * Get active folder
       * @param folderIds {array}
       * @returns {object}
       */
      getActiveFolder(folderIds) {
        // folder is found, set as active
        const activeFolder = folderIds.find((folderId) => findDeep(this.folders, 'folderId', folderId, 'children')?.item);
        if (activeFolder) return activeFolder;

        // folder is not found, set first folder as active
        return this.folders[0];
      },

      /**
       * Check selected files
       * @param files {array}
       */
      setCheckedState(files) {
        // can contain files from other folders as well
        files.forEach((file) => {
          this.$store.commit('datastore/addSelectedFile', file);
        });
      },

      save() {
        // Set saving state to loading
        this.isSaving = true;

        // apply save action
        this.$store.commit(this.uploadModalSettings.action, {
          files: this.selectedFiles,
          slideId: this.uploadModalSettings?.slideId,
          formFieldId: this.uploadModalSettings?.formFieldId,
        });

        // Set saving state to idle
        this.isSaving = false;
        $('#modal-library')
          .modal('hide');
      },

      // copy & creating a new value reference in memory
      updateOldCheckedFiles() {
        this.oldCheckedIds = this.selectedFiles.slice(0);
      },
    },
    mounted() {
      this.oldCheckedIds = [];
    },
  };
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/appwork/_appwork/_include.scss";
  @import "node_modules/spinthatshit/src/loaders";

  div.is-dragging :deep(.modal-dialog) {
    max-width: none;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .modal-footer {
    bottom: 0;
    z-index: 250;
  }

  .dropzone {
    min-height: 0;
  }

  .spinner {
    $size: 16px;
    @include loader01($size: $size, $color: $white, $border-size: 2px, $duration: 0.5s);
  }
</style>
