<template>
  <div :class="'dropzone'" :id="id" ref="dropzoneElement">
    <div v-if="useCustomSlot">
      <slot name="dz-message"></slot>
    </div>
    <div v-else class="dz-default dz-message" ref="dzMessage">
      <button class="dz-button" type="button">Drop files here to upload</button>
    </div>
  </div>
</template>

<script>
  import Dropzone from 'dropzone';
  import Sortable from 'sortablejs';
  import gsap from 'gsap';
  import { root } from '@/utils/paths';
  import { getUniqueSelector } from '@/utils/helpers';
  import { getTranslation } from '@/utils/languages';
  import mixinFile from '@/mixins/file';

  // Prevent self initialization
  Dropzone.autoDiscover = false;

  export default {
    name: 'dropzone',
    mixins: [mixinFile],
    props: {
      id: {
        type: String,
        required: true,
        default: getUniqueSelector('#dropzone')
          .substring(1),
      },
      autoDiscover: {
        type: Boolean,
        required: true,
        default: true,
      },
      options: {
        type: Object,
        required: false,
      },
      useCustomSlot: {
        type: Boolean,
        default: false,
        required: false,
      },
      sortableSelector: {
        type: String,
        default: null,
      },
    },
    emits: {
      addedFile(file) {
        return !!(file);
      },
      sending(file, xhr, formData) {
        return !!(file && xhr && formData);
      },
      sendingMultiple(file, xhr, formData) {
        return !!(file && xhr && formData);
      },
      success(response) {
        return !!response;
      },
      successMultiple(response) {
        return !!response;
      },
      error(response) {
        return !!response;
      },
      errorMultiple(response) {
        return !!response;
      },
      complete(response) {
        return !!response;
      },
      completeMultiple(response) {
        return !!response;
      },
    },
    data() {
      return {
        dropzone: null,
        defaultOptions: {
          url: `${root}datastore/file-upload`,
          addRemoveLinks: true,
          uploadMultiple: true,
          maxFiles: 20,
          maxFilesize: 25,
          parallelUploads: 20,
          thumbnailWidth: 60,
          thumbnailHeight: 60,
          thumbnailMethod: 'contain',
          acceptedFiles: '*',
          // previewsContainer: '#dz-default-previews-container',
          dictDefaultMessage: getTranslation('Drop files here to upload'),
          dictFallbackMessage: getTranslation('Your browser does not support drag drop file uploads'),
          dictFallbackText: getTranslation('Please use the fallback'),
          dictFileTooBig: getTranslation('File is too big'),
          dictInvalidFileType: getTranslation('Type is not supported'),
          dictResponseError: getTranslation('Server responded with statusCode'),
          dictCancelUpload: getTranslation('Cancel upload'),
          dictUploadCanceled: getTranslation('Upload canceled'),
          dictCancelUploadConfirmation: getTranslation('Are you sure you want to cancel this upload'),
          dictRemoveFile: getTranslation('Remove file'),
          dictRemoveFileConfirmation: null,
          dictMaxFilesExceeded: getTranslation('You can not upload any more files'),
          timeout: 900000, // 15'
        },
        previewNode: null,
        previewContainer: null,
        backdrop: null,
        dialog: null,
        fractionNode: null,
        totalNode: null,
        progress: 0,
        templates: [],
      };
    },
    computed: {
      mergedOptions() {
        // Merge defaultOptions with user defined options
        return {
          ...this.defaultOptions,
          ...this.options,
        };
      },
    },
    methods: {
      setCustomPreviewTemplate() {
        if (!this.mergedOptions.previewTemplate) return;
        this.previewNode = document.querySelector(`[data-id="${this.mergedOptions.previewTemplate}"]`);
        // overwrite previewTemplate
        this.mergedOptions.previewTemplate = this.previewNode.parentNode.innerHTML;
      },

      // file is added
      addedFile(file) {
        const extension = file.name.split('.')
          .pop() || '';
        const iconClasses = this.getExtensionIconClass(extension);
        const thumbNode = file.previewElement.querySelector('.dz-thumb');

        // set icon for non-image file types
        if (iconClasses && thumbNode && !iconClasses.includes('fa-file-image')) {
          thumbNode.innerHTML = `<i class="${iconClasses}"></i>`;
        }

        this.$emit('addedFile', file);
      },

      // files are added to the dropzone
      addedFiles(files) {
        const tl = gsap.timeline();
        this.tl = tl;

        // assign vars
        if (this.mergedOptions.previewsContainer) {
          this.previewContainer = document.querySelector(this.mergedOptions.previewsContainer);
          this.backdrop = this.previewContainer.closest('.dz-backdrop');
          this.dialog = this.previewContainer.closest('.dz-dialog');
          this.templates = [...this.previewContainer.children];

          // set fractions
          if (this.dialog) {
            this.totalNode = this.dialog.querySelector('.dz-total');
            this.fractionNode = this.dialog.querySelector('.dz-fraction');
          }
        }

        // set total files
        if (this.totalNode) {
          this.totalNode.textContent = files.length;
        }

        // add backdrop actions
        if (this.backdrop) {
          // disable click on ui
          this.backdrop.classList.add('active');

          // fade in backdrop
          tl.add(gsap.to(this.backdrop, {
            duration: 0.35,
            opacity: 1,
          }));
        }

        // animate dialog in
        if (this.dialog) {
          tl.add(gsap.to(this.dialog, {
            duration: 0.25,
            y: 0,
            opacity: 1,
            delay: -0.1,
            ease: 'power1.out',
          }));
        }

        // animate previews
        tl.to(this.templates, {
          y: 0,
          opacity: 1,
          stagger: {
            each: 0.25,
            ease: 'power2.out',
          },
        });
      },

      /**
       * https://www.dropzonejs.com/#event-sending
       * @param file {object}
       * @param xhr {xhr}
       * @param formData {formData}
       */
      sending(file, xhr, formData) {
        this.$emit('sending', file, xhr, formData);
      },

      /**
       * https://www.dropzonejs.com/#event-sendingmultiple
       * @param files {array}
       * @param xhr {xhr}
       * @param formData {formData}
       */
      sendingMultiple(files, xhr, formData) {
        this.$emit('sendingMultiple', files, xhr, formData);
      },

      /**
       * https://www.dropzonejs.com/#event-success
       * @param file {object}
       */
      success(file) {
        this.$emit('success', file);
      },

      /**
       * https://www.dropzonejs.com/#event-successmultiple
       * @param files {array}
       */
      successMultiple(files) {
        this.$emit('successMultiple', files);
      },

      /**
       * https://www.dropzonejs.com/#event-error
       * @param file {object}
       */
      error(file) {
        this.$emit('error', file);
      },

      /**
       * https://www.dropzonejs.com/#event-errormultiple
       * @param files {array}
       */
      errorMultiple(files) {
        this.$emit('errorMultiple', files);
      },

      /**
       * https://www.dropzonejs.com/#event-complete
       * @param file {object}
       */
      complete(file) {
        try {
          this.$emit('complete', file);
          this.progress += 1;
          this.setFraction();
        } catch (e) {
          throw new Error(e);
        }
      },

      /**
       * https://www.dropzonejs.com/#event-completemultiple
       * @param files {array}
       */
      completeMultiple(files) {
        try {
          // files.forEach((file) => {
          this.$emit('completeMultiple', files);
          this.progress += files.length;
          this.setFraction();
          // });
        } catch (e) {
          throw new Error(e);
        }
      },

      // https://www.dropzonejs.com/#event-queuecomplete
      queueComplete() {
        console.log('all uploaded');

        // animate dialog out
        if (this.dialog) {
          this.tl.add(gsap.to(this.dialog, {
            duration: 0.25,
            delay: 0.6,
            y: '-10vh',
            opacity: 0,
            ease: 'power1.in',
            clearProps: 'all',
          }));
        }

        // fade out backdrop
        if (this.backdrop) {
          this.tl.add(gsap.to(this.backdrop, {
            duration: 0.35,
            delay: -0.1,
            opacity: 0,
            ease: 'none',
            clearProps: 'all',
          }));
        }

        // enable ui
        this.tl.call(
          () => {
            if (this.backdrop) {
              this.backdrop.classList.remove('active');
              this.dropzone.removeAllFiles();
              this.progress = 0;
            }
          },
        );
      },

      // set fraction (e.g. 2/4)
      setFraction() {
        if (this.fractionNode) {
          this.fractionNode.textContent = this.progress;
        }
      },
    },

    mounted() {
      this.setCustomPreviewTemplate();

      // create Dropzone
      this.dropzone = new Dropzone(
        this.$el,
        this.mergedOptions,
      );

      // no custom init, use default
      if (!this.mergedOptions.init) {
        this.dropzone.on('addedfile', this.addedFile);
        this.dropzone.on('addedfiles', this.addedFiles);
        this.dropzone.on('queuecomplete', this.queueComplete);

        if (this.mergedOptions.uploadMultiple) {
          this.dropzone.on('sendingmultiple', this.sendingMultiple);
          this.dropzone.on('successmultiple', this.successMultiple);
          this.dropzone.on('errormultiple', this.errorMultiple);
          this.dropzone.on('completemultiple', this.completeMultiple);
        } else {
          this.dropzone.on('sending', this.sending);
          this.dropzone.on('success', this.success);
          this.dropzone.on('error', this.error);
          this.dropzone.on('complete', this.complete);
        }
      }

      // disabling autoDiscover, otherwise Dropzone will try to attach twice.
      this.dropzone.autoDiscover = this.autoDiscover;

      // make sortable
      if (this.sortableSelector && document.querySelector(this.sortableSelector)) {
        Sortable.create(document.querySelector(this.sortableSelector), { animation: 150 });
      }
    },
  };
</script>

<style lang="scss">
  @import "~@/assets/scss/appwork/_appwork/include";

  div.dropzone-fs {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: $primary;
    border: 0;
    padding: 0;
    min-height: 0;
    animation: bgColor 12s infinite alternate forwards;

    &.dz-drag-hover .dz-message {
      opacity: 1;
    }

    .dz-message {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      pointer-events: none;
      width: 100vw;
      height: 100vh;
      color: $white;
      text-align: center;
    }
  }
</style>

<style scoped lang="scss">
  @import "~dropzone/dist/dropzone.css";
  @import "~@/assets/scss/appwork/_appwork/include";
  @import "./dropzone_exceptions";

  .dropzone.reset {
    min-height: 0;
    border: 0;
    background: none;
    padding: 0;

    :deep(.dz-message) {
      margin: 0;
    }
  }

  .dropzone.dnd {
    position: relative;
    width: 100%;
    cursor: pointer;
    background-color: #fcfcff;

    // Disabled
    &:not(.dz-clickable) {
      opacity: .5;
      cursor: not-allowed;
    }
  }
</style>
