import * as api from '@/api/v10/datastore';
import { downloadFile } from '@/utils/files';

export const fileActions = {
  /**
   * download files
   * @param commit
   * @param args {object} - fileIds, optional cb
   */
  downloadFiles({ commit }, args) {
    const formData = new FormData();

    args.fileIds.forEach((id) => {
      formData.append('filesIds[]', id);
    });

    return api.downloadFiles(formData)
      // success
      .then((response) => {
        if (response.data.success) {
          downloadFile(response.data?.url, response.data?.name);
        }

        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            commit('addMessage', error, { root: true });
          });
        }
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Kon bestand(en) niet downloaden.',
            type: 'danger',
          },
          { root: true });
      })
      // always
      .then(() => {
        if (args.cb) args.cb();
      });
  },

  /**
   * edit file
   * @param commit
   * @param formData {FormData}
   */
  editFile({ commit }, formData) {
    return api.editFile(formData)
      // success
      .then((response) => {
        if (response.data.success) {
          commit('editFile', response.data.file);
        }

        if (response.data.errors) {
          response.errors.forEach((error) => {
            commit('addMessage', error, { root: true });
          });
        }
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Kon bestand niet bewerken.',
            type: 'danger',
          },
          { root: true });
      });
  },

  /**
   * delete file(s)
   * @param commit
   * @param fileIds {array}
   */
  deleteFiles({ commit }, fileIds) {
    const formData = new FormData();

    fileIds.forEach((id) => {
      formData.append('filesIds[]', id);
    });

    return api.deleteFiles(formData)
      // success
      .then((response) => {
        const errors = [];

        response.data.forEach((file) => {
          if (file.isSuccess) {
            // file is deleted successfully
            commit('deleteFile', file.fileUID);
            commit('removeSelectedFile', file);
          } else {
            // file could not be deleted
            errors.push(file.message);
          }
        });

        // we have errors
        if (errors.length > 1) {
          // multiple delete errors
          commit('addMessage', {
            title: 'Fout bij verwijderen?',
            message: `${errors.length} bestanden niet konden verwijderd.`,
            type: 'danger',
          }, { root: true });
        } else if (errors.length === 1) {
          // single delete error
          commit('addMessage', errors[0], { root: true });
        }
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to remove file(s).',
            type: 'danger',
          },
          { root: true });
      });
  },

  /**
   * get file by id
   * @param commit
   * @param id {string}
   * @returns {Promise}
   */
  getFileById({ commit }, id) {
    const formData = new FormData();
    formData.append('fileID', id);

    return api.getFileById(formData)
      // success
      .then((response) => {
        commit('setActiveFile', response.data.file);
      })
      // fail
      .catch(() => {
        commit('addMessage',
          {
            title: 'Unable to get file by id.',
            type: 'danger',
          },
          { root: true });
      });
  },
};

export default {
  fileActions,
};
