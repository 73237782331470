<script>
  import '@/assets/libs/datatables/datatables';
  import '@/assets/libs/select2/select2';
  import 'lazysizes';
  import './theme';
  import './modules/v10';

  // css
  import './styles';
  import 'bootstrap-icons/font/bootstrap-icons.css';

  import { mapState } from 'vuex';
  import Toast from '@/components/toast/Toast';

  export default {
    name: 'App',
    comments: false,
    computed: {
      ...mapState({
        messages: (state) => state.messages,
      }),
    },
    components: { Toast },
    methods: {
      /**
       * destroy toast
       * @param message {object}
       */
      destroyToast(message) {
        this.$store.commit('removeMessage', message);
      },
    },
    mounted() {
    },
  };
</script>
