import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';
import { getCritical } from '@/views/engine/utils/alerts';
import { getTranslation } from '@/utils/languages';

export default class FormCategoryManager {
  /**
   * constructor of FormCategoryManager
   *
   * @param formEl {Element}
   */
  constructor(formEl) {
    this.dom = {
      formEl,
      engine: document.getElementById('engine'),
      masterSlaveSelects: formEl.querySelectorAll('.js-category-masterslave'),
      addCategoryButton: formEl.querySelector('.js-add-category'),
    };

    this.data = {
      formData: null,
    };

    this.props = {
      mlcid: null,
      moduleid: null,
      endlevel: null,
      selectedvalue: 0,
      parentcategoryid: 0,
      selectedcatkey: null,
      activelevel: 0,
      addTolevel: 1, // always the lowest by default (catch an edit action with no changes on the category selector yet)
      catkey: null,
      mode: formEl.querySelector('[name="mode"]').value,
    };

    this.components = {
      msCategories: [],
      addElementButtons: [],
    };

    this.events = {
      onChangeCategory: this.onChangeCategory.bind(this),
      onAddCategory: this.onAddCategory.bind(this),
    };

    this.customEvents = {
      criticalError: getCritical,
    };

    // mount
    this.mount();
  }

  mount() {
    // add category master slave instances
    // listen on change
    this.dom.masterSlaveSelects.forEach((select) => {
      // inits certain settings if not set (click add if no change invoked yet)
      if (!this.props.moduleid) this.props.moduleid = parseInt(select.dataset.moduleid, 10);
      if (!this.props.addTolevel) this.props.addTolevel = parseInt(select.dataset.mlcid, 10);

      // add event listener
      select.addEventListener('change', this.events.onChangeCategory);
    });

    // set settings on edit to the last selectors settings
    if (this.dom.masterSlaveSelects) {
      switch (this.props.mode) {
        case 'edit':
          if (this.dom.masterSlaveSelects.length > 1) {
            const lastSelectEl = this.dom.masterSlaveSelects[this.dom.masterSlaveSelects.length - 1];
            if (lastSelectEl) {
              this.props.parentcategoryid = parseInt(this.dom.masterSlaveSelects[this.dom.masterSlaveSelects.length - 2].value, 10);
              this.props.addTolevel = parseInt(lastSelectEl.dataset.mlcid, 10);
              this.props.catkey = parseInt(lastSelectEl.dataset.catkey, 10);
              this.props.moduleid = parseInt(lastSelectEl.dataset.moduleid, 10);
            }
            this.props.activelevel = 1;
          }
          break;
        default:
          break;
      }
    }

    // listen on click
    if (this.dom.addCategoryButton) this.dom.addCategoryButton.addEventListener('click', this.events.onAddCategory);
  }

  unmount() {
    // remove on change event
    this.dom.masterSlaveSelects.forEach((select) => {
      select.removeEventListener('change', this.events.onChangeCategory);
    });

    // remove on click listener
    if (this.dom.addCategoryButton) this.dom.addCategoryButton.removeEventListener('click', this.events.onAddCategory);
  }

  /**
   * change the select item
   *
   * @param e {MouseEvent}
   */
  onChangeCategory(e) {
    // dataset
    const props = e.target.dataset;

    this.props.mlcid = props?.mlcid;
    this.props.activelevel = props?.mlcid;
    this.props.moduleid = props?.moduleid;
    this.props.endlevel = props?.endlevel;
    this.props.catkey = props?.catkey;

    // force reset if no selection
    if (!e.target.value) this.props.selectedvalue = 99;

    // store for next
    if (this.props.catkey) this.props.selectedcatkey = this.props.catkey;

    // check required parameters
    if (!this.props.mlcid || !this.props.moduleid || !this.props.endlevel) {
      // throw critical alert
      getCritical();
      return;
    }

    // only call if levels beneath
    if (this.props.endlevel > 0) {
      // store some values for if you want to add something
      // lower the level to add to
      this.props.addTolevel -= 1;
      this.props.parentcategoryid = e.target.value;

      // formData
      this.data.formData = new FormData();
      this.data.formData.append('mlcid', this.props.mlcid);
      this.data.formData.append('moduleid', this.props.moduleid);
      this.data.formData.append('endlevel', this.props.endlevel);
      this.data.formData.append('category', e.target.value);

      // make axios call
      axios({
        method: 'post',
        url: `${root}common/change-category-on-form`,
        data: this.data.formData,
      })
        .then((response) => {
          if (!response.data.success) {
            // send error notification
            const notification = {
              title: response.data.errorMessage,
              type: 'danger',
            };

            this.customEvents.notificationError = getErrorNotification(notification);
            this.dom.engine.dispatchEvent(this.customEvents.notificationError);
            // throw critical alert
            // getCritical();
          } else if (this.props.mlcid > 1) {
            // one level down gets visual if not bottom level
            const ms = `mlcid-level${this.props.mlcid - 1}`;
            this.dom.formEl.querySelector(`[name=${ms}]`)
              .classList
              .remove('d-none');

            // levels underneath are set to make a choice
            for (let i = 1; i <= this.props.endlevel; i += 1) {
              const nameEl = `mlcid-level${i}`;
              const selectEl = this.dom.formEl.querySelector(`[name=${nameEl}]`);
              selectEl.innerHTML = selectEl.firstChild.outerHTML;
            }

            // fill out the selects with the retrieved data
            for (const [key, value] of Object.entries(response.data.levels)) {
              console.log(key);
              const selectEl = this.dom.formEl.querySelector(`[name=mlcid-level${this.props.endlevel}]`);
              const optEl = new Option(value.Display, value.CategoryID);
              selectEl.appendChild(optEl);
            }

            // reset the value of de catkey
            if (this.props.selectedvalue > 0) {
              const catkeyEl = this.dom.formEl.querySelector(`[name=${this.props.selectedcatkey}`);
              catkeyEl.removeAttribute('value');
              this.props.selectedvalue = 0;
              this.props.activelevel = 0;
              this.props.parentcategoryid = 0;
              this.props.addTolevel = this.props.mlcid;
            }
          }
          // some useful debug info
          if (response.data.debugReport) {
            this.dom.debugReport.innerHTML = response.data.debugReport;
          }
        })
        .catch((error) => {
          console.log(error);
          getCritical();
        });
    } else { // end level action
      // pk value needs to be changed
      const catkeyEl = this.dom.formEl.querySelector(`[name=${this.props.catkey}`);
      catkeyEl.value = e.target.value;

      // keep in the class
      this.props.selectedvalue = e.target.value;
    }
  }

  /**
   * add new category
   */
  onAddCategory() {
    // new category
    const inputEl = this.dom.formEl.querySelector('#add-category-txt');
    const categoryNameToAdd = inputEl.value;

    // check if any given
    if (categoryNameToAdd.length < 1) {
      // mark as error on screen
      inputEl.classList.add('is-invalid');

      // send error notification
      const notification = {
        title: getTranslation('Gelieve de rubriek op te geven aub?'),
        type: 'danger',
      };

      const notificationError = getErrorNotification(notification);
      this.dom.engine.dispatchEvent(notificationError);

      // exit
      return;
    }

    // remove invalid class if set
    if (inputEl.classList.contains('is-invalid')) inputEl.classList.remove('is-invalid');

    // formData
    this.data.formData = new FormData();
    this.data.formData.append('level', this.props.addTolevel);
    this.data.formData.append('moduleID', this.props.moduleid);
    this.data.formData.append('parentCategoryID', this.props.parentcategoryid);
    this.data.formData.append('categoryNameToAdd', categoryNameToAdd);
    this.data.formData.append('mode', this.props.mode);

    // make axios call
    axios({
      method: 'post',
      url: `${root}common/save-category`,
      data: this.data.formData,
    })
      .then((response) => {
        if (!response.data.success) {
          // send error notification
          const notification = {
            title: response.data.errorMessage,
            type: 'danger',
          };

          this.customEvents.notificationError = getErrorNotification(notification);
          this.dom.engine.dispatchEvent(this.customEvents.notificationError);
          // throw critical alert
          // getCritical();
        } else {
          // empty the input
          this.dom.formEl.querySelector('#add-category-txt').value = '';

          // add the new category to the representative level
          const ms = `mlcid-level${this.props.addTolevel}`;
          const selectEl = this.dom.formEl.querySelector(`select[name="${ms}"]`);
          const optEl = new Option(categoryNameToAdd, response.data.newID);
          selectEl.appendChild(optEl);
        }

        // some useful debug info
        if (response.data.debugReport) {
          this.dom.debugReport.innerHTML = response.data.debugReport;
        }
      })
      .catch((error) => {
        console.log(error);
        getCritical();
      });
  }
}
