<template>
  <template v-if="state === states.loading">
    <div class="row align-items-center py-3 mb-4">
      <div class="col-md-4 col-xl-3 pr-xl-5">
        <skeleton-box class="w-75" height="32px"/>
      </div>

      <div class="col d-flex">
        <skeleton-box class="mr-2" width="10%" height="32px"/>
        <skeleton-box class="mr-2" width="10%" height="32px"/>
        <skeleton-box class="mr-2" width="60%" height="32px"/>
        <skeleton-box width="20%" height="32px"/>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="header-sticky row align-items-center py-3 mb-4">
      <!-- Title -->
      <div class="col-md-4 col-xl-3 pr-xl-5 d-flex align-items-center">
        <h4 class="font-weight-bold text-truncate mb-0">
          <span class="text-muted font-weight-light">Bibliotheek&nbsp;/&nbsp;</span>
          <template v-if="activeFolder && activeFolder.folderName">{{ activeFolder.folderName }}</template>
          <template v-else>Overzicht</template>
        </h4>
      </div>

      <!-- Actions -->
      <div class="col">
        <div class="d-flex">
          <div :class="{ disabled: files.length < 1 }" class="btn-group flex-shrink-0 mr-2">
            <!-- File actions -->
            <button
              :disabled="selectedFiles.length < 1"
              type="button"
              class="btn btn-outline-gray dropdown-toggle hide-arrow d-flex align-items-center justify-content-center px-3"
              data-toggle="dropdown">
              <i class="bi bi-gear-fill text-18 text-dark"></i>
              <i class="bi bi-chevron-down text-dark text-12 ml-2"></i>
            </button>
            <div :disabled="selectedFiles.length > 1" class="dropdown-menu">
              <btn-view-file></btn-view-file>
              <btn-download-files></btn-download-files>
              <btn-edit-file></btn-edit-file>
              <div class="dropdown-divider"></div>
              <btn-delete-files></btn-delete-files>
            </div>
          </div>

          <!-- Views -->
          <div :class="{ disabled: files.length < 1 }" class="btn-group btn-group-toggle flex-shrink-0 mr-2">
            <!-- List -->
            <btn-switch-view :view="views.list" title="Lijst weergave">
              <i class="bi bi-list-ul text-18"></i>
            </btn-switch-view>

            <!-- Grid -->
            <btn-switch-view :view="views.grid" title="Raster weergave">
              <i class="bi bi-grid-3x3-gap-fill text-16"></i>
            </btn-switch-view>
          </div>

          <!-- Search -->
          <search></search>

          <!-- Upload file -->
          <dropzone
            :use-custom-slot=true
            :autoDiscover=false
            :options="{
              previewTemplate: 'dz-template',
              previewsContainer: '#previews',
              clickable: true,
              createImageThumbnails: true,
              uploadMultiple: true,
              maxFiles: maxUploadFiles,
              maxFilesize: maxUploadFilesize,
              parallelUploads: maxParallelUploads,
             }"
            @sendingMultiple="uploadSendingMultiple"
            @successMultiple="uploadSuccessMultiple"
            role="form"
            class="dz-clickable reset"
            id="dz-btn"
            ref="dz-btn">
            <template v-slot:dz-message>
              <button type="button" class="btn btn btn-primary d-flex align-items-center flex-shrink-0 text-transform-none dz-message ml-auto">
                <i class="bi bi-plus-circle-fill mr-2 text-16"></i>
                Bestanden uploaden
              </button>
            </template>
          </dropzone>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
  import { mapState } from 'vuex';

  // mixins
  import mixinDatastoreUpload from '@/mixins/datastore/upload';

  // components
  import skeletonBox from '@/components/skeleton/SkeletonBox';

  // partials
  import datastoreSearch from './Search';
  import datastoreBtnDownloadFiles from './BtnDownloadFiles';
  import datastoreBtnViewFile from './BtnViewFile';
  import datastoreBtnEditFile from './BtnEditFile';
  import datastoreBtnDeleteFiles from './BtnDeleteFiles';
  import datastoreBtnSwitchView from './BtnSwitchView';

  export default {
    name: 'datastore-overview-heading',
    mixins: [mixinDatastoreUpload],
    components: {
      search: datastoreSearch,
      btnDownloadFiles: datastoreBtnDownloadFiles,
      btnViewFile: datastoreBtnViewFile,
      btnEditFile: datastoreBtnEditFile,
      btnDeleteFiles: datastoreBtnDeleteFiles,
      btnSwitchView: datastoreBtnSwitchView,
      skeletonBox,
    },
    computed: {
      selectedFiles: {
        get() {
          return this.$store.state.datastore.selectedFiles;
        },
        set(value) {
          this.$store.commit('datastore/setSelectedFiles', value);
        },
      },
      ...mapState({
        activeFolder: (state) => state.datastore.activeFolder,
        files: (state) => state.datastore.files,
        views: (state) => state.datastore.views,
        state: (state) => state.datastore.state,
        states: (state) => state.datastore.states,
      }),
    },
    methods: {},
  };
</script>
