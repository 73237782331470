import {
  findDeep, removeDeep, replaceDeep, sortDeep,
} from '@/utils/array';

export const folderMutations = {
  /**
   * set folders state
   * @param state
   * @param folders {array}
   */
  setFolders(state, folders) {
    console.log('datastore/setFolders');
    state.folders = folders;
  },

  /**
   * set active folder
   * @param state
   * @param folderId {string}
   */
  setActiveFolder(state, folderId = '') {
    console.log('datastore/setActiveFolder');
    let activeFolder = findDeep(state.folders, 'active', true, 'children').item;

    // active folder is the same, do nothing
    if (activeFolder && activeFolder.folderId === folderId) return;

    // folder is passed
    if (folderId) {
      const newActiveFolder = findDeep(state.folders, 'folderId', folderId, 'children').item;

      // change old folder state
      if (newActiveFolder && activeFolder) {
        activeFolder.active = false;
      }

      if (newActiveFolder) {
        // set new folder state
        newActiveFolder.active = true;
        // set new activeFolder
        state.activeFolder = newActiveFolder;
        return;
      }
    }

    // fallback: set first folder as active (if there are any)
    try {
      const firstFolder = state.folders[0];
      firstFolder.active = true;
      activeFolder = firstFolder;

      // set active folder state
      state.activeFolder = activeFolder;
    } catch (e) {
      throw new Error(e);
    }
  },

  /**
   * search for folder by id and merge its obj with given obj
   * @param state
   * @param folderProps {object}
   */
  setFolderName(state, folderProps) {
    console.log('datastore/setFolderName');
    replaceDeep(state.folders, { folderId: folderProps.id }, { folderName: folderProps.name }, 'children');
  },

  /**
   * set activeRenameFolderId value
   * @param state
   * @param folderId {string}
   */
  setActiveRenameFolderId(state, folderId) {
    console.log('datastore/setActiveRenameFolderId');
    state.activeRenameFolderId = folderId;
  },

  /**
   * add a temporary folder to the ui
   * @note folder is not sent to the server, only exists in state
   * @param state
   * @param folder {object}
   */
  addTempFolder(state, folder) {
    console.log('datastore/addTempFolder');
    if (folder.parentFolderId === state.ROOT_FOLDER_ID) {
      // root folder
      state.folders.unshift(folder);
    } else {
      // sub-folder
      const parentFolder = findDeep(state.folders, 'folderId', folder.parentFolderId, 'children').item;
      if (parentFolder.children) {
        parentFolder.children.unshift(folder);
      } else {
        parentFolder.children = [folder];
      }
    }

    // folder will not be saved until a name is given
    state.tempFolders.push(folder);
  },

  /**
   * like a boy becomes a man,
   * the temporary folder becomes an actual folder
   * @note folder is sent to server, is now persistent
   * @param state
   * @param obj
   */
  upgradeTempFolder(state, obj) {
    console.log('datastore/upgradeTempFolder');
    replaceDeep(state.folders,
      { folderId: obj.tempId },
      {
        folderId: obj.folderId,
        folderName: obj.folderName,
      },
      'children');
  },

  /**
   * delete folder from TempFolders
   * @param state
   * @param tempFolderId {string}
   */
  deleteTempFolder(state, tempFolderId) {
    console.log('datastore/deleteTempFolder');
    state.tempFolders = state.tempFolders.filter((folder) => folder.folderId !== tempFolderId);
  },

  /**
   * delete folder
   * @param state
   * @param folderId {string}
   */
  deleteFolder(state, folderId) {
    console.log('datastore/deleteFolder');
    removeDeep(state.folders,
      { folderId },
      'children');

    // active folder had been deleted, set first folder as active
    // if (!findDeep(state.folders, 'active', true, 'children').folderdD && state.folders.length > 0) {
    // folderMutations.setActiveFolder(state, state.folders[0]);
    // }
  },

  /**
   * set values for folder delete modal
   * @param state
   * @param obj {object}
   */
  setFolderDeleteModal(state, obj) {
    console.log('datastore/setFolderDeleteModal');
    state.folderDeleteModal = obj;
  },

  /**
   * sort folder
   * @param state
   * @param parentFolderId {string}
   */
  sortFolder(state, parentFolderId) {
    console.log('datastore/sortFolder');
    if (parentFolderId === state.ROOT_FOLDER_ID) {
      // sort root folders alphabetic ascending
      state.folders.sort((a, b) => a.folderName.localeCompare(b.folderName));
    } else {
      // sub-folder
      sortDeep(state.folders,
        { folderId: parentFolderId },
        'folderName',
        'children');
    }
  },
};

export default {
  folderMutations,
};
