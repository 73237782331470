export const fileGetters = {
  /**
   * return file object with matching id's
   * @param state
   * @returns {object} file
   */
  getFileById: (state) => (id) => state.files.find((file) => file.fileUID === id),

  /**
   * return length of selected files
   * @param state
   * @returns {number}
   */
  selectedFilesLength: (state) => state.selectedFiles.length,

  /**
   * return array of selected file ids
   * @param state
   * @returns {array}
   */
  getSelectedFileIds: (state) => state.selectedFiles.map((file) => file.fileUID),

  /**
   * return file size of selected files
   * @param state
   * @returns {number} combined file sizes (rounded up)
   */
  selectedFileSize: (state/* , getters */) => {
    if (state.selectedFiles.length > 0) {
      // const sizes = state.selectedFileIds.map((id) => Number(getters.getFileById(id).size));
      return Math.ceil(state.selectedFiles.reduce((accumulator, currentValue) => accumulator + currentValue.size));
    }
    return 0;
  },
};

export default {
  fileGetters,
};
