import Tagify from '@yaireo/tagify';
import { slugifyText, capitalizeFirstLetter, makePasswordSafe} from './text';
import '@yaireo/tagify/dist/tagify.css';

/**
 * set field B value equal to field A and apply slugify
 * @param field {Element} copy field
 */
export function copyFieldValueAndSlugify(field) {
  const fieldSrc = field.dataset.source;
  if (!fieldSrc) return;

  const behaveLike = field.dataset?.behavior;
  const signLength = field.dataset?.length;

  // init
  const delimiter = '-';
  let slugSrc = '';

  // get all pieces
  const allFrom = fieldSrc.toString().split(';');

  // loop all field pieces
  allFrom.forEach((slugger) => {
    const slug = document.getElementById(slugger);
    slug.addEventListener('input', () => {
      const endEl = [];

      // check how to behave
      switch (behaveLike) {
        case 'username':
          // eslint-disable-next-line no-case-declarations
          const pkVal = document.querySelector('input[data-pkref="true"]').value;
          allFrom.forEach((slugEl) => {
            const current = document.getElementById(slugEl);
            slugSrc = current.value.substr(0, signLength);
            endEl.push(slugifyText(slugSrc, delimiter));
          });
          // add dash at the end
          endEl[(endEl.length - 1)] += pkVal;
          break;
        case 'password':
          allFrom.forEach((slugEl) => {
            const current = document.getElementById(slugEl);
            slugSrc = current.value.substr(0, signLength);
            endEl.push(makePasswordSafe(slugSrc));
          });
          // add hash after class number
          endEl[0] += '#';
          endEl[(endEl.length - 1)] = capitalizeFirstLetter(endEl[(endEl.length - 1)]);
          break;
        case 'copy':
          endEl[0] = document.getElementById(fieldSrc).value;
          break;
        default:
          allFrom.forEach((slugEl) => {
            const current = document.getElementById(slugEl);
            endEl.push(slugifyText(current.value, delimiter));
          });
          break;
      }

      // eslint-disable-next-line no-param-reassign
      field.value = endEl.join('');
    });
  });
}

/**
 * apply tagify on field element
 * @param fieldEl {Element} src element
 */
export function makeTagfield(fieldEl) {
  // The DOM element you wish to replace with Tagify
  // initialize Tagify on the above input node reference
  // eslint-disable-next-line no-new
  new Tagify(fieldEl);
}

/**
 * reveal the password on field element
 * @param htmlEl {Element} html element
 */
export function revealPassword(htmlEl) {
  // @todo moet met een onclick event
  const id = htmlEl.closest('data-formfieldid');
  console.log(id);
  return;
  const togglePassword = document.querySelector(fieldEl);

  return;
  // const password = document.querySelector("#password");

  // togglePassword.addEventListener("click", function () {
    // toggle the type attribute
    const type = togglePassword.getAttribute('type') === 'password' ? 'text' : 'password';
    togglePassword.setAttribute('type', type);

    // toggle the icon
    this.classList.toggle('bi-eye');
  // });
}

export default {
  copyFieldValueAndSlugify,
  makeTagfield,
  revealPassword,
};
