import { getCritical } from '@/views/engine/utils/alerts';
import axios from 'axios';
import { root } from '@/utils/paths';
import { getErrorNotification } from '@/views/engine/utils/notifications';

export default class GridActionPlusMinItem {
  /**
   * delete an item
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      updateArea: null,
      newValue: null,
      engine: document.getElementById('engine'),
    };
    this.events = {
      plusMin: this.plusMin.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.plusMin);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.plusMin);
  }

  /**
   *
   * @param e {MouseEvent}
   */
  async plusMin(e) {
    // prevent default handle
    e.preventDefault();

    // check required parameters
    if (!e.target.dataset.do || !e.target.dataset.grid || !e.target.dataset.pk || !e.target.dataset.key) {
      // throw critical alert
      getCritical();
    } else {
      // get update area
      this.dom.updateArea = document.getElementById(`plus-min-${e.target.dataset.key}-${e.target.dataset.pk}`).innerText;

      // check plus or min
      if (e.target.dataset.do == 'plus') {
        this.dom.newValue = parseInt(this.dom.updateArea) + 1;
      } else {
        this.dom.newValue = parseInt(this.dom.updateArea) - 1;
        if (this.dom.newValue < 0) this.dom.newValue = 0;
      }

      // formdata
      const formData = new FormData();
      formData.append('gridID', e.target.dataset.grid);
      formData.append('action', e.target.dataset.do);
      formData.append('field', e.target.dataset.key);
      formData.append('pk', e.target.dataset.pk);

      // make axios call
      axios({
        method: 'post',
        url: `${root}datagrids/plusmin-value`,
        data: formData,
      })
        .then((response) => {
          if (!response.data.success) {
            // send error notification
            const notification = { title: response.data.errorMessage, type: 'danger' };
            const notificationError = getErrorNotification(notification);
            this.dom.engine.dispatchEvent(notificationError);
          } else {
            // set the value right
            document.getElementById(`plus-min-${e.target.dataset.key}-${e.target.dataset.pk}`).innerText = this.dom.newValue;
          }
        })
        .catch(() => getCritical());
    }
  }
}
